// A function decorating a dataProvider for handling user profiles
export const addUserProfileOverrides = (dataProvider: any) => ({
    ...dataProvider,
    getUserProfile(params: any) {
        const storedProfile = localStorage.getItem('userProfile');

        if (storedProfile) {
            return Promise.resolve({
                data: JSON.parse(storedProfile),
            });
        }

        // No profile yet, return a default one
        return Promise.resolve({
            data: {
                // As we are only storing this information in the localstorage, we don't really care about this id
                id: 'my-profile',
                fullName: "",
                avatar: ""
            },
        });
    },

    updateUserProfile(params: any) {
        return dataProvider.update('users', {id: params.data.id, data: params.data}).then(({data}: any) => {
            if (!data.fullName) {
                data.fullName = `${data.last_name} ${data.first_name}`;
            }

            localStorage.setItem('userProfile', JSON.stringify(data));
            return Promise.resolve({data});
        })
    }
});
