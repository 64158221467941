import {useTranslate} from "ra-core";
import {Typography} from "@material-ui/core";
import {AvatarWithName} from "../AvatarWithName";
import {Labeled, TextField} from "react-admin";
import React from "react";

export const Parent = (props: any) => {
    const t = useTranslate();
    if (!props.record.parent) {
        return null;
    }

    return (<><Typography>
        {t('resources.users.fields.parent', 'Tábor parancsnok')}: <AvatarWithName {...props.record.parent} />
    </Typography>
        <Labeled label="resources.users.fields.email">
            <TextField {...props} source="parent.email"/>
        </Labeled><br/>
        <Labeled label="resources.users.fields.mobile">
            <TextField {...props} source="parent.mobile"/>
        </Labeled></>);
}