import {RegLink} from "./Registration";
import {ProfileLink} from "./ProfileEdit";
import {EmailVerifyLink} from "./EmailVerifyLink";
import {CampApplyRoute} from "./CampApplyRoute";
import {ForgotPasswordLink} from "./ForgotPassword";
import {ResetPasswordLink} from "./ResetPassword";
import Forbidden from "../components/Forbidden";

export const routes = [
    ResetPasswordLink,
    ForgotPasswordLink,
    RegLink,
    ProfileLink,
    EmailVerifyLink,
    CampApplyRoute,
    Forbidden
    /*FAQRoutes*/
]
