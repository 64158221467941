import * as React from 'react';
import {FC, ReactElement} from 'react';
import PropTypes from 'prop-types';
import ActionList from '@material-ui/icons/List';
import {Link} from 'react-router-dom';
import {useResourceContext} from 'ra-core';

import {Button, ButtonProps} from 'react-admin';
import {stringify} from "query-string";

// @ts-ignore
/**
 * Opens the List view of a given resource
 *
 * @example // basic usage
 * import { ListButtonWithQuery } from 'react-admin';
 *
 * const CommentListButton = () => (
 *     <ListButtonWithQuery basePath="/comments" label="Comments" />
 * );
 *
 * @example // linking back to the list from the Edit view
 * import { TopToolbar, ListButtonWithQuery, ShowButton, Edit } from 'react-admin';
 *
 * const PostEditActions = ({ basePath, record, resource }) => (
 *     <TopToolbar>
 *         <ListButtonWithQuery basePath={basePath} />
 *         <ShowButton basePath={basePath} record={record} />
 *     </TopToolbar>
 * );
 *
 * export const PostEdit = (props) => (
 *     <Edit actions={<PostEditActions />} {...props}>
 *         ...
 *     </Edit>
 * );
 */
const ListButtonWithQuery: FC<ListButtonProps> = ({
                                                      basePath = '',
                                                      icon = defaultIcon,
                                                      label = 'ra.action.list',
                                                      query = {},

                                                      ...rest
                                                  }) => {
    const resource = useResourceContext(rest);
    const {
        filter,
        page,
        perPage,
        sort
    } = query;
    const queryString = {};
    if (sort) {
        // @ts-ignore
        queryString.sort = JSON.stringify([sort?.field, sort?.order]);
    }
    if (filter) {
        // @ts-ignore
        queryString.filter = JSON.stringify(filter);
    }
    if (page) {
        // @ts-ignore
        queryString.page = page;
    }
    if (perPage) {
        // @ts-ignore
        queryString.perPage = perPage;
    }


    return (<Button
        component={Link}
        to={`/${resource}?${stringify(queryString)}`}
        label={label}
        {...(rest as any)}
    >
        {icon}
    </Button>);
};

const defaultIcon = <ActionList/>;

interface Props {
    basePath?: string;
    icon?: ReactElement;
    label?: string;
}

export type ListButtonProps = Props & ButtonProps & {
    query?: {
        filter?: any, page?: number, perPage?: number, sort?: {
            field?: string, order?: string
        }
    }
};

// @ts-ignore
ListButtonWithQuery.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    query: PropTypes.objectOf({
// @ts-ignore
        filter: PropTypes.any,
        page: PropTypes.number,
        perPage: PropTypes.number,
        sort: PropTypes.objectOf({
// @ts-ignore
            field: PropTypes.string,
            order: PropTypes.string
        })
    }),
};

export default ListButtonWithQuery;
