import * as React from 'react';
import {BooleanField, ReferenceField, Show, SimpleShowLayout, TextField, useListContext} from 'react-admin';
import {Avatar} from '@material-ui/core';


const cardStyle = {
    width: 300,
    minHeight: 300,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
};
const AvatarGrid = () => {
    const {ids, data, basePath} = useListContext();
    return (
        <div style={{margin: '1em'}}>
            {ids.map(id => (
                    <div key={`avatar-{$id}`}><Avatar src={data[id].url}/></div>
                )
            )}
        </div>
    );
};
const MediaGrid = () => {
    const {ids, data, basePath} = useListContext();

    return (
        <div style={{margin: '1em'}}>
            {ids.map(id => (
                    <div key={`media-{$data[id].id}`}><Avatar src={data[id].url}/></div>
                )
            )}
        </div>
    );
};
/*
<Datagrid>
    <TextField source="id"/>
    <TextField source="model_type"/>
    <ReferenceField source="model_id" reference="models">
        <TextField source="id"/>
    </ReferenceField>
    <TextField source="uuid"/>
    <TextField source="collection_name"/>
    <TextField source="name"/>
    <TextField source="file_name"/>
    <TextField source="mime_type"/>
    <TextField source="disk"/>
    <TextField source="conversions_disk"/>
    <NumberField source="size"/>
    <TextField source="manipulations"/>
    <TextField source="custom_properties"/>
    <TextField source="responsive_images"/>
    <NumberField source="order_column"/>
    <DateField source="created_at"/>
    <DateField source="updated_at"/>
    <UrlField source="url"/>
</Datagrid>
*/

export const AddressShow = (props: any) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="street"/>
            <TextField source="street_extra"/>
            <TextField source="city"/>
            <TextField source="state"/>
            <TextField source="post_code"/>
            <ReferenceField source="country_id" reference="countries"><TextField source="id"/></ReferenceField>
            <TextField source="note"/>
            <TextField source="lat"/>
            <TextField source="lng"/>
            <TextField source="addressable_type"/>
            <ReferenceField source="addressable_id" reference="addressables"><TextField source="id"/></ReferenceField>
            <BooleanField source="is_public"/>
            <BooleanField source="is_primary"/>
            <BooleanField source="is_billing"/>
            <BooleanField source="is_shipping"/>
            <TextField source="created_at"/>
            <TextField source="updated_at"/>
            <TextField source="deleted_at"/>
            <TextField source="__class_name"/>
        </SimpleShowLayout>
    </Show>
);
