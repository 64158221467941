import * as React from "react";
import {BooleanInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin';

export const AddressEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="street"/>
            <TextInput source="street_extra"/>
            <TextInput source="city"/>
            <TextInput source="state"/>
            <TextInput source="post_code"/>
            <ReferenceInput source="country_id" reference="countries"><SelectInput optionText="name"/></ReferenceInput>
            <TextInput source="note"/>
            <TextInput source="lat"/>
            <TextInput source="lng"/>
            <TextInput source="addressable_type"/>
            <ReferenceInput source="addressable_id" reference="addressables"><SelectInput
                optionText="id"/></ReferenceInput>
            <BooleanInput source="is_public"/>
            <BooleanInput source="is_primary"/>
            <BooleanInput source="is_billing"/>
            <BooleanInput source="is_shipping"/>
            <TextInput source="created_at"/>
            <TextInput source="updated_at"/>
            <TextInput source="deleted_at"/>
            <TextInput source="__class_name"/>
        </SimpleForm>
    </Edit>
);
