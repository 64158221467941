import {UserList} from "./UserList";
import {UserEdit} from "./UserEdit";
import {UserCreate} from "./UserCreate";
import {UserShow} from "./UserShow";
import PeopleIcon from '@material-ui/icons/People';

export {ProfileEdit} from "./ProfileEdit";
export * from "./UserList";
export * from "./UserEdit";
export * from "./UserCreate";
const resource = {
    list: UserList,
    create: UserCreate,
    show: UserShow,
    //edit: EditGuesser,
    edit: UserEdit,
    icon:PeopleIcon
};

export default resource;
