import React from 'react';
import {MemberShow as MemberShowBase} from '../../components/members/MemberShow'
import {StatusToolbar} from "../../components/StatusToolbar";
import {usePermission} from "../../providers/permissionProvider";
import MemberStateMachine from "./MemberStateMachine";
import ApplicationsList from "../applications/ApplicationsList";

export const MemberShow = (props: any) => {
    const {hasPermission} = usePermission();
    return (
        <MemberShowBase {...props}>
            <ApplicationsList {...props} />
            {/*<PromiseList {...props} />*/}
            <StatusToolbar {...props} source="status" extraParams={{source: 'departments', getStatus:(record)=>record.departments.map((dep)=>dep.status)}}
                           showToolbar={hasPermission('manage.members')} stateMachine={MemberStateMachine}/>
        </MemberShowBase>);
}
