import * as React from 'react';
import {FC} from 'react';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import {useTranslate} from 'react-admin';
import CardWithIcon from './CardWithIcon';
import {CampSubList, CampSubListProps} from "../pages/camps";

const YourCampApplications: FC<CampSubListProps> = ({recentCamps = []}) => {
    const translate = useTranslate();
    // @ts-ignore
    return (<CardWithIcon icon={FireplaceIcon} title={translate('pos.dashboard.new_camp_application')} subtitle={recentCamps?.length} >
            <CampSubList recentCamps={recentCamps}/>
        </CardWithIcon>
    );
};


export default YourCampApplications;
