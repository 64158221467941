import {Identifier, ReduxState} from 'react-admin';

export type ThemeName = 'light' | 'dark';

export interface AppState extends ReduxState {
    theme: ThemeName;
}

export interface VaccinationType {
    id: Identifier;
    name?: string;
    type: string;
}

/*


namespace MedicalInformationType {
    function resource(info: MedicalInformationType) {
        return `users/medical_information/${info}`;
    }
}


enum MedicalInformationType {
    ALLERGY = 'ALLERGY',
    MEDICINE = 'MEDICINE',
    EATING_HABITS = 'EATING_HABITS',
    VACCINATION = 'VACCINATION',
    DISEASE = 'DISEASE',
    OTHERS = 'OTHERS',
    DOCTOR = 'DOCTOR',
    INSURANCE = 'INSURANCE',
}
*/


/**
 * @todo documentation
 *
 * @since {date}
 */
export class MedicalInformationType {
    //------ Instances ------//

    static readonly ALLERGY = new MedicalInformationType('ALLERGY');
    static readonly MEDICINE = new MedicalInformationType('MEDICINE');
    static readonly EATING_HABITS = new MedicalInformationType('EATING_HABITS');
    static readonly VACCINATION = new MedicalInformationType('VACCINATION');
    static readonly DISEASE = new MedicalInformationType('DISEASE');
    static readonly OTHERS = new MedicalInformationType('OTHERS');
    static readonly DOCTOR = new MedicalInformationType('DOCTOR');
    static readonly INSURANCE = new MedicalInformationType('INSURANCE');

    //------ Static Methods ------//

    private constructor(
        /**
         * The name of the instance; should be exactly the variable name,
         * for serializing/deserializing simplicity.
         */
        public readonly name: string,
        // /**
        //  * The index of the instance; should be a unique integer.
        //  */
        // public readonly index: number,
        // /**
        //  * The description of the todo status.
        //  */
        // public readonly description: string
    ) {
    }

    static get values(): MedicalInformationType[] {
        return [
            this.ALLERGY,
            this.MEDICINE,
            this.EATING_HABITS,
            this.VACCINATION,
            this.DISEASE,
            this.OTHERS,
            this.DOCTOR,
            this.INSURANCE,

        ];
    }

    //------ Constructor------//

    get resource() {
        return `users/medical_information/${this.name.toLowerCase()}`;
    }

    //------ Methods ------//

    /**
     * Converts a string to its corresponding MedicalInformationType instance.
     *
     * @param string the string to convert to MedicalInformationType
     * @throws RangeError, if a string that has no corresponding MedicalInformationType value was passed.
     * @returns the matching MedicalInformationType
     */
    static fromString(string: string): MedicalInformationType {
        // Works assuming the name property of the status is identical to the variable's name.
        // Alternatively, you can search the .values array
        const value = (this as any)[string];
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): ${string} does not correspond to any instance of the enum ${
                (this as any).prototype.constructor.name
            }`
        );
    }

    /**
     * Called when converting the Enum value to a string using JSON.Stringify.
     * Compare to the fromString() method, which deserializes the object.
     */
    public toJSON() {
        return this.name;
    }

    public toString() {
        return this.name;
    }


}

export interface PropsWithSelectedUser {
    selectedUser: {
        id?: Identifier,
        parent_id?: Identifier
    }
}

export interface FileRecord {
    src: string,
    id: Identifier,
    title: string
}


export const WorkgroupsFieldValues = {
    faragas : {id: "faragas", name: "Fafaragás"},
    fazekassag : {id: "fazekassag", name: "Fazekasság"},
    furulya : {id: "furulya", name: "Furulyázás (kezdőknek is)"},
    citera : {id: "citera", name: "Citeratanulás (kezdő + haladó, nem kötelező a saját hangszer)"  },
    vonos : {id: "vonos", name: "Hegedű-, brácsa- és bőgőoktatás (nem kezdőknek, csak saját hangszerrel)"},
    nemez : {id: "nemez", name: "Nemezelés"},
}

export const InstrumentsFieldValues = {
    bracsa: {id:'bracsa', name:'Brácsa'},
    bogo: {id:'bogo', name:'Bőgő'},
    cimbalom: {id:'cimbalom', name:'Cimbalom'},
    citera: {id:'citera', name:'Citera'},
    csello: {id:'csello', name:'Cselló'},
    furulya: {id:'furulya', name:'Furulya'},
    gitar: {id:'gitar', name:'Gitár'},
    hegedu:{id:'hegedu', name:'Hegedű'},
    orgona: {id:'orgona', name:'Orgona'},
    zongora: {id:'zongora', name:'Zongora'},

    nem: {id:'nem', name:'Nem játszok (ezeken) hangszer(ek)en'},
}

export interface DocumentsFieldProps {
    record: {
        media: {
            invitation?: FileRecord,
            general_information?: FileRecord,
            camp_regulations?: FileRecord,
            moral_statement?: FileRecord,
            equipment_list?: FileRecord,
            others?: FileRecord[]
        },
        parent?: {
            media?: {
                invitation?: FileRecord,
                general_information?: FileRecord,
                camp_regulations?: FileRecord,
                moral_statement?: FileRecord,
                equipment_list?: FileRecord,
                others?: FileRecord[]
            },
        }
    },
    source: string

}

export interface DocumentProps {
    file: FileRecord | undefined,
    type: string,
    campRef?: string|undefined,
    campName?: string|undefined
}
