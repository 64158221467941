import {useRedirect} from 'react-admin';
import {LoginForm} from "ra-ui-materialui";
import {Link} from "react-router-dom";
import * as React from "react";
import {Typography} from "@material-ui/core";
import {useTranslate} from "ra-core";
import Button from "@material-ui/core/Button";
import {makeStyles, Theme} from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import Login from "./Login";

const useStyles = makeStyles(
    (theme: Theme) => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: 'auto',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage:
                'radial-gradient(circle at 50% 14em, #737850 0%, #4f523d 60%, #4f523d 100%)',
        },

        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            display: "block",
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    {name: 'RaLoginForm'}
);

export const LoginPage = (props: any) => {
    const t = useTranslate();
    const classes = useStyles(props);
    const redirect = useRedirect();

    return (<Login classes={classes} {...props}>
            <LoginForm {...props}/>
            <CardActions>
                <Button
                    variant="contained"
                    type="button"
                    color="primary"
                    className={classes.button}
                    fullWidth
                    onClick={() => {
                        redirect('/register')
                    }}
                >
                    {t('auth.registration')}
                </Button>
            </CardActions>
            <CardActions>
                <Button
                    variant="contained"
                    type="button"
                    color="primary"
                    className={classes.button}
                    fullWidth
                    onClick={() => {
                        redirect('/forgot_password')
                    }}
                >
                    {t('auth.forgotten_password')}
                </Button>
            </CardActions>
            {/*<CardActions>*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        type="button"*/}
            {/*        color="primary"*/}
            {/*        className={classes.button}*/}
            {/*        fullWidth*/}
            {/*        onClick={() => {*/}
            {/*            redirect('/faq')*/}
            {/*        }}*/}
            {/*    ><QuestionAnswerIcon/> GY.I.K. / FAQ</Button>*/}
            {/*</CardActions>*/}
        </Login>
    )
};

