import {ListGuesser} from 'react-admin';
import {AddressEdit} from "./AddressEdit";
import {AddressShow} from "./AddressShow";
import {AddressCreate} from "./AddressCreate";

export {AddressCard} from "./AddressCard";
//
// import {AddressList} from "./AddressList";
// import {AddressEdit} from "./AddressEdit";
// import {AddressCreate} from "./AddressCreate";
// import {AddressShow} from "./AddressShow";
//
// export * from "./UserList";
// export * from "./UserEdit";
// export * from "./UserCreate";
const resource = {
    list: ListGuesser,
    create: AddressCreate,
    show: AddressShow,
    //edit: EditGuesser,
    edit: AddressEdit
    //icon: VisitorIcon,
};
export default resource;
