import React, {Children} from 'react';
import {Loading, ShowView, SimpleShowLayout, useGetIdentity, useGetOne, useShowController} from 'react-admin';
import {ShowContextProvider} from "ra-core";
import {Box} from "@material-ui/core";
import {ScoutInformation} from "../ScoutInformation";
import {CampPermissions} from "../CampPermissions";
import {PostShowActions} from "./PostShowActions";
import {Title} from "./Title";
import {PersonalInformation} from "./PersonalInformation";
import {CampInformation} from "./CampInformation";
import {MedicalInformation} from "./MedicalInformation";
import {OtherOptions} from "./OtherOptions";


export const MemberShow = (props: any) => {
    const controllerProps = useShowController(props);
    const {
        data: camp,
        loading: campLoading,
        loaded: campLoaded,
        error: campError
    } = useGetOne('camps', controllerProps.record?.camp_id, {enabled: !!controllerProps.record?.camp_id});
    const {
        data: user,
        loading: userLoading,
        loaded: userLoaded,
        error: userError
    } = useGetOne('users', controllerProps.record?.user_id, {enabled: !!controllerProps.record?.user_id});
    const {
        identity,
        loading: identityLoading
    } = useGetIdentity();

    if (campLoading || userLoading || identityLoading) {
        return <Loading/>
    }
    const applicationProps = {
        user,
        camp,
        identity, ...controllerProps
    };


    return (<ShowContextProvider value={applicationProps}>
        <ShowView
            {...applicationProps}
            children={<MemberShowView {...applicationProps} children={props.children}/>}
            actions={<PostShowActions/>}
            title={//@ts-ignore
                <Title {...applicationProps}/>}/>
    </ShowContextProvider>)

};

const MemberShowView = (props: any) => {
    const {
        user: userData,
        identity,
        camp,
        record: recordOrigin
    } = props;
    const user = userData || recordOrigin;
    const department_ids = user.department_ids;
    const commanderOf = recordOrigin.currentIsGroupCommander||false;
    const campCommander = recordOrigin.currentIsCampCommander||recordOrigin.currentIsSubCampCommander||false;
    
    

    return (<SimpleShowLayout {...props}>
        <Box p="1em">
            <Box display="flex">
                <Box flex={2} className="ra-field ">
                    <PersonalInformation {...props} record={user}/>
                </Box>
                <Box flex={2} className="ra-field ">
                    <ScoutInformation {...props} isEditable={commanderOf} record={user} resource="users"/>

                </Box>
            </Box>
            <Box display="flex">
                <Box flex={2} className="ra-field ">
                    <MedicalInformation {...props} record={user}/>
                </Box>
                <Box flex={2} className="ra-field ">
                    {camp && <CampInformation {...props} record={camp}/>}
                    {camp && <CampPermissions {...props}
                                              isEditable={(campCommander)}
                                              prefix={null}
                    />}
                </Box>
            </Box>
            <Box display="flex">
                <Box flex={2} className="ra-field ">
                    {camp && <OtherOptions {...props}/>}
                </Box>
                <Box flex={2} className="ra-field ">
                </Box>
            </Box>
        </Box>
        {Children.map(props.children, (child) => React.cloneElement(child, {
            ...props,
            recordOrigin
        }))}
    </SimpleShowLayout>)
}


