import * as React from "react";
import {useState} from "react";
import {BooleanInput, Button, Edit, FileField, FileInput, FunctionField, Labeled, Loading, SimpleForm, TextInput} from 'react-admin';
import {Permissions} from "../../components/Permissions";
import {Redirect, useParams} from "react-router-dom";
import {usePermission} from "../../providers/permissionProvider";
import RichTextInput from 'ra-input-rich-text';
import {Box, Grid, Paper, Typography} from "@material-ui/core";
import get from 'lodash/get';
import lodashGet from 'lodash/get';
import {useTranslate} from "ra-core";
import {DeleteButton, SaveButton} from "ra-ui-materialui";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Toolbar from "../../components/Toolbar";
import dataProvider from "../../providers/dataProvider";
import {CampTransferOptionEdit} from "./components/CampTransferOptionEdit";
import {CampStartAtOptionEdit} from "./components/CampStartAtOptionEdit";
import {CampAddressEdit} from "./components/CampAddressEdit";
import {CampBaseEdit} from "./components/CampBaseEdit";
import {useFormState} from "react-final-form";
import {CampRegosOptionsEdit} from "./components/CampRegosOptionsEdit";

const CampEditToolbar = (props: any) => {
  const [closing, setClosing] = useState(false)
  const form = useFormState();
  const archived = lodashGet(form, 'values.archived', false);
  const {record} = props;
  return (<Toolbar {...props} className="">
    <SaveButton disabled={archived}/>
    {record && typeof record.id !== 'undefined' && (<DeleteButton size="medium" disabled={archived} className="defaultToolbar"/>)}
    {record && typeof record.can_close && !archived && (<Button variant="contained" size="medium" disabled={closing} label="Close" onClick={() => {
      setClosing(true);
      dataProvider.update('camps/close', {id: props.record.id}).finally(() => setClosing(false));
    }}><LockOutlinedIcon/></Button>)}
  </Toolbar>)
}

export const CampEdit = (props: any) => {
  const {
    loaded: pLoaded, hasPermission
  } = usePermission();
  const {record} = props;
  const t = useTranslate();
  const params = useParams<{ id?: string }>();
  if (!pLoaded) {
    return (<Loading/>);
  }
  if (!hasPermission(Permissions.MANAGE_CAMPS)) {
    return <Redirect to={`/camps/${params.id}/show`}/>;
  }
  var toolbarOptions = [['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}], [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    [{'direction': 'rtl'}],                         // text direction

    [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    //[{ 'font': [] }],
    [{'align': []}], ['image', 'link'], ['clean']                                         // remove formatting button
  ]
  const campName = get(record, 'name');
  const campRef = get(record, 'ref');
  const EditTitle = (props: any) => {
    const {
      record, resource
    } = props;
    const campName = `${record.parent ? `${record.parent.name} - ${record?.name}` : record?.name}`;
    const t = useTranslate();
    return <>{t(`resources.${resource}.pageTitle${record?.archived ? 'Archived' : ''}`, {
      campName
    })}</>;
  };
  return (<Edit {...props} title={<EditTitle {...props}/>}>
    <SimpleForm toolbar={<CampEditToolbar/>}>
      {(hasPermission(Permissions.SUPERADMIN) || ((record && record.permissions) || []).includes('camp.owner')) && <BooleanInput source="archived" {...props}/>}
      <Grid container spacing={1} style={{width: "100%"}}>
        <Grid item xs={6}>
          <CampBaseEdit fullWidth={true} {...props}/>
        </Grid>
        <Grid item xs={6}>
          <CampAddressEdit fullWidth={true} {...props}/>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{width: "100%"}}>
        <Grid item xs={6}>
          <CampStartAtOptionEdit/>
        </Grid>
        <Grid item xs={6}>
          <CampTransferOptionEdit/>
        </Grid>
        <Grid item xs={6}>
          {/*<Paper>*/}
          {/*  <Box p="1em">*/}
          {/*    <Typography variant="h6">Bank információk</Typography>*/}
          {/*    <Labeled fullWidth label="bank.name"><TextInput source="bank.name" fullWidth/></Labeled>*/}
          {/*    <Labeled fullWidth label="bank.account_name"><TextInput source="bank.account_name" fullWidth/></Labeled>*/}
          {/*    <Labeled fullWidth label="bank.swift"><TextInput source="bank.swift" fullWidth/></Labeled>*/}
          {/*    <Labeled fullWidth label="bank.iban"><TextInput source="bank.iban" fullWidth/></Labeled>*/}
          {/*  </Box>*/}
          {/*</Paper>*/}
        </Grid>
        <Grid item xs={6}>
          <CampRegosOptionsEdit/>
        </Grid>
      </Grid>
      <Grid style={{width: "100%"}}>
        <Grid item>
          <TextInput multiline source="moral_statement"
                     initialValue={t('resources.media.moral_statement.defaultValue')} fullWidth {...props}/>
        </Grid>
        <Grid item>
          <TextInput multiline source="camp_regulations" initialValue={t('resources.media.camp_regulations.defaultValue')} fullWidth {...props}/>
        </Grid>
      </Grid>

      <RichTextInput source="description" toolbar={toolbarOptions}/>
      <RichTextInput source="email" toolbar={toolbarOptions}/>
      <Typography variant="h6">Letölthető anyagok, ismertetők, dokumentumok:</Typography>
      <FileInput source="media.invitation" label="Meghívó" accept="application/pdf">
        <FileField source="src" target="_blank"
                   title={t(`resources.media.invitation.title`, {
                     campRef, campName, type: 'PDF'
                   })}/>
      </FileInput>
      <FileInput source="media.general_information" label="Általános tudnivalók" accept="application/pdf">
        <FileField source="src" target="_blank"
                   title={t(`resources.media.general_information.title`, {
                     campRef, campName, type: 'PDF'
                   })}/>
      </FileInput>
      <FileInput source="media.camp_regulations" label="Tábori szabályzat" accept="application/pdf">
        <FileField source="src"
                   target="_blank"
                   title={t(`resources.media.camp_regulations.title`, {
                     campRef, campName, type: 'PDF'
                   })}/>
      </FileInput>
      <FileInput source="media.moral_statement" label="Adatkezelési és erkölcsi nyilatkozat"
                 accept="application/pdf">
        <FileField source="src"
                   target="_blank"
                   title={t(`resources.media.moral_statement.title`, {
                     campRef, campName, type: 'PDF'
                   })}/>
      </FileInput>
      <FileInput source="media.equipment_list" label="Felszerelési lista" accept="application/pdf">
        <FileField source="src" target="_blank"
                   title={t(`resources.media.equipment_list.title`, {
                     campRef, campName, type: 'PDF'
                   })}/>
      </FileInput>
      <FileInput source="media.others" label="Egyéb anyagok" accept="application/pdf" multiple={true}>
        <FunctionField
          render={(record: any) => <FileField record={{src: record.undefined, ...record}} source="src"
                                              target="_blank"
                                              title={t(`resources.media.others.title`, {
                                                title: record.title || record.rawFile.name, campRef, campName, type: 'PDF'
                                              })}/>}/>
      </FileInput>
    </SimpleForm>
  </Edit>)
};
