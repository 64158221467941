import {useDataProvider, useNotify, useVersion} from "react-admin";
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const EmailVerify = ({staticContext, ...props}: any) => {
    const dataProvider = useDataProvider();
    const version = useVersion();
    const notify = useNotify();
    const {...urlParams} = useParams();
    const [verified, setVerified] = useState<boolean>(false);
    const verifyEmail = useCallback(async () => {
        const {data: recentCamps} = await dataProvider.create('auth/verify', {data: urlParams});
        setVerified(true);
    }, [dataProvider]);

    useEffect(() => {
        !verified && verifyEmail();
    }, [version, verified]);

    return <div>{verified ? 'Verified' : 'Not verified'}</div>
};