import React, {useCallback, useEffect, useState} from 'react';
import {ArrayField, Datagrid, FunctionField, Loading, RichTextField, Show, ShowButton, TextField, useAuthState, useDataProvider, useGetIdentity} from 'react-admin';
import {Box, Card, CardContent, Typography} from '@material-ui/core';
import {usePermission} from "../../providers/permissionProvider";
import {Permissions} from "../../components/Permissions";
import {CampType} from "./CampType";
import {CRUD_GET_ONE, useGetOne, useResourceContext, useTranslate} from "ra-core";
import {CampCommander} from "../../components/CampCommander";
import {AddressCard} from "../../models/addresses";
import {CampSubList} from "./CampSubList";
import {ApplicationButton} from "../../components/ApplicationButton";
import {StatusField} from "../../components/StatusField";
import {DocumentsField} from "./DocumentsField";


export const CampShow = (props: any) => {
  const {id} = props;
  const resource = useResourceContext(props);
  const {
    loading: authLoading
  } = useAuthState();
  const {
    data,
    loading
  } = useGetOne<CampType>(
    resource,
    id,
    {
      action: CRUD_GET_ONE
    }
  );

  if (authLoading || loading) {
    return <Loading/>
  }
  return <CampShowView {...props} record={data}/>
};


export const CampShowView = (props: any) => {
  const dataProvider = useDataProvider();
  const {hasPermission} = usePermission();
  const {record} = props;
  const {
    loading: authLoading,
    loaded: authLoaded,
    authenticated
  } = useAuthState();
  const isAutenticated = (authLoaded && authenticated);
  const {identity} = useGetIdentity();

  const [state, setState] = useState<{ recentCamps?: Array<CampType>, parentCamp?: CampType }>({});

  const fetchChildCamps = useCallback(async () => {
    if (record !== undefined) {
      await dataProvider.getList<CampType>(
        'camps',
        {
          sort: {
            field: 'name.keyword',
            order: 'ASC'
          },
          pagination: {
            page: 1,
            perPage: 10
          },
          filter: {"parent.id": record.id}
        }
      ).then((recentCamps) => {
        setState(state => ({
          ...state,
          recentCamps: recentCamps.data
        }));

      }).catch(() => {
      });
    }
  }, [dataProvider, isAutenticated, record]);
  const fetchParentCamp = useCallback(async () => {
    if (record !== undefined && record.parent) {
      await dataProvider.getOne<CampType>(
        'camps',
        {
          id: record.parent?.id
        }
      ).then((parentCamp) => {
        setState(state => ({
          ...state,
          parentCamp: parentCamp.data
        }));

      }).catch(() => {
      });
    }
  }, [dataProvider, isAutenticated]);
  useEffect(() => {
    //@ts-ignore
    isAutenticated && fetchChildCamps() && fetchParentCamp();
  }, [isAutenticated]);

  if (!props.record) {
    return <Loading/>;
  }
  const {
    permissions,
    commander,
    address,
  } = record;

  let actions: undefined | false = false;
  if (hasPermission(Permissions.SUPERADMIN) || record.commander.id == identity?.id || permissions.includes('camp.update')) {
    actions = undefined;
  } else if (record.archived) {
    actions = false;
  }
  //const campName = `${parent ? `${parent.name} - ${record?.name}` : record?.name}`;
  const campName = record?.name;

  if (authLoading && !authLoaded) {
    return <Loading/>
  }

  return (
    <Show {...props} actions={actions} title={<EditTitle {...props}/>}>
      <Card>
        <CardContent>
          <Typography
            variant="h2"
            component="h2"
            align="center"
          >
            {campName}
          </Typography>
          <Box p="1em" textAlign="right">
            {!(state.recentCamps && state.recentCamps.length) && <ApplicationButton record={record}/>}
          </Box>
          <Card>
            <CardContent>
              <CampCommander linkTo={true} {...commander}/>
              <AddressCard address={address || state.parentCamp?.address}/>
              <RichTextField source="description" record={record} id="camp_description"/>
              <DocumentsField source="media" record={record}/>
            </CardContent>
          </Card>
          {(state.recentCamps && state.recentCamps.length &&
              <Card>
                  <CardContent>
                      <CampSubList recentCamps={(state.recentCamps || [])}/>
                  </CardContent>
              </Card>

          ) || null}
          <Box p="1em">
            {!(state.recentCamps && state.recentCamps.length) && <ApplicationButton record={record}/>}
          </Box>
          {(record.applications && record.applications.length &&
              <ArrayField source="applications" record={record} >
                  <Datagrid>
                      <TextField source="last_name" label="resources.applications.fields.last_name"/>
                      <TextField source="first_name" label="resources.applications.fields.first_name"/>
                      <StatusField source="pivot.status" label="resources.applications.fields.status"/>
                      <FunctionField render={(props: any) => {
                        return <ShowButton basePath="/applications" record={props.pivot}/>
                      }}/>
                  </Datagrid>
              </ArrayField>) || null}
        </CardContent>
      </Card>

    </Show>
  )
}
const EditTitle = (props: any) => {
  const {
    record,
    resource
  } = props;
  //const campName = `${record.parent ? `${record.parent.name} - ${record?.name}` : record?.name}`;
  const campName = record?.name;
  const t = useTranslate();
  return <>{t(`resources.${resource}.pageTitle${record?.archived ? 'Archived' : ''}`, {
    campName
  })}</>;
};
