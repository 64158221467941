/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
import _ from 'lodash';

_.mixin({
    deeply: function (map) {
        return function (obj, fn) {
            return map(_.mapValues(obj, function (v) {
                return _.isPlainObject(v) ? _.deeply(map)(v, fn) : v;
            }), fn);
        }
    },
});


const sendMediaFile = ({dataProvider, media, type}) => {
    if (media && media.rawFile instanceof File) {
        return dataProvider.create('media', {data: {type, [type]: media}});
    }
    return Promise.reject('Doesn\'t have any update data');
}

const addUploadCapabilities = dataProvider => ({
    ...dataProvider,
    update: (resource, params) => uploader(dataProvider, params, resource, 'update'),
    create: (resource, params) => uploader(dataProvider, params, resource, 'create'),
});

const uploader = (dataProvider, params, resource, method) => {
    if (!/rawFile/.test(JSON.stringify(params.data))) {
        // fallback to the default implementation
        return dataProvider[method](resource, params);
    }

    const paths = [];
    const getPaths = (obj, path = '') => {
        Object.keys(obj).forEach((item) => {
            const newPath = `${path}.${item}`.replace(/^\./, '').replace(/\.$/, '');
            if (obj.rawFile) {
                paths.push(newPath);
            } else if (/rawFile/.test(JSON.stringify(obj[item]))) {
                getPaths(obj[item], newPath);
            }
        })
    }
    getPaths(params.data);
    const promises = [];
    paths.filter((p) => /rawFile/.test(p))
        .map((p) => p.replace(/rawFile/, '').replace(/^\./, '').replace(/\.$/, ''))
        .forEach((path) => {
            promises.push(sendMediaFile({
                dataProvider,
                media: _.get(params.data, path),
                type: 'media'
            }).then(({data}) => ({...data, path})));
        });

    return Promise.all(promises).then((values) => {
        values.forEach(({path, ...data}) => {
            _.set(params, `data.${path}`, data);
        })
    }).then(() => dataProvider[method](resource, params));
}


/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

export default addUploadCapabilities;
