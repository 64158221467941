enum ApplicationStates {
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    ACCEPTED_BY_COMMANDER = 'acceptedByCommander',
    ACCEPTED_BY_CAMP_COMMANDER = 'acceptedByCampCommander',
    ACCEPTED_BY_SUB_CAMP_COMMANDER = 'acceptedBySubCampCommander',
    BLOCKED = 'blocked',
    DELETED = 'deleted',
    DECLINED = 'declined'
}
export default ApplicationStates;