import {Box, Paper, Typography} from "@material-ui/core";
import {ArrayInput, DateInput, SimpleFormIterator} from "react-admin";
import * as React from "react";

export function CampStartAtOptionEdit() {
    return <Paper><Box p="1em">
        <Typography variant="h6">Csatlakozási lehetőségek</Typography>
        <ArrayInput source="options.starts" label="Egyéb csatlakozási időpontok">
            <SimpleFormIterator>
                <DateInput source="start_at"/>
            </SimpleFormIterator>
        </ArrayInput></Box></Paper>;
}