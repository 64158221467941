import * as React from "react";
import {
    ChipField,
    Datagrid,
    EmailField,
    Filter,
    List,
    ReferenceArrayField,
    SearchInput,
    SingleFieldList
} from 'react-admin';
import {PropsWithPermissions, usePermission} from "../../providers/permissionProvider";
import {Permissions} from "../../components/Permissions";
import {StatusField, StatusFilter} from "../../components/StatusField";
import FullNameField from "../../components/FullNameField";

const PostFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
        <StatusFilter source="status" alwaysOn/>

    </Filter>
);
export const UserList = (props: PropsWithPermissions) => {
    const {hasPermission} = usePermission();
    if (!hasPermission(Permissions.MANAGE_USERS)) {
        return null;
    }
    // @ts-ignore
    return (
        <List {...props} filters={<PostFilter/>}>
            <Datagrid rowClick="show">
                <FullNameField source="fullName" size="35"/>
                <EmailField source="email"/>
                <ReferenceArrayField source="department_ids" reference="departments" label='Csapat'>
                    <SingleFieldList>
                        <ChipField source="title"/>
                    </SingleFieldList>
                </ReferenceArrayField>
                <StatusField source="status" addLabel={true}/>
            </Datagrid>
        </List>
    );
};
