import {Identifier, useTranslate} from "ra-core";
import {
    Create,
    Loading,
    useAuthProvider,
    useDataProvider,
    useGetIdentity,
    useGetList,
    useGetOne,
    useNotify
} from "react-admin";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {CampApplyForm} from "./CampApplyForm";
import {useSelectedUser} from "../../providers/userProvider";
import {usePermission} from "../../providers/permissionProvider";
import {useRedirect} from "ra-core/esm/sideEffect";

export const UserCampApplyPage = (props: any) => {
    const t = useTranslate();
    var redirect = useRedirect();
    const dataProvider = useDataProvider();
    const authProvider = useAuthProvider();
    // @ts-ignore
    const {
        id,
        userId
        // @ts-ignore
    } = useParams<{ id?: Identifier; userId?: Identifier; }>();
    //const [camp, setCamp] = useState<CampType>()
    const notify = useNotify();
    const [saving, setSaving] = useState<boolean>();
    // @ts-ignore
    const {
        loaded: identityLoaded,
        identity
    } = useGetIdentity();
    const {
        selectedUser,
        setSelectedUser
    } = useSelectedUser();
    const {
        data: camp,
        loaded: campLoaded
        // @ts-ignore
    } = useGetOne('camps', id);

    const {
        hasPermission,
        loaded: permissionsLoaded
    } = usePermission();
    const {
        data: children,
        loaded: childrenLoaded
    } = useGetList('family', {
        page: 1,
        perPage: 10
    }, {
        field: 'first_name',
        order: 'ASC'
    }, {});

    const createProps = {
        ...props,
        basePath: `/camps/${id}`,
        resource: `users`, // @ts-ignore
        record: {    // @ts-ignore
            user_id: selectedUser?.id,
            camp,
        }
    };

    const handleSave = useCallback((values) => {
        setSaving(true);
        dataProvider.updateUserProfile({data: values}, {
            onSuccess: ({data}: any) => {
                setSaving(false);
                notify("Your profile has been updated", "info", {
                    _: "Your profile has been updated"
                });
            },
            onFailure: (result: any) => {
                setSaving(false);
                notify("A technical error occured while updating your profile. Please try later.", "warning", {
                    _: "A technical error occured while updating your profile. Please try later."
                });
            }
        });
    }, [dataProvider, notify]);

    useEffect(() => {
        if (childrenLoaded && identityLoaded && identity) {
            if (userId) {
                if (identity?.id == userId) {
                    setSelectedUser(identity)
                } else if (children && children[userId]) {
                    const child = children[userId];
                    setSelectedUser(child)
                }
                // @ts-ignore
            } else if (identity?.id !== selectedUser?.id) {
                setSelectedUser(identity);
            }
        }
    }, [identityLoaded, childrenLoaded, identity])


    const saveContext = useMemo(() => ({
        save: handleSave,
        saving
    }), [saving, handleSave]);
    const campName = `${camp?.parent ? `${camp?.parent?.name} - ` : ''}${camp?.name}`;
    const loading = !campLoaded || !identityLoaded || !permissionsLoaded;

    if (loading) return <Loading/>;
    const transform = (data: any) => {
        return ({
            user_id: data.user_id,
            options: data.options,
            camp: {id: data.camp.id},
            agree_moral_statement: data.agree_moral_statement,
            agree_camp_regulations: data.agree_camp_regulations,
            user: {
                medical_information: data.user.medical_information,
                date_of_birth: data.user.date_of_birth,
                place_of_birth: data.user.place_of_birth,
                isParent: data.user.isParent,
                email: data.user.email,
                mobile: data.user.mobile,
                first_name: data.user.first_name,
                last_name: data.user.last_name,
                id: data.user.id,
            }
        })
    }
    return (<Create title={`${t('resources.users.campApplicationPage')} - ${campName}`} {...createProps}
                    resource="camps/apply" onFailure={() => {
    }} transform={transform} onSuccess={(_a) => {
        var newRecord = _a.data;
        notify(
            'ra.notification.created', 'info', {
                smart_count: 1,
            });
        redirect('show', '/camps', newRecord.id, newRecord);
        authProvider.refresh();

    }}>
        <CampApplyForm {...{
            selectedUser,
            setSelectedUser
        }} campName={campName}
                       scoutChildren={(childrenLoaded && identityLoaded && hasPermission('manage.family')) ? children : null}/>
    </Create>);
}
