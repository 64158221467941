import MemberStates from "./MemberStates";
import {MemberRecord} from "./MemberRecord";
import Node from "../../components/statemachine/Node";

export default class MemberStateMachine {

    static nodes: Node<MemberRecord>[] = [
        new Node<MemberRecord>(MemberStates.PENDING, MemberStates.ACCEPTED, (record, hasPermission?: Function, identity?: any) => {
            return identity && identity.isCommanderOf(record.department_ids);
            //return record.currentIsGroupCommander
        }),
        new Node<MemberRecord>(MemberStates.PENDING, MemberStates.DECLINED, (record, hasPermission?: Function, identity?: any) => {
            return identity && identity.isCommanderOf(record.department_ids);
            //return record.currentIsGroupCommander
        }),
        new Node<MemberRecord>(MemberStates.DECLINED, MemberStates.ACCEPTED, (record, hasPermission?: Function, identity?: any) => {
            return identity && identity.isCommanderOf(record.department_ids);
            //return record.currentIsGroupCommander
        })
    ];
    static next = (status: string, record: MemberRecord, hasPermission: Function, identity: any): string | undefined => {
        const next = MemberStateMachine.nodes.filter((node) => {
            return node.from === status && node.callback(record, hasPermission, identity);
        }).shift();
        return (next && `${next.to}:${identity.commanderOf.join()}`) || undefined;
    }

}