import get from "lodash/get";
import {AutocompleteArrayInput, BooleanInput, RadioButtonGroupInput, TextInput, required} from "react-admin";
import React from "react";
import {Box} from "@material-ui/core";
import {Record} from "ra-core";
import {InstrumentsFieldValues, WorkgroupsFieldValues} from "../../../../types";

interface CustomCampProps {
  record: Record,
  camp: Record,
}

const RegosBox = ({
                    record, camp
                  }: CustomCampProps) => {
  const dataset = get(camp, 'options.regos', {enabled: false});
  if (!dataset.enabled) {
    return null;
  }
  const workgroups = [
    WorkgroupsFieldValues.faragas,
    //WorkgroupsFieldValues.fazekassag,
    //WorkgroupsFieldValues.furulya,
    WorkgroupsFieldValues.citera,
    WorkgroupsFieldValues.vonos,
    WorkgroupsFieldValues.nemez
  ];

  const instruments = [
    InstrumentsFieldValues.hegedu,
    InstrumentsFieldValues.bracsa,
    InstrumentsFieldValues.bogo,
    InstrumentsFieldValues.csello,
    InstrumentsFieldValues.citera,
    InstrumentsFieldValues.gitar,
    InstrumentsFieldValues.furulya,
    InstrumentsFieldValues.orgona,
    InstrumentsFieldValues.nem,
  ];



  return <Box p="1em">
    <AutocompleteArrayInput source="options.regos.instruments"  record={record}
                            label="Milyen hangszeren játszol? "
                            choices={instruments} validate={required()}
                            fullWidth />
    <RadioButtonGroupInput 
      label="Van saját hangszered, amit el is tudsz hozni?" 
      source="options.regos.own_intrument"
      fullWidth record={record} 
      choices={[
        { id: 1, name: 'Van' },
        { id: 0, name: 'Nincs' },
      ]}
      validate={required()} />
    <AutocompleteArrayInput source="options.regos.workgroups"  record={record}
                            label="Milyen munkacsoporton szeretnél résztvenni? Tedd sorrendbe prioritás szerint a munkacsoportokat!"
                            choices={workgroups} validate={required()} fullWidth/>
    <RadioButtonGroupInput label="Van viseleted?" source="options.regos.full_outfit" choices={[
    { id: 1, name: 'Van' },
    { id: 2, name: 'Nincs, de van lehetőség beszerezni' },
    { id: 0, name: 'Nincs' },
]} validate={required()} />

    </Box>;
}
export default RegosBox;
