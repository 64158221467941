import {Box, Paper, Typography} from "@material-ui/core";
import {required, useGetIdentity} from "react-admin";
import React, {useEffect, useState} from "react";
import CreateIcon from '@material-ui/icons/Create';
import {EditableField, FieldEnum} from "./EditableField";
import {CampType} from "../../../pages/camps";
import {UserRecord} from "../../RecordTypes";
import {useTranslate} from "ra-core";
import IconButton from "@material-ui/core/IconButton";
import {useFormState} from "react-final-form";
import {isEmpty, sanitizeProps} from "../../../components/Helpers";

export const BaseData = (props: { record: UserRecord | CampType } | any) => {
    const {selectedUser} = props;
    const t = useTranslate();
    const [isEdit, setEdit] = useState<boolean>(false);
    const formProps = sanitizeProps({isEdit, setEdit, ...props});
    const formState = useFormState();

    const validate = (values: any) => {
        if (isEmpty(values.last_name)) {
            return false;
        }
        if (isEmpty(values.first_name)) {
            return false;
        }
        if (isEmpty(values.date_of_birth)) {
            return false;
        }
        if (isEmpty(values.place_of_birth)) {
            return false;
        }
        return true;
    }
    useEffect(() => {
        if (!isEdit && !validate(formState.values)) {
            setEdit(true);
        }
    }, [isEdit, formState.values])
    return (
        <Paper>
            <Box margin="1em">
                <Typography
                    variant="h6">{t('components.BaseData.title')}<IconButton onClick={() => {
                    setEdit(!isEdit)
                }}><CreateIcon/></IconButton></Typography>
                <input type="hidden" name="user_id" value={selectedUser?.id}/>
                <EditableField fieldType={FieldEnum.TEXT} source="last_name" {...formProps} validate={required()}/>
                <EditableField fieldType={FieldEnum.TEXT} source="first_name" {...formProps} validate={required()}/>
                <EditableField fieldType={FieldEnum.DATE} source="date_of_birth" {...formProps} validate={required()}/>
                <EditableField fieldType={FieldEnum.TEXT} source="place_of_birth" {...formProps} validate={required()}/>
                {/*<EditableField fieldType={FieldEnum.REF_SELECT}*/}
                {/*               optionText="title"*/}
                {/*               reference="departments"*/}
                {/*               label="resources.departments.groupName"*/}
                {/*               source={`departments.${(departments.length - 1)}.id`}*/}
                {/*               sourceName={`departments.${(departments.length - 1)}.title`}*/}
                {/*               {...formProps} isEdit={false}*/}
                {/*/>*/}
                <EditableField fieldType={FieldEnum.BOOLEAN} source="swimming"  {...formProps} />

                {!selectedUser.parent_id &&
                <EditableField fieldType={FieldEnum.BOOLEAN} source="isParent" {...formProps}/>}
            </Box>
        </Paper>
    )
}
