import * as React from 'react';
import {FunctionComponent} from 'react';
import PropTypes from 'prop-types';
import {FieldTitle, InputProps, useInput} from 'ra-core';
import {TextFieldProps} from '@material-ui/core/TextField';

import {InputHelperText, ResettableTextField, sanitizeInputRestProps} from 'ra-ui-materialui';

export type TextInputProps = InputProps<TextFieldProps> &
    Omit<TextFieldProps, 'label' | 'helperText'>;

/**
 * An Input component for a string
 *
 * @example
 * <TextInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
const TextInput: FunctionComponent<TextInputProps> = ({
                                                          label,
                                                          format,
                                                          helperText,
                                                          onBlur,
                                                          onFocus,
                                                          onChange,
                                                          options,
                                                          parse,
                                                          resource,
                                                          source,
                                                          validate,
                                                          ...rest
                                                      }) => {
    const {
        id,
        input,
        isRequired,
        meta: {touched, error, submitError},
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'text',
        validate,
        ...rest,
    });
    // console.debug({
    //     label,
    //     format,
    //     helperText,
    //     onBlur,
    //     onFocus,
    //     onChange,
    //     options,
    //     parse,
    //     resource,
    //     source,
    //     validate,
    //     ...rest
    // });
    // @ts-ignore
    return (
        <ResettableTextField
            id={id}
            {...input}
            label={
                label !== '' &&
                label !== false && (
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                )
            }
            error={!!(touched && (error || submitError))}
            helperText={
                <InputHelperText
                    touched={!!touched}
                    error={error || submitError}
                    helperText={helperText}
                />
            }
            {...options}
            {...sanitizeInputRestProps(rest)}
        />
    );
};

// @ts-ignore
TextInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    resource: PropTypes.string,
    //source: PropTypes.string,
};

TextInput.defaultProps = {
    options: {},
};

export default TextInput;
