import {createSvgIcon} from '@material-ui/core/utils'

export default createSvgIcon(
    <svg version="1.1"
         x="0px" y="0px"
         viewBox="0 0 256 256">
        <style type="text/css">{`
            .st0{fill:#E8E4D8;}
            .st1{fill:#FCD258;stroke:#FDCC68;stroke-width:4;stroke-miterlimit:10;}
            .st2{fill:none;stroke:#FCD258;stroke-width:40;stroke-miterlimit:10;}
    `}
        </style>
        <g>
            <circle className="st0" cx="128" cy="128" r="125"/>
            <rect x="15" y="108" className="st1" width="226" height="40"/>
            <ellipse className="st2" cx="128" cy="128" rx="108" ry="108"/>
        </g>
    </svg>

    , 'STScoutIcon');