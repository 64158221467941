import WatchLaterIcon from '@material-ui/icons/WatchLater';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {makeStyles} from "@material-ui/core/styles";
import get from "lodash/get";
import Chip from "@material-ui/core/Chip";
import classnames from "classnames";
import sanitizeFieldRestProps from "ra-ui-materialui/lib/field/sanitizeFieldRestProps";
import * as React from "react";
import {InjectedFieldProps, PublicFieldProps} from "ra-ui-materialui/src/field/types";
import useTranslate from "ra-core/esm/i18n/useTranslate";
import {Record, SelectInput} from "react-admin";
import {fieldPropTypes} from "ra-ui-materialui/lib/field/types";


const useStyles = makeStyles(
    theme => ({
        pending: {
            color: theme.palette.warning.main,
        },
        accepted: {
            color: theme.palette.success.main,
        },
        decliend: {
            color: theme.palette.error.main,
        },
        chip: {margin: 4, cursor: 'inherit'},

    }),
    {name: 'RaChipField'}
);


// @ts-ignore
interface StatusFieldProps extends PublicFieldProps, InjectedFieldProps {
    source?: string;
    sources?:Record[];
    parser?: Function;
}

export const StatusField = (props: StatusFieldProps) => {
    var translate = useTranslate();

    // @ts-ignore
    const {
        // @ts-ignore
        className,
        // @ts-ignore
        classes: classesOverride,
        // @ts-ignore
        source,
        // @ts-ignore
        sources,
        // @ts-ignore
        resource,
        // @ts-ignore
        record = {},
        // @ts-ignore
        emptyText,
        parser,
        ...rest
    } = props;
    // @ts-ignore
    const classes = useStyles(props);
    const currentSourceRecord = sources?.find((item:Record) => get(item, 'id') === get(record, 'id'))||record;
    const value = (typeof parser === 'function' ? parser(currentSourceRecord) : get(currentSourceRecord, (source||'').toString())) || 'pending';
    const isLTC = !!get(record, `camp.is_ltc`) || false;
    const newProps = {className: '', icon: <></>};
    switch (value) {

        case undefined:
        case 'pending':
            newProps.className = classes.pending;
            newProps.icon = <WatchLaterIcon/>;
            break;
        case 'accepted':
        case 'acceptedByCommander':
        case 'acceptedByCampCommander':
        case 'acceptedBySubCampCommander':
            newProps.className = classes.accepted;
            newProps.icon = <CheckCircleIcon/>;
            break;
        case 'deleted':
        case 'declined':
            newProps.className = classes.decliend;
            newProps.icon = <CancelIcon/>;
            break;
    }
    const prefix = isLTC ? 'isLTC.' : '';

    return <Chip
        className={classnames(classes.chip, newProps.className)}
        label={translate(`status.${prefix}${value}`)}
        {...sanitizeFieldRestProps({...rest, icon: newProps.icon})}
    />
}
StatusField.propTypes = fieldPropTypes;

export const StatusFilter = (props: StatusFieldProps) => {
    var translate = useTranslate();
// @ts-ignore
    const {
        // @ts-ignore
        className,
        // @ts-ignore
        classes: classesOverride,
        // @ts-ignore
        source,
        // @ts-ignore
        resource,
        // @ts-ignore
        record = {},
        // @ts-ignore
        emptyText,
        ...rest
    } = props;
    const isLTC = !!get(record, `camp.is_ltc`) || false;
    const prefix = isLTC ? 'isLTC.' : '';

    return <SelectInput
        {...props}
        choices={[
            {id: 'pending', name: translate(`status.${prefix}pending`)},
            {id: 'accepted', name: translate(`status.${prefix}accepted`)},
            {id: 'acceptedByCommander', name: translate(`status.${prefix}acceptedByCommander`)},
            {id: 'acceptedByCampCommander', name: translate(`status.${prefix}acceptedByCampCommander`)},
            {id: 'acceptedBySubCampCommander', name: translate(`status.${prefix}acceptedBySubCampCommander`)},
            {id: 'deleted', name: translate(`status.${prefix}deleted`)},
            {id: 'declined', name: translate(`status.${prefix}declined`)},

        ]}
        source="status"
    />
}
StatusFilter.propTypes = fieldPropTypes;

StatusFilter.defaultProps = {
    alwaysOn: false
}

// @ts-ignore
//export const StatusField = translate(StatusFieldView);

