import {Datagrid, DateField, FunctionField, ReferenceArrayField, ReferenceField} from "react-admin";
import {StatusField} from "../../components/StatusField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {Typography} from "@material-ui/core";
import React from "react";

const ApplicationsList = (props: any) => {
    return (<ReferenceArrayField source="application_ids"
                                 reference="applications"
                                 label="resources.members.fields.applications"
                                 addLabel={true}
                                 sort={{
                                     field: 'start_at',
                                     order: 'desc'
                                 }}
                                 {...props}
    >
        <Datagrid {...props} >
            <ReferenceField source="camp_id" reference="camps" label="resources.camps.fields.name">
                <FunctionField render={(record: any) => {
                    const campName = record.parent ? `${record.parent.name} - ${record.name}` : record.name;

                    return <Typography variant="body2" style={{margin: 10}}>{campName}</Typography>
                }}/>
            </ReferenceField>
            <StatusField source="status" label="resources.members.fields.status"/>
            <ReferenceField source="camp_id" reference="camps" label="resources.camps.fields.start_at">
                <DateField source="start_at"/>
            </ReferenceField>
            <ReferenceField source="camp_id" reference="camps" label="resources.camps.fields.finish_at">
                <DateField source="start_at"/>
            </ReferenceField>
            <Actions label={false}/>
        </Datagrid>
    </ReferenceArrayField>);
}
const Actions = (props: any) => {
    const {record} = props;
    return <>{record?.camp?.archived ? <LockOutlinedIcon/> : null}</>
};

export default ApplicationsList;