import * as React from "react";
import {createElement, useMemo, useRef} from "react";
import {Route, useParams} from "react-router-dom";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {Card, Theme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import classnames from "classnames";
import {RegistrationEdit} from "../models/users/RegistrationEdit";
import CardMedia from '@material-ui/core/CardMedia';
import logo from '../images/kmcssz-europa-logo.png'
import {Loading, useGetOne} from "react-admin";
import get from 'lodash/get';
import {useTranslate} from "ra-core";

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: 'radial-gradient(circle at 50% 14em, #737850 0%, #4f523d 60%, #4f523d 100%)',
    },
    card: {
        minWidth: 300,
        maxWidth: 450,
        marginTop: '6em',
        width: '40%',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        // @ts-ignore
        backgroundColor: theme.palette.secondary[500],
    },
    media: {
        //height: 71,
        width: '100%'
    }
}), {name: 'RaLogin'});
// @ts-ignore
export const RegLink = <Route key="register" path="/register/:departmentId?" noLayout={true}
                              render={() => <RegistrationPage noLayout={true}/>}/>

const RegistrationPage = (props: any) => {
    const containerRef = useRef<HTMLDivElement>();
    const {
        theme,
        children,
        className,
        notification,
        ...rest
    } = props;
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    const {departmentId: dId} = useParams<{ departmentId?: string }>();
    const departmentId = dId ? parseInt(dId) : 0;
    const t = useTranslate();
    const {
        data,
        loading
    } = useGetOne('groups', departmentId);
    const classes = useStyles();

    if (loading) {
        return <Loading/>
    }
    return <ThemeProvider theme={muiTheme}>
        <div
            className={classnames(classes.main, className)}
            {...rest}
            ref={containerRef}
        >
            <Card className={classes.card}>
                <div className={classes.avatar}>
                    <CardMedia
                        className={classes.media}
                        image={    // @ts-ignore
                            get(data, 'media.header.url') || logo}
                        title={t(        data?.title || "resources.pages.registration.title")}
                        component="img"
                    />
                </div>
                <RegistrationEdit department={data}/>
            </Card>
            {notification ? createElement(notification) : null}
        </div>
    </ThemeProvider>
}
