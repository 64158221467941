import {EditableField, FieldEnum} from "../models/users/components/EditableField";
import {ChipField, Datagrid, ReferenceArrayField, SingleFieldList, useGetIdentity, useRecordContext} from "react-admin";
import React from "react";
import {EditableComponent} from "./EditableComponent";
import {StatusField} from "./StatusField";

export const ScoutInformation = (props: any) => {
    const record = useRecordContext(props);
    const {prefix} = props;
    const {identity} = useGetIdentity();
    return (<EditableComponent {...props} label={'Cserkész információk'}
                               isEditable={identity?.isCommanderOf(props.record?.department_ids) || !props.record || identity?.id == props.record.id || identity?.id == props.record.parent?.id}>
        <EditableField source="rank" label="resources.members.fields.rank" fieldType={FieldEnum.RANK} {...props} />
        {/* <ReferenceArrayField source="department_ids" reference="departments" label="resources.members.fields.scouts"             sort={{
              field: 'title',
              order: 'ASC'
            }}
>
            <Datagrid>
                <ChipField source="title" label="resources.members.fields.departments"/>
                <StatusField source="status" label="resources.members.fields.status"/>
            </Datagrid>
        </ReferenceArrayField> */}
        <EditableField
            source="department_ids" reference="departments"
            sort={{
              field: 'title',
              order: 'ASC'
            }}
            fieldType={FieldEnum.REFARRAY}
            label=""
            addLabel={true}
            fullWidth={true}
            {...props} >
            <EditableField fieldType={FieldEnum.TEXT} source="title" label="resources.members.fields.departments"/>
            <StatusField source="status" sources={record.departments} label="resources.members.fields.status"/>
        </EditableField>
    </EditableComponent>)
};
