import {ApplicationList} from "./ApplicationList";
import {ApplicationShow} from "./ApplicationShow";
import WorkIcon from '@material-ui/icons/Work';
import {Permissions} from "../../components/Permissions";

const resource = {
    list: ApplicationList,
    show: ApplicationShow,
    edit: ApplicationShow,
    icon: WorkIcon,
    options: {
        list: {
            permissions: (r: object, hasPermission: Function) => {
                return hasPermission(Permissions.MANAGE_APPLICATIONS) || hasPermission(Permissions.MANAGE_CAMP_APPLICANTS);
            }
        }
    },

};

export default resource;
