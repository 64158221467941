import Avatar from "@material-ui/core/Avatar";
import React from "react";
import {Link} from 'react-router-dom';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Identifier} from "react-admin";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            //justifyContent: 'center',
        },
        small: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
        text: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(0),
            verticalAlign: 'center'
        }
    }),
);


interface AvatarWithNameType {
    avatar?: string;
    last_name?: string;
    first_name?: string;
    linkTo?: boolean;
    id?: Identifier;
}

export const AvatarWithName = ({avatar, first_name, id, last_name, linkTo}: AvatarWithNameType) => {
    const classes = useStyles();
    const name = `${last_name} ${first_name}`;
    return (<div className={classes.root}>
        <Avatar alt={name} src={avatar} className={classes.small}/>
        {linkTo ? <Link to={`/users/${id}/show`} className={classes.text}>{name}</Link> :
            <div className={classes.text}>{name}</div>}
    </div>);
}
