import React, {useEffect, useState} from "react";
import {Box, Paper, Typography} from '@material-ui/core';
import {EditableField, FieldEnum} from "./EditableField";
import CreateIcon from '@material-ui/icons/Create';
import IconButton from "@material-ui/core/IconButton";
import {Alert} from "@material-ui/lab";
import {useFormState} from "react-final-form";
import {isEmpty, sanitizeProps} from "../../../components/Helpers";
import {required} from "react-admin";
import {useTranslate} from "ra-core";

export const ContactInformation = (props: any) => {
    const {selectedUser} = props;
    const [isEdit, setEdit] = useState<boolean>(false);
    const formState = useFormState();
    const formProps = sanitizeProps({
        isEdit,
        setEdit, ...props
    });
    const t = useTranslate();

    const validate = (values: any) => {
        if (isEmpty(values.email)) {
            return false;
        }
        if (isEmpty(values.mobile)) {
            return false;
        }
        return true;
    }
    useEffect(() => {
        if (!isEdit && !validate(formState.values)) {
            setEdit(true);
        }
    }, [isEdit, formState.values])
    const validations = selectedUser?.parent_id ? [] : required();
    return (<Paper>
        <Box margin="1em">
            <Typography variant="h6">Elérhetőségek
                <IconButton onClick={() => {
                    setEdit(!isEdit)
                }}><CreateIcon/></IconButton>
            </Typography>

            {isEdit && selectedUser?.parent_id && <Alert severity="warning"><span dangerouslySetInnerHTML={{__html: t('resources.users.contact.info')}}/></Alert>}
            <EditableField fieldType={FieldEnum.TEXT} source="email" {...formProps} validate={validations}/>
            <EditableField fieldType={FieldEnum.TEXT} source="mobile" {...formProps} />
        </Box>
    </Paper>)

};
