import React from "react";
import {PersonalData} from "./PersonalData";
import {BooleanInput, Link, required, SaveButton, Toolbar} from "react-admin";
import {Box, Typography} from "@material-ui/core";
import {PropsWithSelectedUser} from "../../../../types";
import {Record, useTranslate} from "ra-core";
import {get as getByPath} from 'lodash'
import {TransferBox} from "./TransferBox";
import {StartLater} from "./StartLater";
import get from "lodash/get";
import {CampRecord} from "../../../RecordTypes";
import RegosBox from "./RegosBox";

const StatementField = (props: ApplicationProps & { resource: string, source: string }) => {
    const t = useTranslate();
    const {resource, source} = props;
    const statement = get(props, `record.camp.${source}`, get(props, `record.camp.parent.${source}`));
    const link = get(props, `record.camp.media.${source}.src`);
    const parentLink = get(props, `record.camp.parent.media.${source}.src`);
    if (statement && statement.includes(`${source}_link`)) {
        const matches = statement.match(new RegExp(`^(.*?)\\{${source}_link\}(.*?)\{\/${source}_link\}(.*)$`)) || ['', '', '', ''];
        if (link || parentLink) {
            //@ts-ignore
            return <Typography variant="body2">{matches[1]}<Link
                to={{pathname: link || parentLink}} target="_blank"><strong>{matches[2]}</strong></Link>{matches[3]}</Typography>;
        }
        return <Typography variant="body2">{matches[1]} <strong>{matches[2]}</strong> {matches[3]}</Typography>;
    }
    return <Typography
        variant="body2">{statement || t(`resources.${resource}.default_${source}_description`)} </Typography>;
}


type ApplicationProps = {
    campName: string, record: Record & {
        camp: CampRecord
    }
} & PropsWithSelectedUser;

export function Application(props: ApplicationProps) {
    //@ts-ignore
    const {
        //@ts-ignore
        setSelectedUser, selectedUser, //@ts-ignore
        formProps, record
    } = props;
    const {camp} = record;
    const applications = getByPath(camp, 'props.record.camp.applications', []);
    const alreadyApplied = applications.map(({id}: any) => {
        return id
    }).includes(selectedUser.id);
    // @ts-ignore
    const application = applications.filter(({id}) => {
        return id == selectedUser.id
    }).shift()?.pivot;


    // @ts-ignore


    return <>
        <PersonalData {...props} record={props.record} saveWrapper={(data: any) => {
            setSelectedUser(data);
        }}>
        <TransferBox record={application} camp={camp}/>
        <StartLater record={application} camp={camp}/>
        <RegosBox record={application} camp={camp}/>
        </PersonalData>
        {/*<CampData campName={campName}/>*/}
        <Box p="1em">
            <Typography variant="h6">Nyilatkozatok</Typography>
            <StatementField {...props} source="camp_regulations" resource="camps"/>
            <BooleanInput label="A tábori szabályzatot elfogadom" source="agree_camp_regulations"
                          validate={required()}/>
            <StatementField {...props} source="moral_statement" resource="camps"/>
            <BooleanInput label="Az fent leírtakat megerősítem " source="agree_moral_statement" validate={required()}/>
        </Box>

        <Toolbar>
            <Box display="flex" justifyContent="space-between"
                 width="100%">
                <SaveButton
                    label={alreadyApplied ? 'ra.action.save' : 'resources.camps.apply'}
                    submitOnEnter={false}
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
            </Box>
        </Toolbar>
    </>;
}
