import React, { CSSProperties, FC, PropsWithChildren, useCallback, useEffect, useState, } from 'react';
import { useAuthState, useDataProvider, useVersion } from 'react-admin';
import { Theme, useMediaQuery } from '@material-ui/core';
import YourCampApplications from "./YourCampApplications";
import { CampType } from "../pages/camps/CampType";
import GoogleDriveVideoField from './GoogleDriveVideo';
// import MonthlyRevenue from './MonthlyRevenue';
// import NbNewOrders from './NbNewOrders';
// import PendingOrders from './PendingOrders';
// import PendingReviews from './PendingReviews';
// import NewCustomers from './NewCustomers';
// import OrderChart from './OrderChart';

// import { Customer, Order, Review } from '../types';

interface OrderStats {
    revenue: number;
    nbNewOrders: number;
    // pendingOrders: Order[];
}

interface CustomerData {
    // [key: string]: Customer;
}

interface State {
    nbNewOrders?: number;
    nbPendingReviews?: number;
    // pendingOrders?: Order[];
    pendingOrdersCustomers?: CustomerData;
    // pendingReviews?: Review[];
    pendingReviewsCustomers?: CustomerData;
    // recentOrders?: Order[];
    revenue?: string;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

interface State {
    recentCamps?: Array<CampType>;
}

const Dashboard: FC<PropsWithChildren<{}>> = (props: PropsWithChildren<{}>) => {
    const [state, setState] = useState<State>({});
    const version = useVersion();

    const { authenticated, loaded } = useAuthState();

    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const fetchAssingnedCamps = useCallback(async () => {
        if (authenticated) {
            const { data: recentCamps } = await dataProvider.getList<CampType>(
                'camps/recentCamps',
                {
                    filter: {},
                    sort: { field: 'start_at', order: 'DESC' },
                    pagination: { page: 1, perPage: 5 },
                }
            );
            setState(state => ({
                ...state,
                recentCamps
            }));
        }
    }, [dataProvider, state, authenticated]);


    useEffect(() => {
        loaded && authenticated && fetchAssingnedCamps();
    }, [version, loaded, authenticated]);
    const {
        recentCamps
    } = state;
    if (!authenticated) {
        return null;
    }

    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                {/*<Welcome/>*/}
                {/*<VerticalSpacer/>*/}
                <YourCampApplications recentCamps={recentCamps} />
                <GoogleDriveVideoField id="Jelentkezofelulet2" label="Videó segítség a regisztráláshoz és a jelentkezéshez" />

                <VerticalSpacer />
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            {/*<div style={styles.singleCol}>*/}
            {/*    <Welcome/>*/}
            {/*</div>*/}
            <div style={styles.flex}>
            <YourCampApplications recentCamps={recentCamps} />
            </div>
            <div style={styles.singleCol}>
                <GoogleDriveVideoField id="Jelentkezofelulet2" label="Videó segítség a regisztráláshoz és a jelentkezéshez" />
            </div>
            <div style={styles.singleCol}>
            </div>
        </div>
    ) : (
        <>
            {/*<Welcome/>*/}
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <YourCampApplications recentCamps={recentCamps} />
                    </div>
                    <div style={styles.singleCol}>
                    <GoogleDriveVideoField id="Jelentkezofelulet2" label="Videó segítség a regisztráláshoz és a jelentkezéshez" />
                    </div>
                    <div style={styles.singleCol}>
                    </div>
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        <Spacer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
