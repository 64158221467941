import React, {FC} from "react";
import {useTranslate} from "ra-core";
import {Typography} from "@material-ui/core";
import {FileField} from "react-admin";
import get from "lodash/get";
import {DocumentProps, DocumentsFieldProps} from "../../types";

const Document = ({file, type, campRef, campName}: DocumentProps) => {
    const t = useTranslate();
//    console.log({file, type})
    return file && <li>
        <FileField download={true} target="_blank" source="src" record={file}
                   title={t(`resources.media.${type}.title`, {
                       ...file,
                       campRef,
                       campName,
                       type: 'PDF'
                   })}/>
    </li> || null;
}

export const DocumentsField: FC<DocumentsFieldProps> = (props) => {

    const {
        record, record: {
            media: {
                invitation,
                general_information: generalInformation,
                camp_regulations: campRegulations,
                moral_statement: moralStatement,
                equipment_list: equipmentList,
                others
            },
            parent
        }
    } = props;


    const {
        invitation: parentInvitation = undefined,
        general_information: parentGeneralInformation = undefined,
        camp_regulations: parentCampRegulations = undefined,
        equipment_list: parentEquipmentList = undefined,
        moral_statement: parentMoralStatement,
    } = parent?.media || {};

    const campName = get(record, 'name');
    const campRef = get(record, 'ref');

    const hasAnyFile = !!(parentInvitation || moralStatement || parentMoralStatement || invitation || parentGeneralInformation || generalInformation || parentCampRegulations || campRegulations || parentEquipmentList || equipmentList || (others && others.length));

    if (hasAnyFile) {
        return <>
            <Typography>Letölthető anyagok, ismertetők, dokumentumok:</Typography>
            <ul style={{listStyle: "none", listStyleType: ""}}>
                <Document type="invitation" {...{file: invitation || parentInvitation, campRef, campName}}/>
                <Document type="general_information" {...{
                    file: generalInformation || parentGeneralInformation,
                    campRef,
                    campName
                }}/>
                <Document type="camp_regulations" {...{
                    file: campRegulations || parentCampRegulations,
                    campRef,
                    campName
                }}/>
                <Document type="moral_statement" {...{
                    file: moralStatement || parentMoralStatement,
                    campRef,
                    campName
                }}/>
                <Document type="equipment_list" {...{file: equipmentList || parentEquipmentList, campRef, campName}}/>
                {(others || []).map((file) => <Document type="others" {...{file, campRef, campName}}/>)}
            </ul>
        </>

    }

    return null;
}
