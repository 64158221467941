import {CampList} from "./CampList";
import {CampCreate} from "./CampCreate";
import {CampEdit} from "./CampEdit";
import {CampShow} from "./CampShow";
import {CampIcon} from "./CampIcon";

export * from "./CampList";
export * from "./CampEdit";
export * from "./CampShow";
export * from "./CampType";
export * from "./CampCreate";

export {CampSubList} from "./CampSubList";
export type { CampSubListProps } from "./CampSubList";

const resource = {
    list: CampList,
    create: CampCreate,
    show: CampShow,
    edit: CampEdit,
    icon: CampIcon
};

export default resource;
export {CampTransferOptionEdit} from "./components/CampTransferOptionEdit";
export {CampStartAtOptionEdit} from "./components/CampStartAtOptionEdit";
export {CampAddressEdit} from "./components/CampAddressEdit";
export {CampBaseEdit} from "./components/CampBaseEdit";