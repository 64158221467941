import {Record} from "ra-core";
import {Typography} from "@material-ui/core";
import {AutocompleteInput, ReferenceInput, required, TextInput} from "react-admin";
import * as React from "react";

export function CampAddressEdit(props: { record?: Record, fullWidth?: boolean }) {
    return <>
        <Typography variant="h6">Helyszín</Typography>
        <TextInput source="address.street" validate={[required()]} {...props}/>
        <TextInput source="address.street_extra" {...props}/>
        <TextInput source="address.city" validate={[required()]} {...props}/>
        <TextInput source="address.state" {...props}/>
        <TextInput source="address.post_code" validate={[required()]} {...props}/>
        <ReferenceInput source="address.country_id" reference="countries"
                        validate={[required()]} {...props}><AutocompleteInput
            optionText="name"/></ReferenceInput>
        <TextInput source="address.note" {...props}/>
    </>;
}