import {Alert, AlertTitle} from "@material-ui/lab";

const MaintenancePage = () => (
    <div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <div className="MuiPaper-root MuiCard-root MuiPaper-elevation1 MuiPaper-rounded">
            <div className="MuiCardActions-root MuiCardActions-spacing">
                <Alert severity="warning">
                    <AlertTitle>Karbantartás</AlertTitle>
                    <p>Az oldal karbantartás alatt áll, kérlek <strong>látogass vissza</strong> kicsit később!</p>
                    <p>Előreláthatólag <strong>2021.07.11. 15:30</strong>-ig eltart a folyamat.</p>
                </Alert>
            </div>
        </div>
    </div>
);
export default MaintenancePage;
