import {FormWithRedirect, useGetIdentity} from "react-admin";
import {Box} from "@material-ui/core";
import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useForm} from "react-final-form";

const PickerForm = (props: { onChange: (values: any) => void, record: any, selectedUser: any }) => {
    const {loaded, identity} = useGetIdentity();
    if (!loaded) {
        return <div>&nbsp;</div>
    }
    return (<Box p="1em">
            <Box display="flex">
                <FormControl variant="filled" fullWidth={true}>
                    <InputLabel id="demo-simple-select-filled-label">Gyermek neve</InputLabel>
                    <Select
                        value={props.selectedUser.id}
                        onChange={props.onChange}
                    >
                        {Object.values(props.record).map((record: any) =>
                            <MenuItem
                                key={`PickerForm-${record.id}`} value={record.id}>{record.last_name} {record.first_name}{record.id == identity?.id && ' (Saját magad)'}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>
        </Box>);
}

export const ScoutChildPicker = (props: any) => {
    const {selectedUser, setSelectedUser} = props;
    const {loaded, identity} = useGetIdentity();
    const form = useForm();

    if (!props.scoutChildren || !loaded || !selectedUser) {
        return null;
    }

    const record = {...props.scoutChildren, [identity?.id || 0]: identity};
    return (
        <FormWithRedirect
            {...props}
            record={record}
            resource="users/apply"
            initialValues={{id: selectedUser.id}}
            fullWidth={true}
            render={(formProps: any) => {
                return (
                    <PickerForm selectedUser={selectedUser}
                                record={record} onChange={(values: any) => {
                        const newChildren = props.scoutChildren[values.target.value];
                        if (newChildren) {
                            setSelectedUser(newChildren);
                        } else {
                            setSelectedUser(identity)
                        }
                    }}/>
                )
            }}/>
    )
}