import get from "lodash/get";
import {useTranslate, useUpdate} from "ra-core";
import {Button, useGetIdentity, useRefresh} from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import React from "react";
import {usePermission} from "../providers/permissionProvider";

export const AcceptButton = (props: any) => {
    const {
        record,
        id,
        resource,
        source,
        extraParams = {},
        stateMachine,
        status: currentStatus
    } = props;

    const {hasPermission} = usePermission();
    const {identity, loading: identityLoading} = useGetIdentity();
    const refresh = useRefresh();

    const diff = {};
    const status = currentStatus ? (currentStatus.toString() === currentStatus ? currentStatus : currentStatus(props)) : get(record, source);
    const extraParamValues = {};
    const t = useTranslate();
    const nextStatus = stateMachine.next(status, record, hasPermission, identity);

    Object.keys(extraParams).forEach((paramKey) => {
        // @ts-ignore
        extraParamValues[paramKey] = get(record, extraParams[paramKey]);
    });
    const [update, {
        loading,
        error
    }] = useUpdate(resource, (id || record.id), {...extraParamValues, status: nextStatus});


    const label = nextStatus ? t('status.action.applicationAccept') : t('status.' + props.status);


    if (error) {
        return <p>ERROR</p>;
    }
    return <Button
        variant='contained'
        type='button'
        onClick={(e) => {
            update(e);
            //@TODO HACK :((
            setTimeout(() => refresh(), 1000);
        }}
        color={loading ? "default" : "primary"}
        label={label}
        disabled={loading || !nextStatus}

    >
        {props.acceptLoading ? (
            <CircularProgress
                size={18}
                thickness={2}
            />
        ) : (
            <VerifiedUserIcon/>
        )}

    </Button>;
};