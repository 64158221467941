// import {UserList} from "./UserList";
// import {UserEdit} from "./UserEdit";
// import {UserCreate} from "./UserCreate";
// import {UserShow} from "./UserShow";
import {ChildList} from "./ChildrenList";
import {ChildEdit} from "./ChildEdit";
import {ChildCreate} from "./ChildCreate";
import {CampIcon} from "../../pages/camps/CampIcon";
import {ChildrenIcon} from "./ChildrenIcon";
//export {ProfileEdit} from "./ProfileEdit";

// export * from "./UserList";
// export * from "./UserEdit";
// export * from "./UserCreate";
const resource = {
    list: ChildList,
    //show: ChildShow,
    edit: ChildEdit,
    //edit: EditGuesser,
    create: ChildCreate,
    icon:ChildrenIcon
};

export default resource;
