import {stringify} from 'query-string';
import {DataProvider, fetchUtils} from 'ra-core';
// import {cacheDataProviderProxy} from 'react-admin';
import addUploadFeature from "./addUploadFeature";
import {addUserProfileOverrides} from "./addProfileOverrides";
import {HttpError, CreateParams} from "react-admin";
import { debug } from 'console';

interface MetaData {
    "current_page": number,
    "from": number,
    "last_page": number,
    "path": string,
    "per_page": number,
    "to": number,
    "total": number
}

const getTotal = (headers: any, data: { count?: number, meta?: MetaData }) => {
    if (data.meta && data.meta.total !== undefined) {
        return data.meta.total;
    }
    if (data.count && data.count !== undefined) {
        return data.count;
    }
    return parseInt((headers
        .get('content-range') || '0/0')
        .split('/')
        .pop(), 10)
};

interface DataProviderOptions extends RequestInit{
    user?: {
        authenticated?: boolean;
        token?: string;
    };
}




/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
const dataProvider = (apiUrl: string, httpClient = fetchUtils.fetchJson): DataProvider => ({

    downloadPdf: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify(params.filter),
        };
        const id = params.id;
        return fetchFile(`${apiUrl}/${resource}/pdf/${id}?${stringify(query)}`);
    },
    getData: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify(params.filter),
        };

        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`).then(({json}) => ({
            data: json.data,
        }))
    },
    GET_TREE_ROOT_NODES: (resource: string, params: any) => {
        const query = {};
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({
                                         headers,
                                         json
                                     }) => {
            return {
                data: json.data, // @ts-ignore
                total: getTotal(headers, json),
            };
        });
    },
    MOVE_NODE: (resource: string, params: any) => {

    },
    GET_TREE_CHILDREN_NODES: (resource: string, params: any) => {
        //filter: {"id":6}
        //filter: {"id":[3]}
        params.id = [params.id];
        const query = {
            filter: JSON.stringify(params),

        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({
                                         headers,
                                         json
                                     }) => {
            return {
                data: json.data, // @ts-ignore
                total: getTotal(headers, json),
            };
        });
    },
    getList: (resource, params) => {
        const {
            page,
            perPage
        } = params.pagination;
        const {
            field,
            order
        } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            page,
            perPage,
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({
                                         headers,
                                         json
                                     }) => {
            // if (!headers.has('content-range')) {
            //     throw new Error(
            //         'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
            //     );
            // }
            return {
                data: json.data, // @ts-ignore
                total: getTotal(headers, json),
            };
        });
    },
    getOne: (resource, params) => {
        const query = {};

        Object.keys(params || {}).forEach((key: any) => {
            // @ts-ignore
            query[key] = JSON.stringify(params[key]);
        })
        // @ts-ignore
        return httpClient(`${apiUrl}/${resource}${params && (params.id || !isNaN(params.id)) ? `/${params.id}` : ''}?${stringify(query)}`).then(({json}) => ({
            data: json.data,
        }))
    },
    getOneCustom: (resource: string, params?: object) => {
        const query = {};

        Object.keys(params || {}).forEach((key: any) => {
            // @ts-ignore
            query[key] = JSON.stringify(params[key]);
        })
        // @ts-ignore
        return httpClient(`${apiUrl}/${resource}${params && (params.id || !isNaN(params.id)) ? `/${params.id}` : ''}?${stringify(query)}`).then(({json}) => ({
            data: json.data,
        }))
    },
    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({
                id: params.ids.map(function (item: any) {
                    return item instanceof Object ? item.id : item;
                })
            }),
            all: true
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({
                                         headers,
                                         json
                                     }) => {
            return {
                data: json.data, // @ts-ignore
                total: getTotal(headers, json)
            }
        });
    },
    getManyReference: (resource, params) => {

        const {
            page,
            perPage
        } = params.pagination;
        const {
            field,
            order
        } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({
                                         headers,
                                         json
                                     }) => {
            // if (!headers.has('content-range')) {
            //     throw new Error(
            //         'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
            //     );
            // }
            return {
                data: json.data, // @ts-ignore
                total: getTotal(headers, json),
            };
        });
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json: data}) => {
            return ({data});
        })
    },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) => Promise.all(params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
    }))).then(responses => ({data: responses.map(({json}) => json.id)})),

    create: (resource, params) => {
        const hasFile = (params.data.type && params.data[params.data.type] instanceof File);
        const headers = new Headers({});
        if (hasFile) {
            headers.set('Content-Type', 'multipart/form-data')
        }

        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            headers,
            body: jsonToFormData(params.data),
        }).then(({json}) => ({
            data: {...json},
        }));
    },
    post: (resource: string, params: CreateParams) => {
        const hasFile = (params.data.type && params.data[params.data.type] instanceof File);
        const headers = new Headers({});
        if (hasFile) {
            headers.set('Content-Type', 'multipart/form-data')
        }

        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            headers,
            body: jsonToFormData(params.data),
        }).then(({json}) => ({
            data: {...json},
        }));
    },

    delete: (resource, params) => httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'DELETE',
        body: jsonToFormData(params),
    }).then(({json}) => (json)),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) => Promise.all(params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
        method: 'DELETE',
    }))).then(responses => ({data: responses.map(({json}) => '')})),
});

// @ts-ignore
export const httpClient = (url: any, options: { headers?: Headers, user?: {} } = {}) => {
    if (!options.headers) {
        options.headers = new Headers({});
    }
    options.headers.set('Accept', 'application/json+laravel');
    //options.headers.set('mode', 'cors');
    const token = localStorage.getItem('token');
    if (token) {
        options.user = {
            token,
            authenticated: true
        };
    }
    // add your own headers here
    return fetchUtils.fetchJson(url, options);
};


const fetchFile = (url, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({});
    }
    options.headers.set('Accept', 'application/pdf');
    const token = localStorage.getItem('token');
    if (token) {
        options.user = {
            token,
            authenticated: true
        };
    }
    const requestHeaders = fetchUtils.createHeadersFromOptions(options);

    // add your own headers here
    return fetch(url, { ...options, headers: requestHeaders })
    .then(response => {
        if (response.status < 200 || response.status >= 300) {
            return Promise.reject(
                new HttpError(response.statusText, response.status)
            );
        }
        return Promise.resolve({data: response});
    });
}

// @ts-ignore
const baseProvider = dataProvider(process.env["REACT_APP_API_URL"] || '', httpClient);
const uploadCapableDataProvider = addUploadFeature(baseProvider);
const profileProvider = addUserProfileOverrides(uploadCapableDataProvider);
const sometimesFailsDataProvider = new Proxy(profileProvider, {
    get: (target, name) => (resource: string, params: { data: { title: string; }; }) => {
        // add rejection by type or resource here for tests, e.g.
        // if (name === 'delete' && resource === 'posts') {
        //     return Promise.reject(new Error('deletion error'));
        // }
        if (resource === 'posts' && params.data && params.data.title === 'f00bar') {
            return Promise.reject(new Error('this title cannot be used'));
        }
        if (profileProvider[name]) {
            return profileProvider[name](resource, params);
        } else {
            console.error(`is missing from dataProvider`, name.toString());
        }
    },
});
const delayedDataProvider = new Proxy(sometimesFailsDataProvider, {
    get: (target, name) => (resource: string, params: { data: { title: string; }; }) => new Promise(resolve => setTimeout(() => resolve(sometimesFailsDataProvider[name](resource, params)), 300)),
});

function buildFormData(formData: FormData, data: { [x: string]: any; } | null, parentKey: string | undefined) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        // @ts-ignore
        formData.append(parentKey, value);
    }
}

function jsonToFormData(data: { [x: string]: any; } | null) {
    const formData = new FormData();

    // @ts-ignore
    buildFormData(formData, data);

    return formData;
}

export default delayedDataProvider;
//export default cacheDataProviderProxy(delayedDataProvider);
