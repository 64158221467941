import {Box, Paper, Typography} from "@material-ui/core";
import {ArrayInput, BooleanInput, FormDataConsumer, SimpleFormIterator, TextInput} from "react-admin";
import * as React from "react";

export function CampTransferOptionEdit() {
    return <Paper><Box p="1em">
        <Typography variant="h6">A transport lehetőségek</Typography>
        <BooleanInput source="options.transfer_available"
                      label="A transport megszervezésre kerül valamely állomás(ok)ról?"/>
        <FormDataConsumer>
            {({
                  formData,
                  ...rest
              }) => formData.options?.transfer_available &&
                <ArrayInput source="options.transfers" label="Állomás">
                    <SimpleFormIterator>
                        <TextInput source="station"/>
                    </SimpleFormIterator>
                </ArrayInput>
            }
        </FormDataConsumer>
    </Box></Paper>
}