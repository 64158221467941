import React, {useState} from "react";
import {Typography} from "@material-ui/core";
import {
  AutocompleteInput,
  BooleanInput,
  CreateContextProvider,
  email,
  Labeled,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useCreateController,
  useDataProvider,
  useGetIdentity,
  useNotify,
  useRedirect
} from "react-admin";
import {FORM_ERROR} from 'final-form';
import Alert from '@material-ui/lab/Alert';
import {FormDataConsumer, useTranslate} from "ra-core";
import TextInput from "../../components/formInputs/TextInput";
import {Button} from "@material-ui/core";
import UndoIcon from "@material-ui/icons/Undo";
import {RankInput} from "../../components/RankInput";
import {makeStyles} from "@material-ui/core/styles";
import GoogleDriveVideoField from "../../components/GoogleDriveVideo";

const useStyles = makeStyles(
  () => ({
    backButton: {
      position: 'absolute',
      right: '20px'
    }
  }),
  {name: 'RaLogin'}
);

export const RegistrationEdit = ({
                                   staticContext,
                                   department,
                                   ...props
                                 }: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [, setSaving] = useState<boolean>();
  const [registered, setRegistered] = useState<string>();
  const {loaded} = useGetIdentity();
  const t = useTranslate();
  const redirect = useRedirect();
  const departmentId = department?.id;
  const handleSave = async (values: any) => {
    setSaving(true);
    try {
      await dataProvider.create('auth/register',
        {data: values},
        {
          onSuccess: ({data: {id}}: any) => {
            setSaving(false);
            notify("Your profile has been updated", "info", {
              _: "Your profile has been updated"
            });
            setRegistered(id);
            redirect('/login');
          },
          onFailure: ({body: {message}}: any) => {
            setSaving(false);
            notify(
              "A technical error occured while updating your profile. Please try later.",
              "warning",
              {
                _:
                message
              }
            );
          }
        }
      );
    } catch ({
      body: {
        message,
        errors
      },
      status
    }) {
      if (status === 422) {
        if (errors) {
          const result = {
            [FORM_ERROR]: t('Please check your data'),
            ...errors
          }
          // @ts-ignore
          if (errors.exists) {
            // @ts-ignore
            result[FORM_ERROR] = errors.exists;
          }
          // @ts-ignore
          if (errors.error) {
            // @ts-ignore
            result[FORM_ERROR] = errors.error;
          }
          Object.keys(errors).forEach(key => {
            // @ts-ignore
            const error = errors[key];
            if (error.constructor === String) {
              result[key] = t(error.toString());
            } else if (error.constructor === Array) {
              result[key].forEach((value: string, idx: number) => {
                result[key][idx] = t(value.replace(/\.$/, ''));
              });
            }
          });

          return result;
        }
        return {[FORM_ERROR]: t('Unknown error')};
      }
    }
  };
  const controllerProps = useCreateController({
    ...props,
    basePath: `/register/${departmentId || ''}`,
    resource: 'auth/register',
    save: handleSave
  });

  interface RegistrationErrors {
    password_confirmation?: string
    group_id?: string
    rank?: string
  }

  const validateUserCreation = (values: any): RegistrationErrors => {
    const errors: RegistrationErrors = {};
    if (values.password && values.password_confirmation && values.password != values.password_confirmation) {
      errors.password_confirmation = 'ra.validation.password_confirmation_not_matching';
    }
    if (values.isScout && !values.isDiaspora && !values.group_id) {
      errors.group_id = 'ra.validation.required';
    }
    if (values.isScout && !values.rank) {
      errors.rank = 'ra.validation.required';
    }
    return errors;
  };


  if (!loaded) {
    return null;
  }

  if (registered) {
    return (
      <Alert severity="success">Your user is registered, and we sena an verification email to you! please check
        your mailbox to be able to continue</Alert>);
  } else {
    const fields = 'resources.users.fields';
    return (
      <CreateContextProvider value={controllerProps}>
        <SimpleForm save={handleSave} toolbar={<PostCreateToolbar/>} autocomplete="off"
                    validate={validateUserCreation} initialValues={{group_id: departmentId}}>
          <TextInput fullWidth={true} label={`${fields}.last_name`} source="last_name" validate={required()}/>
          <TextInput fullWidth={true} label={`${fields}.first_name`} source="first_name"
                     validate={required()}/>
          <TextInput fullWidth={true} label={`${fields}.email`} source="email" type="email"
                     validate={[required(), email()]}/>
          <TextInput fullWidth={true} label={`${fields}.password`} type='password' source="password"
                     validate={required()}/>
          <TextInput fullWidth={true} label={`${fields}.password_confirmation`} type='password'
                     source="password_confirmation" validate={required()}/>
          <BooleanInput source="isParent" label='resources.users.fields.isParent'/>
          {!departmentId && (<>
              <BooleanInput source="isScout" label='resources.users.fields.isScout'/>
              <FormDataConsumer>
                {({formData}) => {
                  if (formData.isScout) {
                    return (<>
                      <BooleanInput source="isDiaspora"
                                    label="resources.users.fields.isDiaspora"/>

                      <RankInput row={false} source="rank" label="resources.users.fields.rank"/>
                    </>)
                  }
                  return null;
                }
                }
              </FormDataConsumer></>

          )}
          <FormDataConsumer>
            {({formData}) => {
              if (departmentId) {
                return <TextInput type="hidden" source="group_id" value={departmentId}
                                  style={{display: 'none'}}/>
              } else if ((formData.isScout || formData.isParent) && !formData.isDiaspora) {
                return (<><Typography  variant="caption" >Kezd el beírni a csapat nevét vagy a szövettség nevét melyhez tartozol.</Typography><br/>
                  <ReferenceInput label={`${fields}.group`} source="group_id" reference="groups"
                                  sort={{
                                    field: 'title',
                                    order: 'ASC'
                                  }}
                    // validate={required()}
                  >
                     <AutocompleteInput optionText="title" />

                  </ReferenceInput>
                </>)
              }
              return null;
            }
            }
          </FormDataConsumer>

          <GoogleDriveVideoField id="Jelentkezofelulet2" label="Videó segítség a regisztráláshoz és a jelentkezéshez"/>
        </SimpleForm>
      </CreateContextProvider>
    );
  }
};

const PostCreateToolbar = (props: any) => {
  const classes = useStyles();
  const t = useTranslate();
  const redirect = useRedirect();
  return (
    <Toolbar {...props} >
      <SaveButton submitOnEnter={true} label="Regisztáció"/>
      <Button
        variant="text"
        type="button"
        color="primary"
        className={classes.backButton}
        onClick={() => {
          redirect('/login')
        }}
      >
        <UndoIcon/>
        {t('backButton')}
      </Button>

    </Toolbar>
  )
};
