// @ts-ignore

import * as React from "react";
import {ChipField, Datagrid, Filter, List, ReferenceArrayField, SearchInput, SingleFieldList} from 'react-admin';
import {StatusField} from "../../components/StatusField";
import {RankField} from "../../components/RankField";
import FullNameField from "../../components/FullNameField";
import {usePermission} from "../../providers/permissionProvider";
import {Permissions} from "../../components/Permissions";

const PostFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
        {/*<StatusFilter source="status" alwaysOn/>*/}
    </Filter>
);
export const MemberList = (props: any) => {
    const {hasPermission} =  usePermission();
    if (!hasPermission(Permissions.MANAGE_MEMBERS)) {
        return null;
    }

    return (
        <List {...props} bulkActionButtons={false} exporter={false} filters={<PostFilter {...props}/>}>
            <Datagrid rowClick="show">
                <FullNameField size="35" source="fullName" label="resources.members.fields.fullName" sortable={true}/>
                <ReferenceArrayField source="department_ids" reference="departments" label='Csapat'>
                    <SingleFieldList linkType={hasPermission(Permissions.MANAGE_DEPARTMENTS)?'show':false}>
                        <ChipField source="title"/>
                    </SingleFieldList>
                </ReferenceArrayField>
                <RankField source="rank"/>
                <StatusField source="status" parser={(currentSourceRecord, resource)=>currentSourceRecord.departments.map((d)=>d.status).shift()} sortable={false}/>
            </Datagrid>
        </List>
    )
};
