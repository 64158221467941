import {MedicalInformationType as MIType} from "../../types";
import get from "lodash/get";
import {useTranslate} from "ra-core";
import {Alert} from "@material-ui/lab";
import {ArrayField, Datagrid, DateField, Labeled, TextField} from "react-admin";
import React from "react";

export const MedicalInformationShow = (props: { type: MIType, record?: any }) => {

    const {type} = props;
    const data = get(props.record.medical_information, `${type.name}.0`);
    const t = useTranslate();

    if (data && data?.name == null && data?.selected) {
        return (<div className={`ra-field ra-field-medical_information.${type}.name`}>
            <div className="MuiFormControl-root MuiFormControl-marginDense MuiFormControl-fullWidth"><label
                className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-59 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
                data-shrink="true"><span>{t(`resources.medical_information.type.${type}`)}</span></label>
                <Alert severity="success">{t(`resources.medical_information.${type}.fields.hasNot`)}</Alert>
            </div>
        </div>);
    }
    if (data === undefined) {
        return (<div className={`ra-field ra-field-medical_information.${type}.name`}>
            <div className="MuiFormControl-root MuiFormControl-marginDense  MuiFormControl-fullWidth"><label
                className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-59 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
                data-shrink="true"><span>{t(`resources.medical_information.type.${type}`)}</span></label>
                <div className="RaLabeled-value-60"><span
                    className="MuiTypography-root MuiTypography-body2"><Alert severity="error">Nincs megadva</Alert></span>
                </div>
            </div>
        </div>);
    }
    if (type == MIType.VACCINATION) {
        return (<Labeled label={t(`resources.medical_information.type.${type}`)}>
            <ArrayField {...props} source={`medical_information.${type}`}
                        resource={type.resource}
                        label={t(`resources.medical_information.type.${type}`)}>
                <Datagrid>
                    <TextField source="name"
                               label={t(`resources.medical_information.${type}.fields.name`)}/>
                    <DateField source="valid_until"
                               label={t(`resources.medical_information.${type}.fields.valid_until`)}/>
                </Datagrid>
            </ArrayField>
        </Labeled>);
    }
    return (<Labeled label={t(`resources.medical_information.type.${type}`)}>
        <ArrayField {...props} source={`medical_information.${type}`}
                    resource={type.resource}
                    label={t(`resources.medical_information.type.${type}`)}>
            <Datagrid>
                <TextField source="name"
                           label={t(`resources.medical_information.${type}.fields.name`)}/>
                <DateField source="valid_until"
                           label={t(`resources.medical_information.${type}.fields.description`)}/>
            </Datagrid>
        </ArrayField>
    </Labeled>);
}