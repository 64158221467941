import {AddressType} from "./AddressType";
import React, {FC} from "react";
import {Card, CardContent, Typography} from "@material-ui/core";

interface AddressCardProps {
    address: AddressType | null;
}

export const AddressCard: FC<AddressCardProps> = ({address}) => {
    if (address) {
        return <Card>
            <CardContent>
                <Typography variant="h4">Helyszín</Typography>
                <Typography>{address?.street}</Typography>
                <Typography>
                    {address?.city}, {address?.stateAbbr} {address?.post_code}
                </Typography>
            </CardContent>
        </Card>
    }

    return null;
}
