import {MemberList} from "./MemberList";
import {MemberShow} from "./MemberShow";
// import {DepartmentList} from "./DepartmentList";
// import {DepartmentEdit} from "./DepartmentEdit";
// import {DepartmentShow} from "./DepartmentShow";
// import {DepartmentCreate} from "./DepartmentCreate";
// import {ListTreeDepartments} from "./ListTreeDepartments";

const resource = {
    list: MemberList,
    show: MemberShow
};

export default resource;
