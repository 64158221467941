import * as React from "react";
import {AutocompleteInput, Create, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin';
import { usePermission } from "../../providers/permissionProvider";
import { Permissions } from "../../components/Permissions";

export const DepartmentCreate = (props: any) => {
    const {hasPermission} =  usePermission();
    if (!hasPermission('manage.departments')) {
        // redirect to show page
        props.history.push("/forbidden");
        return null;
    }
    return (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title"/>
            <ReferenceInput source="parent_id" reference="departments"><AutocompleteInput
                optionText="title"/></ReferenceInput>
        </SimpleForm>
    </Create>
)};
