//import { Video } from '@mui/icons-material';
import Video from "@material-ui/icons/Movie";
import {Box, Typography} from "@material-ui/core";
import { useState, useEffect } from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';



export const GoogleDriveVideoMenuItem = ({ id, label }) => {
  const videoLink = `https://drive.google.com/file/d/${id}/?autoplay=0`;


  return (<MenuItemLink
    key={`GoogleDriveVideoMenuItem-${id}`}
    to="#"
    primaryText={label}
    leftIcon={<Video/>}
    about="_blank"
    onClick={() => window.open(videoLink, '_blank')}
    // dense={dense}
    // sidebarIsOpen={open}
/>);
};




const GoogleDriveVideoField = ({ id, label }) => {
  const translate = useTranslate();



//  const src = `https://drive.google.com/file/d/${id}/preview`;
  const src = `/videos/${id}.mp4`;

  if (!src) {
    return null;
  }

  const videoEl = (
    <video controls style={{ maxWidth: '100%' }}>
      <source src={src} type="video/mp4" />
      {translate('resources.videos.fields.error_message')}
    </video>
  );

  return (
    <Box>
        <Typography variant="subtitle2" gutterBottom>{label}</Typography>
      {videoEl}
    </Box>
  );
};



export default GoogleDriveVideoField;