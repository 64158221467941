import {Typography} from "@material-ui/core";
import React from "react";

export const AddressInformation = ({record}: any) => {
    if (!record?.address) return null;
    return (<><Typography variant="h6">Helyszín</Typography>
        <Typography itemProp="address" itemScope
                    itemType="https://schema.org/PostalAddress">
            <Typography component="span"
                        variant="body2"
                itemProp="streetAddress">{record.address.street}</Typography><br/>
            <Typography component="span"
                        variant="body2"
                itemProp="addressLocality">{record.address.city}</Typography>,
            <Typography component="span"
                        variant="body2"
                itemProp="addressRegion">{record.address.state}</Typography><br/>
            <Typography component="span"
                        variant="body2"
                itemProp="postalCode">{record.address.post_code}</Typography>&nbsp;<Typography component="span"
                        variant="body2"
                itemProp="addressCountry">{record.address.country}</Typography>
        </Typography><br/></>);
}