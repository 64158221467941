import {Box, List, ListItem, Paper, Typography} from "@material-ui/core";
import {BooleanInput, FormDataConsumer} from "react-admin";
import * as React from "react";

function RegosFieldsInfo() {
  return <>
    <Typography>Az alábbi kérdések kerülnek hozzáadásra:</Typography>
    <List>
      <ListItem>
        Milyen munkacsoporton szeretnél résztvenni? (Nem tudjuk a kívánt munkacsoporton való részvételt garantálni)?:<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- fafaragás<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- fafaragás<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- fazekasság<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- citeratanulás (kezdő + haladó, nem kötelező a saját hangszer)<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- hegedű-, brácsa- és bőgőoktatás (nem kezdőknek, csak saját hangszerrel)<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- nemezelés<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- talán furulyázás (kezdőknek is)<br/>
      </ListItem>
      <ListItem>Ha játszol hangszeren, milyen(ek)en?</ListItem>
      <ListItem>Tudsz hozni magadnak egész viseletet a táborba?</ListItem>
    </List>
  </>;
}

export function CampRegosOptionsEdit() {
  return <Paper><Box p="1em">
    <Typography variant="h6">Regős tábori lehetőségek</Typography>
    <BooleanInput source="options.regos.enabled"
                  label="Ez egy regőstábor?"/>
    <FormDataConsumer>
      {({formData}) => formData.options?.regos?.enabled && <RegosFieldsInfo/>}
    </FormDataConsumer>
  </Box></Paper>
}


/**
 { id: 'faragas', name: 'fafaragás' },
 { id: 'fazekassag', name: 'fazekasság' },
 { id: 'citera', name: 'citeratanulás (kezdő + haladó, nem kötelező a saját hangszer)' },
 { id: 'vonos', name: 'hegedű-, brácsa- és bőgőoktatás (nem kezdőknek, csak saját hangszerrel)' },
 { id: 'nemez', name: 'nemezelés' },
 { id: 'furulya', name: 'furulyázás (kezdőknek is)' },

 - fafaragás
 - fazekasság
 - citeratanulás (kezdő + haladó, nem kötelező a saját hangszer)
 - hegedű-, brácsa- és bőgőoktatás (nem kezdőknek, csak saját hangszerrel)
 - nemezelés
 - talán furulyázás (kezdőknek is)

 */
