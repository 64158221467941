import React, {useState} from "react";
import {Box, Paper, Typography} from '@material-ui/core';
import {EditableField, FieldEnum} from "./EditableField";
import CreateIcon from '@material-ui/icons/Create';
import IconButton from "@material-ui/core/IconButton";
import {sanitizeProps} from "../../../components/Helpers";

export const AvatarUploader = (props: any) => {
    const [isEdit, setEdit] = useState<boolean>(false);
    const formProps = sanitizeProps({isEdit, setEdit, ...props});
    return (
        <Paper>
            <Box margin="1em">
                <Typography variant="h6">Profil kép<IconButton onClick={() => {
                    setEdit(!isEdit)
                }}><CreateIcon/></IconButton>
                </Typography>
                <EditableField
                    fieldType={FieldEnum.FILE}
                    source="avatar" {...formProps}
                />
            </Box>
        </Paper>
    )
};
