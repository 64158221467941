import React from "react";
import {BooleanInput, DateInput, FormDataConsumer, Loading, required,} from "react-admin";
import {Box, Paper, Typography} from "@material-ui/core";
import {useListController, useTranslate} from "ra-core";
import TextInput from "../../../../components/formInputs/TextInput";
import {MedicalInformationType} from "../../../../types";
import get from "lodash/get";
import {useFormState} from 'react-final-form'
import {UserRecord} from "../../../RecordTypes";
import SimpleFormIterator from "../../../../components/form/SimpleFormIterator";
import ArrayInput from "../../../../components/form/ArrayInput";
import {Alert} from "@material-ui/lab";

// @ts-ignore
interface MedicalInformationProps {
    type: MedicalInformationType,
    record: any,
    saveWrapper?: any,
    selectedUser: UserRecord,
    prefix?: string
}

export const MedicalInformation = ({
                                       type,
                                       prefix,
                                       ...props
                                   }: MedicalInformationProps) => {
    const {selectedUser} = props;
    const t = useTranslate();
    const resource = type.resource;
    const formState = useFormState();
    const controllerProps = useListController({
        ...props,
        resource,
        filter: {
            user_id: selectedUser?.id,
            type: type.toString()
        }
    });

    if (controllerProps.loading) {
        return <Loading/>;
    }

    const basePath = `${prefix ? `${prefix}.` : ''}medical_information.${type}`;
    const recordData = get(formState.values, basePath);
    const hasOwnDataSet = recordData?.length && get(recordData, '0.selected') !== true;
    return (<Paper>
        <Box margin="1em">
            <Typography variant="body2">{t(`resources.medical_information.type.${type}`)}</Typography>
            {type === MedicalInformationType.VACCINATION &&
                <Alert severity="warning"><span dangerouslySetInnerHTML={{__html: t(`resources.medical_information.${MedicalInformationType.VACCINATION}.info`)}}/></Alert>}
            {!hasOwnDataSet?<BooleanInput label={`resources.medical_information.${type}.fields.hasNot`} source={`${basePath}.0.selected`} />:null}
            <FormDataConsumer>
                {({formData}) => {
                    return hasOwnDataSet ? (
                        <ArrayInput source={basePath}>
                            <SimpleFormIterator>
                                <TextInput source="name"
                                           label={`resources.medical_information.${type}.fields.name`}
                                           validate={required()}/>
                                {type.toString() == 'VACCINATION' ?
                                    <DateInput source="valid_until"
                                               label={`resources.medical_information.${type}.fields.valid_until`}/> :
                                    <TextInput source="description"
                                               label={`resources.medical_information.${type}.fields.description`}/>}
                            </SimpleFormIterator>
                        </ArrayInput>) : null;
                }
                }
            </FormDataConsumer>
        </Box>
    </Paper>);
}
