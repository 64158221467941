import React, {useCallback, useMemo, useState} from "react";
import {SaveContextProvider, SimpleForm, useDataProvider, useGetIdentity, useNotify} from "react-admin";
import {useProfile} from "../../providers/profileProvider";
import {PersonalData} from "./component/application/PersonalData";


export const ProfileEdit = ({staticContext, ...props}: any) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [saving, setSaving] = useState<boolean>();
    // @ts-ignore
    const {refreshProfile} = useProfile();
    const {loaded, identity} = useGetIdentity();

    const handleSave = useCallback(
        async (values) => {
            setSaving(true);

            dataProvider.updateUserProfile(
                {data: values},
                {
                    onSuccess: ({data}: any) => {
                        setSaving(false);
                        notify("Your profile has been updated", "info", {
                            _: "Your profile has been updated"
                        });
                        refreshProfile();
                    },
                    onFailure: () => {
                        setSaving(false);
                        notify(
                            "A technical error occured while updating your profile. Please try later.",
                            "warning",
                            {
                                _:
                                    "A technical error occured while updating your profile. Please try later."
                            }
                        );
                    }
                }
            );
        },
        [dataProvider, notify, refreshProfile]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            saving
        }),
        [saving, handleSave]
    );

    if (identity) {
        return (
            <SaveContextProvider value={saveContext}>
                <SimpleForm
                    save={handleSave}
                    record={identity ? {...identity} : undefined}
                    resource="users"
                    /*toolbar={<></>}*/>
                    <PersonalData
                        saveWrapper={handleSave}
                        record={identity ? {...identity} : undefined}
                        selectedUser={identity}
                        resource="users"
                        withoutForm
                    />
                </SimpleForm>
            </SaveContextProvider>
        );
    }
    return null;
};

