import React, {
    HtmlHTMLAttributes, ComponentType, createElement, ReactNode, useRef, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Card, Avatar, Theme} from '@material-ui/core';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import {StaticContext} from 'react-router';
import {useHistory} from 'react-router-dom';
import {useCheckAuth, TitleComponent, useTranslate} from 'ra-core';
import {defaultTheme, Notification} from "react-admin";
import {LoginForm} from "ra-ui-materialui";
import CardMedia from "@material-ui/core/CardMedia";
import get from "lodash/get";
import logo from "../../images/kmcssz-europa-logo.png";
import {RegistrationEdit} from "../../models/users/RegistrationEdit";


export interface LoginProps extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
    backgroundImage?: string;
    children?: ReactNode;
    classes?: object;
    className?: string;
    notification?: ComponentType;
    staticContext?: StaticContext;
    theme?: object;
    title?: TitleComponent;
}

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    },
    card: {
        minWidth: 300,
        maxWidth: 450,
        marginTop: '6em',
        width: '40%',
    },

    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        // @ts-ignore
        backgroundColor: theme.palette.secondary[500],
    },
    media: {
        //height: 71,
        margin: '1em',
        width: '95%'
    }
}), {name: 'RaLogin'});

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login: React.FunctionComponent<LoginProps> = props => {
    const {
        theme,
        title,
        classes: classesOverride,
        className,
        children,
        notification,
        staticContext,
        backgroundImage,
        ...rest
    } = props;
    const containerRef = useRef<HTMLDivElement>();
    const classes = useStyles(props);
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    let backgroundImageLoaded = false;
    const checkAuth = useCheckAuth();
    const history = useHistory();
    const t = useTranslate();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);

    const updateBackgroundImage = () => {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
        if (backgroundImage) {
            const img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };

    useEffect(() => {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
    });

    return (<ThemeProvider theme={muiTheme}>
            <div
                className={classnames(classes.main, className)}
                {...rest} // @ts-ignore
                ref={containerRef}
            >
                <Card className={classes.card}>
                                <CardMedia
                                    className={classes.media}
                                    image={logo}
                                    title={t("resources.pages.registration.title")}
                                    component="img"
                                />
                    {children}
                </Card>
                {notification ? createElement(notification) : null}
            </div>
        </ThemeProvider>);
};

Login.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

Login.defaultProps = {
    theme: defaultTheme,
    children: <LoginForm/>,
    notification: Notification,
};

export default Login;
