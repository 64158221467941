import {SimpleShowLayout, TextField} from "react-admin";
import {Typography} from "@material-ui/core";
import {AddressInformation} from "./AddressInformation";
import {CampCommander} from "../CampCommander";
import React from "react";

export const CampInformation = (props: any) => {
    const campName = props.record.parent ? `${props.record.parent.name} - ${props.record.name}` : props.record.name;
    return (

        <SimpleShowLayout {...props}>
            <Typography variant="h6">Tábor</Typography>
            <Typography variant="body2" style={{margin: 10}}>{campName}</Typography>
            <TextField source="start_at"/>
            <TextField source="finish_at"/>
            <AddressInformation/>
            <CampCommander linkTo={false} {...props.record.commander}/>
        </SimpleShowLayout>);
};