import {Box, Paper, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import CreateIcon from '@material-ui/icons/Create';
import {MedicalInformationType} from "../../../../types";
import {EditableField, FieldEnum} from "../EditableField";
import {Identifier, useGetOne} from "ra-core";
import {useFormState} from "react-final-form";
import IconButton from "@material-ui/core/IconButton";
import {required} from "react-admin";
import {isEmpty, sanitizeProps} from "../../../../components/Helpers";

export const Insurance = (props: any) => {
    const {selectedUser} = props;
    //const notify = useNotify();
    const {
        data,
        loaded,
        error,
        loading
    } = useGetOne(MedicalInformationType.INSURANCE.resource, selectedUser?.medical_information?.INSURANCE?.id as Identifier);


    const [isEdit, setEdit] = useState<boolean>(false);
    // const [
    //     create,
    //     {
    //         loading: createLoading,
    //         error: createError
    //     }
    // ] = useCreate(MedicalInformationType.INSURANCE.resource);
    // const [
    //     update,
    //     {
    //         loading: updateLoading,
    //         error: updateError
    //     }
    // ] = useUpdate(MedicalInformationType.INSURANCE.resource, selectedUser.medical_information.INSURANCE?.id as Identifier);
    const {errors, dirty, touched} = useFormState();
    useEffect(() => {
        if (!data || (data && !dirty && (isEmpty(data.description) || isEmpty(data.name)))) {
            setEdit(true);
        }
    }, [isEdit, touched, data])

    const record = {medical_information: {INSURANCE: data}};
    const formProps = sanitizeProps({...props, record,isEdit, setEdit });
    return (<InsuranceView basePath="/camps" {...formProps}
                           id={data?.id} /*save={onSave}*/ resource={MedicalInformationType.INSURANCE.resource}
    />)

};

// @ts-ignore
export const InsuranceView = (props) => {
    const {setEdit, isEdit} = props;
    const formProps = sanitizeProps(props);
    return (
        <Paper>
            <Box margin="1em">
                <Typography
                    variant="h6">Biztosítási adatok<IconButton onClick={() => {
                    setEdit(!isEdit)
                }}><CreateIcon/></IconButton></Typography>
                <>
                    <EditableField fieldType={FieldEnum.TEXT}
                                   label="resources.medical_information.INSURANCE.fields.name"
                                   source="medical_information.INSURANCE.name" {...formProps} validate={required()}/>
                    <EditableField fieldType={FieldEnum.TEXT}
                                   label="resources.medical_information.INSURANCE.fields.description"
                                   source="medical_information.INSURANCE.description" {...formProps}
                                   validate={required()}/>
                </>
            </Box>
        </Paper>
    )

}

// @ts-ignore
// export const InsuranceView = (props) => {
//     const {setEdit, isEdit, record, save, ...rest} = props;
//     return (
//         <Paper>
//             <Box margin="1em">
//                 <Typography
//                     variant="h6">Biztosítási adatok<IconButton onClick={() => {
//                     setEdit(!isEdit)
//                 }}><CreateIcon/></IconButton></Typography>
//                 <Form
//                     initialValues={record}
//                     onSubmit={save}
//                     {...rest}
//                     render={({handleSubmit, invalid, dirty, ...formProps}) => {
//                         if (!dirty && formProps.initialValues && (formProps.initialValues.description === null || formProps.initialValues.name === null)) {
//                             setEdit(true);
//                         }
//
//
//                         return (<>
//                                 <EditableField fieldType={FieldEnum.TEXT}
//                                                label="resources.medical_information.INSURANCE.fields.name"
//                                                source="name" {...props} validate={required()}/>
//                                 <EditableField fieldType={FieldEnum.TEXT}
//                                                label="resources.medical_information.INSURANCE.fields.description"
//                                                source="description" {...props} validate={required()}/>
//                                 {/*{showFrom && <SubmitIconButton label={''} onClick={handleSubmit}/>}*/}
//                                 {/*{showFrom && <CancelIconButton onClick={() => setEdit(false)} label=""/>}*/}
//                             </>
//                         );
//                     }}
//                 />
//             </Box>
//         </Paper>
//     )
//
// }