import React from "react";
import {EditableComponent} from "./EditableComponent";
import {EditableField, FieldEnum} from "../models/users/components/EditableField";
import {useTranslate} from "ra-core";

export const CampPermissions = (props: any) => {
    const {
        prefix,
        isEditable
    } = props;
    const t = useTranslate();
    const source = `${prefix ? `${prefix}.` : ''}permissions`
    if (isEditable) {
        return (<EditableComponent  {...props} label='Jogosultságok'>
            <EditableField fieldType={FieldEnum.CHECKBOX} source={source} row={false} choices={[{
                id: 'manage.camp.applicants',
                name: t('resources.applications.permissions.manage.camp.applicants')
            }, {
                id: 'manage.camps',
                name: t('resources.applications.permissions.manage.camps')
            },
            ]} {...props}/>
        </EditableComponent>)
    }
    return null;
};
