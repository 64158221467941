import * as React from "react";
import {Datagrid, List, NumberField, TextField} from 'react-admin';

export const CountryList = (props:any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="full_name"/>
            <NumberField source="calling_code"/>
            <TextField source="flag"/>
            <TextField source="currency_symbol"/>
            <TextField source="name"/>
            <TextField source="currency"/>
        </Datagrid>
    </List>
);