import * as React from 'react';
import {AppBar, MenuItemLink, UserMenu} from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import {useSelector} from "react-redux";
import {ReduxState} from "ra-core";
import {useProfile} from "../providers/profileProvider";
import SpyIcon from "./icons/SpyIcon";

const MyUserMenu = (props: any) => {
    const {
        classes: classesOverride,
        className,
        dense,
        hasDashboard,
        onMenuClick,
        logout,
        ...rest
    } = props;
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    // @ts-ignore
    const {profileVersion} = useProfile();
    return (<>
        {localStorage.hasOwnProperty('or') && <SpyIcon/>}
        <UserMenu key={`profile-${profileVersion}`} {...props}>
            <MenuItemLink
                to="/me"
                primaryText="Profilom"
                leftIcon={<SettingsIcon/>}
                onClick={onMenuClick} // close the menu on click
                dense={dense}
                sidebarIsOpen={open}/>
        </UserMenu>
    </>);
};

export const MyAppBar = (props: any) => <AppBar {...props} title="@user" userMenu={<MyUserMenu/>}></AppBar>;
