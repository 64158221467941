import {EditGuesser, ShowGuesser} from 'react-admin';
import {CountryList} from "./CountryList";
import {CountryIcon} from "./CountryIcon";

const resource = {
    list: CountryList,
    create: EditGuesser,
    show: ShowGuesser,
    edit: EditGuesser,
    icon: CountryIcon
};

export default resource;
