import {ChipField, Datagrid, downloadCSV, Filter, List, ReferenceArrayField, ReferenceField, SearchInput, SelectInput, SingleFieldList, translate, useGetList, FunctionField, useDataProvider, useListController, ExportButton, useTranslate} from 'react-admin';
import {StatusField, StatusFilter} from "../../components/StatusField";
import FullNameField from "../../components/FullNameField";
import {fieldPropTypes} from "ra-ui-materialui/lib/field/types";
// @ts-ignore
import jsonExport from 'jsonexport/dist';
import {usePermission} from "../../providers/permissionProvider";
import {Permissions} from "../../components/Permissions";
import { camelCase } from 'lodash';


const CampNameField = (record: any) => {
  return record.parent ? `${record.parent.name} - ${record.name}` : record.name
};

const csvExporter = (props)=>(data,fetchRelatedRecords,dataProvider,resource) => {
  const camp_id = props.filterValues.camp_id;

  dataProvider.getOne('camps', { id: camp_id }).then(({data: camp}) => {
    dataProvider.getOneCustom('applications/csv', { id: camp_id }).then((response: any) => {
      const {data} = response;
      const fields = Object.keys(data[0]);
      debugger;
      data.forEach((item: any) => {
        item.status = props.translate(`status.${item.status}`);
      });
  
      jsonExport(data, {
        //headers,
        rename: fields.map((field: string) => {
          console.debug(field);
          debugger;
          return field==='camp'? props.translate(`resources.applications.fields.${field}.name`): props.translate(`resources.applications.fields.${field}`);
        })
      }, (err: any, csv: any) => {
        downloadCSV(csv, `Jelentkezések - ${camp.name}`); // download as 'posts.csv` file
      });
    });
    });
};

const pdfExporter = (props)=>(data,fetchRelatedRecords,dataProvider,resource) => {
  const camp_id = props.filterValues.camp_id;

  dataProvider.getOne('camps', { id: camp_id }).then(({data: camp}) => {

    dataProvider.downloadPdf('applications', { id: camp_id }).then(({data}) => {
      data.blob().then((blob: any) => {;
        const exporterName = 'pdf';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Jelentkezések - ${camp.name}.${exporterName}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });       
    });
    });
};


const CampNameFilter = ({source,alwaysOn}: { source: any, alwaysOn?: boolean }) => {
  const {
    data,
    loaded
  } = useGetList('applications/camps', {
    perPage: 1000,
    page: 1
  });

  if (loaded) {
    return <SelectInput source={source} choices={Object.values(data)} alwaysOn={alwaysOn} allowEmpty label="Tábor"/>
  }
  return <></>;
}
CampNameFilter.propTypes = fieldPropTypes;

CampNameFilter.defaultProps = {
  alwaysOn: false
}

const ApplicationListFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn/>
    <StatusFilter source="status" alwaysOn/>
    <CampNameFilter source="camp_id" alwaysOn/>
  </Filter>
);


const ApplicationListActions = (props: any) => {
  const {hasPermission} = usePermission();
  const listContext = useListController(props);
  const translate = useTranslate();
  if (!hasPermission(Permissions.MANAGE_APPLICATIONS) && !hasPermission(Permissions.MANAGE_CAMP_APPLICANTS)) {
    return null;
  }
  return (
    <div>
      <ExportButton exporter={csvExporter({...props, translate})} label='CSV export'/>
      <ExportButton exporter={pdfExporter({...props, translate})} label='PDF adatlapok'/>
    </div>
  );
}


export const ApplicationList = translate((props: any) => {
  const {hasPermission} = usePermission();
  if (!hasPermission(Permissions.MANAGE_APPLICATIONS) && !hasPermission(Permissions.MANAGE_CAMP_APPLICANTS)) {
    return null;
  }

  //exporter(props)
  return (
    <List {...props} bulkActionButtons={false} filters={<ApplicationListFilter/>} actions={<ApplicationListActions/>} >
      <Datagrid rowClick="show">
        <ReferenceField reference="users" source="user_id" linkType={false}>
          <FullNameField source="fullName" size="35"/>
        </ReferenceField>
        <ReferenceField reference="users" source="user_id" linkType={false} label='Kor'>
        <FunctionField   label={props.translate(`resources.users.fields.age`)}  render={(record)=>{
                  if(!record?.date_of_birth){
                    return '';
                  }  
                  const today = new Date();
                    const birthDate = new Date(record.date_of_birth);
                    let age = today.getFullYear() - birthDate.getFullYear();
                    const m = today.getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age--;
                    }
                    if(age < 0 || age > 100 || isNaN(age)){
                        return '';
                    }
                    return age;
                }}/>        
        </ReferenceField>
        <ReferenceArrayField source="user_department_ids" reference="departments" label='Csapat' >
            <SingleFieldList linkType={false}>
                <ChipField source="title"/>
            </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField reference="camps" source="camp_id" linkType={false}>
          <FunctionField
            label={props.translate(`resources.camps.fields.name`)}
            render={CampNameField}
          />
        </ReferenceField>
        <StatusField source="status" addLabel={true}/>
      </Datagrid>
    </List>
  )
});
