import {FormWithRedirect, Loading} from "react-admin";
import React from "react";
import {ScoutChildPicker} from "./component/application/ScoutChildPicker";
import {Application} from "./component/application/Application";

export const CampApplyForm = (props: any) => {
    const {
        selectedUser,
        setSelectedUser
    } = props;

    const campName = `${props.record.camp?.parent ? `${props.record.camp?.parent?.name} - ` : ''}${props.record.camp?.name}`

    if (!selectedUser.id && !props.scoutChildren) {
        return <Loading/>
    }

    const applicationProps = {
        ...props,
        selectedUser
    };
    const validateApplication = (values: any) => {
        const errors = {
            medical_information: {}
        };

        if (!values.agree_camp_regulations) {
            //@ts-ignore
            errors.agree_camp_regulations = ['A tábori szabályzat elfogadás feltétele a sikeres jelentkezésnek'];
        }
        if (!values.agree_moral_statement) {
            //@ts-ignore
            errors.agree_moral_statement = ['Adatkezelési és erkölcsi nyilatkozat elfogadása feltétele a sikeres jelentkezésnek'];
        }
        //console.debug(errors);
        return errors
    };

    const {record} = props;
    record.user = selectedUser;
    return (<FormWithRedirect
        {...props}
        {...record}
        resource="users/apply"
        fullWidth={true}
        initialValues={{
            options: {
                transfers: {ask: false},
                start_available: true
            }
        }}
        validate={validateApplication}
        redirect={`${props.basePath}/show`}
        render={(formProps: any) => {
            return (<form>
                <ScoutChildPicker {...props} {...{
                    selectedUser,
                    setSelectedUser
                }}/>
                {selectedUser.id && <Application {...applicationProps} prefix="user"
                                                 campName={campName}  {...{
                    selectedUser,
                    setSelectedUser,
                    formProps
                }}/>}
            </form>)
        }}/>);
}


