//import {Link} from 'react-router-dom';
import {Button} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import {useTranslate} from "ra-core";
import {CampType} from "../pages/camps";


interface ApplicationButtonType {
    record: CampType
}

export const ApplicationButton = ({record}: ApplicationButtonType) => {
    const t = useTranslate();
    // if (record.isApplicant) {
    //     return null;
    // }
    return (<Button variant="contained" color="primary" href={`/camps/${record.id}/apply`}>{t('resources.camps.apply')}</Button>);
}