import {DepartmentEdit} from "./DepartmentEdit";
import {DepartmentShow} from "./DepartmentShow";
import {DepartmentCreate} from "./DepartmentCreate";
import {DepartmentList} from "./DepartmentList";
import GroupWorkIcon from '@material-ui/icons/GroupWork';

const resource = {
    list: DepartmentList,
    create: DepartmentCreate,
    show: DepartmentShow,
    edit: DepartmentEdit,
    icon:GroupWorkIcon
};

export default resource;
