import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {Create, SimpleForm, useDataProvider, useGetIdentity, useNotify} from 'react-admin';
import {PersonalData} from "../users/component/application/PersonalData";


export const ChildCreate = (props: any) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [saving, setSaving] = useState<boolean>();
    const {identity, loading, loaded: identityLoaded, error} = useGetIdentity();

    const handleSave = useCallback(
        async (values) => {
            setSaving(true);
            const newIdentity = await dataProvider.getOne('users', {id: 'my-profile'}).then(({data}) => {
                let userProfile = {...data};
                if (!userProfile.fullName) {
                    userProfile.fullName = `${userProfile.last_name} ${userProfile.first_name}`;
                }
                return Promise.resolve(userProfile);
            })
            dataProvider.updateUserProfile(
                {data: newIdentity},
                {
                    onSuccess: ({data}: any) => {
                        setSaving(false);
                        notify("Your profile has been updated", "info", {
                            _: "Your profile has been updated"
                        });
                    },
                    onFailure: () => {
                        setSaving(false);
                        notify(
                            "A technical error occured while updating your profile. Please try later.",
                            "warning",
                            {
                                _:
                                    "A technical error occured while updating your profile. Please try later."
                            }
                        );
                    }
                }
            );
        },
        [dataProvider, notify]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            saving
        }),
        [saving, handleSave]
    );

    return (
        <Create {...props}>
            <SimpleForm>
                <PersonalData
                    saveWrapper={handleSave}
                    record={undefined}
                    selectedUser={{parent_id: identity?.id}}
                    withoutForm
                />
            </SimpleForm>
        </Create>
    )
};