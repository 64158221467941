import * as React from "react";
import {Datagrid, DateField, FunctionField, List, ReferenceField, TextField} from 'react-admin';
import {usePermission} from "../../providers/permissionProvider";
import {Permissions} from "../../components/Permissions";
import {useParams} from "react-router-dom";
import { CampCommander } from "../../components/CampCommander";
import { CampCommanderField } from "../../components/CampCommanderField";

export const CampList = (props: any) => {
    const {hasPermission} = usePermission();
    if (!hasPermission(Permissions.MANAGE_CAMPS) && !hasPermission(Permissions.MANAGE_MEMBERS)) {
        return null;
    }
    return (<List {...props} hasCreate={true} filter={{parent: null}} exporter={false} bulkActionButtons={false}>
            <Datagrid rowClick="show">
                <TextField source="name"/>
                <ReferenceField source="parent_id" reference="camps"><TextField source="name"/></ReferenceField>
                <ReferenceField source="commander.id" reference="users" label="Táborparancsnok"  link={false}><CampCommanderField addLabel={false}/></ReferenceField>
                <DateField source="start_at" showTime={true}/>
                <DateField source="finish_at" showTime={true}/>
            </Datagrid>
        </List>)
}
