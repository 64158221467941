import * as React from "react";
import {AutocompleteInput, Create, FormDataConsumer, Loading, ReferenceInput, required, SelectInput, SimpleForm, TextInput, useGetIdentity} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {Typography} from "@material-ui/core";
import {useForm} from "react-final-form";

function CampParentField(props: any) {
  const form = useForm();
  let choices: any[] = [];
  //console.debug(form.getState())

  return <ReferenceInput source="parent.id" reference="camps" {...props} onChange={({target}) => {
    const parentData = choices.filter((i) => target.value === i.id).shift();
    const {
      address: {
        id,
        is_billing,
        is_primary,
        is_public,
        is_shipping,
        ...address
      },
      commander,
      department_ids,
      ref,
      start_at,
      finish_at,
      is_ltc,
      description,
      media
    } = parentData;
    //console.debug(parentData);
    form.initialize({
      address,
      commander,
      department: {
        id: department_ids.shift()
      },
      ref,
      start_at,
      finish_at,
      is_ltc,
      description,
      media
    })
    //console.debug(parentData);

  }}><FormDataConsumer>{({
                           formData, // The whole form data
                           scopedFormData, // The data for this item of the ArrayInput
                           getSource, // A function to get the valid source inside an ArrayInput
                           ...rest

                         }) => {

    const {
      // @ts-ignore
      loading,
      ...inputProps
    } = rest;
    // @ts-ignore
    choices = inputProps.choices;
    return (<SelectInput optionText="name" {...inputProps}/>)
  }}</FormDataConsumer></ReferenceInput>;
}

export const CampCreate = (props: any) => {
  // @ts-ignore
  const {
    loaded: identityLoaded,
    identity
  } = useGetIdentity();
  var toolbarOptions = [['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}], [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    [{'direction': 'rtl'}],                         // text direction

    [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    //[{ 'font': [] }],
    [{'align': []}], ['image'], ['clean']                                         // remove formatting button
  ]
  if (!identityLoaded) {
    return <Loading/>
  }
  const depId = (identity?.department_ids || [undefined])[0];
  return (<Create {...props}>
    <SimpleForm>
      <TextInput source="name" isRequired={true}/>
      <TextInput source="ref" validate={[required()]}/>
      <ReferenceInput source="department.id" reference="departments" isRequired={true}
                      initialValue={depId}>
        <AutocompleteInput optionText="title"/>
      </ReferenceInput>
      <CampParentField/>
      <ReferenceInput source="commander.id" reference="users" validate={[required()]}
                      initialValue={identity?.id}>
        <AutocompleteInput optionText={(record: any) => `${record?.last_name} ${record?.first_name}`}/>
      </ReferenceInput>
      <TextInput source="start_at" type="datetime" validate={[required()]}/>
      <TextInput source="finish_at" type="datetime" validate={[required()]}/>
      <Typography variant="h6">Helyszín</Typography>
      <TextInput source="address.street" validate={[required()]}/>
      <TextInput source="address.street_extra"/>
      <TextInput source="address.city" validate={[required()]}/>
      <TextInput source="address.state"/>
      <TextInput source="address.post_code" validate={[required()]}/>
      <ReferenceInput source="address.country_id" reference="countries"
                      validate={[required()]}><AutocompleteInput
        optionText="name"/></ReferenceInput>
      <TextInput source="address.note"/>
      <RichTextInput source="description" toolbar={toolbarOptions}/>
    </SimpleForm>
  </Create>);
}
