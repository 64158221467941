import React, {useState} from "react";
import {CRUD_UPDATE, Identifier, useNotify, useUpdate} from "ra-core";
import {SimpleShowLayout, Toolbar, useRefresh} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import {diff as deepDiff} from "deep-object-diff";
import {Box, Paper, Typography} from "@material-ui/core";
import {Form} from "react-final-form";

export const EditableComponent = ({
                                    isEditable = false,
                                    label = '',
                                    children,
                                    record,
                                    prefix,
                                    withoutForm,
                                    ...props
                                  }: any) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const EditButton = () => {
    if (!isEditable) {
      return null;
    }
    return <IconButton onClick={() => {
      setEdit(!isEdit);
    }}><CreateIcon/></IconButton>;
  }

  const [update] = useUpdate(props.resource, record?.id as Identifier);

  const onSave = (values: any) => {
    const diff = deepDiff(record, values);
    if (Object.keys(diff).length) {
      update({payload: {data: diff}},
        {
          action: CRUD_UPDATE,

          onSuccess: function () {
            props.saveWrapper && props.saveWrapper(diff);
            setEdit(false);
            notify("ra.notification.updated", "info", {
              smart_count: 1
            });
            if (prefix) {

              refresh();
            }
          },
          onFailure: function (data) {
            notify("string" === typeof data ? data : data.message || "ra.notification.http_error", "warning")
          },

        }
      )
    } else {
      setEdit(false);
    }
  }

  if (withoutForm) {
    return <Paper>
      <Box margin="1em">

        <Typography
          variant="h6"
        >
          {label}
          <EditButton/>
        </Typography>
        {React.Children.map(children, (child: any) => React.cloneElement(child, {...props, isEdit}))}
      </Box>
    </Paper>

  }

  return <SimpleShowLayout {...props} record={record}>
    <Typography
      variant="h6"
      component="span"
      align="center"
    >
      {label}
      <EditButton/>
    </Typography>
    {isEdit ? <Form
        initialValues={record}
        onSubmit={onSave}
        render={({handleSubmit, invalid, dirty, ...formProps}) => {
          console.debug(children);
          return (<>
              {!children.map && React.cloneElement(children, {...props, ...formProps, isEdit})}
              {children.map && children.map((child: any) => React.cloneElement(child, {...props, ...formProps, isEdit}))}
              <Toolbar {...{handleSubmit, invalid, dirty}}/>
            </>
          )
        }
        }/> :
      (children.map ? children.map((child: any) => React.cloneElement(child, {...props, isEdit})) : React.cloneElement(children, {...props, isEdit}))})
  </SimpleShowLayout>
}
