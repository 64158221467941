import * as React from 'react';
import {Children, cloneElement, FC, isValidElement, ReactElement, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {Record, useTranslate} from 'ra-core';
import classnames from 'classnames';
import classNames from 'classnames';
import {FieldArrayRenderProps} from 'react-final-form-arrays';

import {ClassesOverride, FormInput} from "react-admin"
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {DatagridHeaderCell, useDatagridStyles} from "ra-ui-materialui/lib/list/datagrid";

const useStyles = makeStyles(
    theme => ({
        root: {
            padding: 0,
            marginBottom: 0,
            '& > li:last-child': {
                borderBottom: 'none',
            },
        },
        line: {
            display: 'flex',
            listStyleType: 'none',
            borderBottom: `solid 1px ${theme.palette.divider}`,
            [theme.breakpoints.down('xs')]: {display: 'block'},
            '&.fade-enter': {
                opacity: 0.01,
                transform: 'translateX(100vw)',
            },
            '&.fade-enter-active': {
                opacity: 1,
                transform: 'translateX(0)',
                transition: 'all 500ms ease-in',
            },
            '&.fade-exit': {
                opacity: 1,
                transform: 'translateX(0)',
            },
            '&.fade-exit-active': {
                opacity: 0.01,
                transform: 'translateX(100vw)',
                transition: 'all 500ms ease-in',
            },
        },
        index: {
            width: '3em',
            paddingTop: '1em',
            [theme.breakpoints.down('sm')]: {display: 'none'},
        },
        form: {flex: 2},
        action: {
            paddingTop: '0.5em',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
    }),
    {name: 'RaSimpleFormIterator'}
);

const DefaultAddButton = (props: any) => {
    const classes = useStyles(props);
    const translate = useTranslate();
    return (
        <Button size="small" {...props}>
            <AddIcon className={classes.leftIcon}/>
            {translate('ra.action.add')}
        </Button>
    );
};

const DefaultRemoveButton = (props: any) => {
    const classes = useStyles(props);

    const translate = useTranslate();
    return (
        <Button size="small" {...props}>
            <CloseIcon className={classes.leftIcon}/>
            {translate('ra.action.remove')}
        </Button>
    );
};

// @ts-ignore
function getDatagridHeaderCell(dataGridClasses: Record, currentSort: { field: string; order: string }, field: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal, source: string, index: number, resource: string, updateSort: () => void) {
    //console.debug(field);
    return <DatagridHeaderCell
        className={dataGridClasses.headerCell}
        currentSort={currentSort}
        field={field}
        isSorting={
            currentSort.field ===
            ((field.props as any).sortBy ||
                (field.props as any).source)
        }
        key={source || index}
        resource={resource}
        updateSort={updateSort}
    />;
}

const SimpleFormIterator: FC<SimpleFormIteratorProps & { children: React.ReactNode;}> = (props: any) => {
    const {
        addButton = <DefaultAddButton/>,
        removeButton = <DefaultRemoveButton/>,
        basePath,
        children,
        className,
        fields,
        record,
        resource,
        source,
        disabled,
        disableAdd,
        disableRemove,
        variant,
        margin,
        defaultValue,
        size = 'small',
        expand
    } = props;
    const classes = useStyles(props);
    const dataGridClasses = useDatagridStyles(props);

    const [currentSort] = useState({
        field: '',
        order: ''
    });

    const updateSort = () => {
        console.error("update sort is not implemented yet")
    };

    // We need a unique id for each field for a proper enter/exit animation
    // so we keep an internal map between the field position and an auto-increment id
    const nextId = useRef(
        fields && fields.length
            ? fields.length
            : defaultValue
            ? defaultValue.length
            : 0
    );

    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    const ids = useRef(
        nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
    );

    const removeField = (index:any) => () => {
        ids.current.splice(index, 1);
        fields.remove(index);
    };

    // Returns a boolean to indicate whether to disable the remove button for certain fields.
    // If disableRemove is a function, then call the function with the current record to
    // determining if the button should be disabled. Otherwise, use a boolean property that
    // enables or disables the button for all of the fields.
    const disableRemoveField = (record:any, disableRemove:any) => {
        if (typeof disableRemove === 'boolean') {
            return disableRemove;
        }
        return disableRemove && disableRemove(record);
    };

    const addField = () => {
        ids.current.push(nextId.current++);
        fields.push(undefined);
    };

    // add field and call the onClick event of the button passed as addButton prop
    const handleAddButtonClick = (originalOnClickHandler:any) => (event:any) => {
        addField();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };

    // remove field and call the onClick event of the button passed as removeButton prop
    const handleRemoveButtonClick = (
        originalOnClickHandler:any,
        index:any
    ) => (event:any) => {
        removeField(index)();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };
    const records = get(record, source);
    return fields ? (<Table
        className={classnames(dataGridClasses.table, className)}
        size={size}
    >
        <TableHead className={dataGridClasses.thead}>
            <TableRow
                className={classnames(dataGridClasses.row, dataGridClasses.headerRow)}
            >
                {expand && (
                    <TableCell
                        padding="none"
                        className={classnames(
                            dataGridClasses.headerCell,
                            dataGridClasses.expandHeader
                        )}
                    />
                )}
                {/*{fields.map((member, index) => (`${member}`))}*/}
                {Children.map(children, (field, index) =>
                    // @ts-ignore
                    isValidElement(field) ? getDatagridHeaderCell(dataGridClasses, currentSort, field, source, index, resource, updateSort) : null
                )}
                <TableCell
                    padding="none"
                    className={classnames(
                        dataGridClasses.headerCell,
                        dataGridClasses.expandHeader
                    )}

                >
                    {!disabled && !disableAdd && (

                        <span className={classes.action}>
                                         {cloneElement(addButton, {
                                             onClick: handleAddButtonClick(
                                                 addButton.props.onClick
                                             ),
                                             className: classNames(
                                                 'button-add',
                                                 `button-add-${source}`
                                             ),
                                         })}
                                     </span>
                    )}
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {
                fields.map((member:any, index:any) => (
                <TableRow>
                    {Children.map(children, (input, index2) => {

                        //const source = `${member}.${get(input, 'props.source')}`;


                        if (!isValidElement<any>(input)) {
                            return null;
                        }
                        const {
                            source,
                            ...inputProps
                        } = input.props;
                        return (<TableCell>
                                <FormInput
                                    basePath={
                                        input.props.basePath ||
                                        basePath
                                    }
                                    input={cloneElement(input, {
                                        source: source
                                            ? `${member}.${source}`
                                            : member,
                                        index: source
                                            ? undefined
                                            : index2,
                                        label:
                                            typeof input.props
                                                .label ===
                                            'undefined'
                                                ? source
                                                ? `resources.${resource}.fields.${source}`
                                                : undefined
                                                : input.props.label,
                                        disabled,
                                        ...inputProps,
                                    })}
                                    record={
                                        (records &&
                                            records[index]) ||
                                        {}
                                    }
                                    resource={resource}
                                    variant={variant}
                                    margin={margin}
                                /></TableCell>
                        );


                        // return (<TableCell>
                        //     {React.cloneElement(input, {
                        //         record,
                        //         basePath: input.props.basePath || basePath,
                        //         resource,
                        //         source: source,
                        //         index: source ? undefined : index2,
                        //     })}
                        // </TableCell>)
                    })}
                    {!disabled &&
                    !disableRemoveField(
                        (records && records[index]) || {},
                        disableRemove
                    ) && (<TableCell>

                            {cloneElement(removeButton, {
                                onClick: handleRemoveButtonClick(
                                    removeButton.props.onClick,
                                    index
                                ),
                                className: classNames(
                                    'button-remove',
                                    `button-remove-${source}-${index}`
                                ),
                            })}
                        </TableCell>
                    )}

                </TableRow>))}
        </TableBody>
    </Table>) : null;
    // return fields ? (
    //     <ul className={classNames(classes.root, className)}>
    //         {submitFailed && typeof error !== 'object' && error && (
    //             <FormHelperText error>
    //                 <ValidationError error={error as string} />
    //             </FormHelperText>
    //         )}
    //         <TransitionGroup component={null}>
    //             {fields.map((member, index) => (
    //                 <CSSTransition
    //                     nodeRef={nodeRef}
    //                     key={ids.current[index]}
    //                     timeout={500}
    //                     classNames="fade"
    //                     {...TransitionProps}
    //                 >
    //                     <li className={classes.line}>
    //                         <Typography
    //                             variant="body1"
    //                             className={classes.index}
    //                         >
    //                             {index + 1}
    //                         </Typography>
    //                         <section className={classes.form}>
    //                             {Children.map(
    //                                 children,
    //                                 (input: ReactElement, index2) => {
    //                                     if (!isValidElement<any>(input)) {
    //                                         return null;
    //                                     }
    //                                     const {
    //                                         source,
    //                                         ...inputProps
    //                                     } = input.props;
    //                                     return (
    //                                         <FormInput
    //                                             basePath={
    //                                                 input.props.basePath ||
    //                                                 basePath
    //                                             }
    //                                             input={cloneElement(input, {
    //                                                 source: source
    //                                                     ? `${member}.${source}`
    //                                                     : member,
    //                                                 index: source
    //                                                     ? undefined
    //                                                     : index2,
    //                                                 label:
    //                                                     typeof input.props
    //                                                         .label ===
    //                                                     'undefined'
    //                                                         ? source
    //                                                             ? `resources.${resource}.fields.${source}`
    //                                                             : undefined
    //                                                         : input.props.label,
    //                                                 disabled,
    //                                                 ...inputProps,
    //                                             })}
    //                                             record={
    //                                                 (records &&
    //                                                     records[index]) ||
    //                                                 {}
    //                                             }
    //                                             resource={resource}
    //                                             variant={variant}
    //                                             margin={margin}
    //                                         />
    //                                     );
    //                                 }
    //                             )}
    //                         </section>
    //                         {!disabled &&
    //                             !disableRemoveField(
    //                                 (records && records[index]) || {},
    //                                 disableRemove
    //                             ) && (
    //                                 <span className={classes.action}>
    //                                     {cloneElement(removeButton, {
    //                                         onClick: handleRemoveButtonClick(
    //                                             removeButton.props.onClick,
    //                                             index
    //                                         ),
    //                                         className: classNames(
    //                                             'button-remove',
    //                                             `button-remove-${source}-${index}`
    //                                         ),
    //                                     })}
    //                                 </span>
    //                             )}
    //                     </li>
    //                 </CSSTransition>
    //             ))}
    //         </TransitionGroup>
    //         {!disabled && !disableAdd && (
    //             <li className={classes.line}>
    //                 <span className={classes.action}>
    //                     {cloneElement(addButton, {
    //                         onClick: handleAddButtonClick(
    //                             addButton.props.onClick
    //                         ),
    //                         className: classNames(
    //                             'button-add',
    //                             `button-add-${source}`
    //                         ),
    //                     })}
    //                 </span>
    //             </li>
    //         )}
    //     </ul>
    // ) : null;
};

SimpleFormIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false,
};

SimpleFormIterator.propTypes = {
    defaultValue: PropTypes.any,
    addButton: PropTypes.element,
    removeButton: PropTypes.element,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    // @ts-ignore
    fields: PropTypes.object,
    meta: PropTypes.object,
    // @ts-ignore
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    TransitionProps: PropTypes.shape({}),
};

type DisableRemoveFunction = (record: Record) => boolean;

export interface SimpleFormIteratorProps
    extends Partial<Omit<FieldArrayRenderProps<any, HTMLElement>, 'meta'>> {
    addButton?: ReactElement;
    basePath?: string;
    classes?: ClassesOverride<typeof useStyles>;
    className?: string;
    defaultValue?: any;
    disabled?: boolean;
    disableAdd?: boolean;
    disableRemove?: boolean | DisableRemoveFunction;
    margin?: 'none' | 'normal' | 'dense';
    meta?: {
        // the type defined in FieldArrayRenderProps says error is boolean, which is wrong.
        error?: any;
        submitFailed?: boolean;
    };
    record?: Record;
    removeButton?: ReactElement;
    resource?: string;
    source?: string;
    TransitionProps?: any;
    variant?: 'standard' | 'outlined' | 'filled';
}

export default SimpleFormIterator;
