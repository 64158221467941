import {Typography} from "@material-ui/core";
import {AvatarWithName} from "./AvatarWithName";
import React from "react";
import {Identifier} from "react-admin";
import {useTranslate} from "ra-core";

interface CampCommanderType {
    avatar?: string;
    last_name?: string;
    first_name?: string;
    linkTo?: boolean;
    id?: Identifier;
}

export const CampCommander = (props: CampCommanderType) => {
    const t = useTranslate();
    if (!props.id) {
        return (<Typography>
            {t('resources.camps.commander', 'Tábor parancsnok')}: -
        </Typography>);
    }
    return (<Typography>
        {t('resources.camps.commander', 'Tábor parancsnok')}: <AvatarWithName {...props}/>
    </Typography>);
}