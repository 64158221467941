import React, { PropsWithChildren } from "react";
import {Box, Paper, Grid} from "@material-ui/core";
import {BaseData} from "../../components/BaseData";
import {ContactInformation} from "../../components/ContactInformation";
import {MedicalInformation} from "../../components/MedicalInformation";
import {PropsWithSelectedUser} from "../../../../types";
import {AvatarUploader} from "../../components/AvatarUploader";
import {ScoutInformation} from "../../../../components/ScoutInformation";


export const PersonalData = (props: { formProps?: any, saveWrapper?: Function, record?: any, resource?:string, prefix?: string, withoutForm?: boolean} & PropsWithSelectedUser & PropsWithChildren) => {
    const {children, ...contentProps} = props;
    if (!props.selectedUser && props.record) {
        contentProps.selectedUser = props.record;
    }
    // console.debug(props);
    return <Box p="1em">
        <Box display="flex" >
            <Box flex={1} className="ra-field ">
                <BaseData {...contentProps}/>
                <AvatarUploader {...contentProps}/>
            </Box>
            <Box flex={1} ml="1em" className="ra-field">
                <ContactInformation {...contentProps}/>
                <ScoutInformation {...props} {...contentProps}/>
            </Box>
        </Box>
        <MedicalInformation  {...contentProps}/>
        {children ? <ExtraBoxes>{children}</ExtraBoxes> : null}
    </Box>;
}


const ExtraBoxes = (props: PropsWithChildren) => {
    const {children} = props;
    //if the children is not null, then show the children in two columns
    if (children && Array.isArray(children)) {
        
        return <Paper>
            <Grid container>
                <Grid item xs={6}>
                    {children.filter((child, index) => index % 2 === 0).map((child, index) => child)}
                </Grid>
                <Grid item xs={6}>
                    {children.filter((child, index) => index % 2 === 1).map((child, index) => child)}
                </Grid>
            </Grid>
        </Paper>;
    }
    return null;    
}