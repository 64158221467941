import {Typography} from "@material-ui/core";
import {AvatarWithName} from "./AvatarWithName";
import React from "react";
import {useRecordContext, Identifier, Labeled} from "react-admin";
import {useTranslate} from "ra-core";

interface CampCommanderType {
    avatar?: string;
    last_name?: string;
    first_name?: string;
    linkTo?: boolean;
    id?: Identifier;
}

export const CampCommanderField = (props:any) => {
    const record = useRecordContext(props);
    const {addLabel=true}=props;
    
    console.debug(record);
    const commander = record?.camp?.commander || record;
    const t = useTranslate();

    if(addLabel){
        return (<Labeled label={t("resources.camps.commander")} {...props}><Typography>
            <AvatarWithName {...commander}/>
        </Typography></Labeled>);
    
    } else {
        return (<Typography>
            <AvatarWithName {...commander}/>
        </Typography>)
    
    }
}