import {useEffect, useState} from "react";
import {usePermissions as useRaPermissions} from "react-admin";
import {useProfile} from "./profileProvider";

export interface PropsWithPermissions {
    permissions: string[]
}

export interface PermissionState {
    [key: string]: boolean
}


export function usePermission() {
    const {permissions, loaded, error, loading} = useRaPermissions();
    // @ts-ignore
    const {profileVersion} = useProfile();
    const [hasPerm, setHasPerm] = useState<PermissionState>({profileVersion});
    useEffect(()=>{
        setHasPerm({profileVersion});
    }, [profileVersion]);
    return {
        permissions, loaded, error, loading, hasPermission: (required: string, customPermissions?: Array<string>) => {
            const findPerm = (perm: string) => perm === required;
            const checkPerm = (perms?: string[]) => (perms || []).findIndex(findPerm) >= 0;
            if (!loaded) return false;

            
            //console.debug({hasPerm, checkPerm:checkPerm(permissions)});
            // @ts-ignore
            if (!hasPerm.hasOwnProperty(required)) {
                hasPerm[required]=checkPerm(permissions);
                setHasPerm({...hasPerm});
            }

            if (customPermissions?.length && !hasPerm[required]) {
                return checkPerm(customPermissions)
            }
            return hasPerm[required];
        }
    }
}
