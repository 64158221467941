import {makeStyles} from "@material-ui/core/styles";
import get from "lodash/get";
import Chip from "@material-ui/core/Chip";
import classnames from "classnames";
import sanitizeFieldRestProps from "ra-ui-materialui/lib/field/sanitizeFieldRestProps";
import * as React from "react";
import {InjectedFieldProps, PublicFieldProps} from "ra-ui-materialui/src/field/types";
import useTranslate from "ra-core/esm/i18n/useTranslate";
import {Icon} from '@material-ui/core';
import Liliom from './csjelveny.svg';
import OVScoutIcon from './icons/OVScoutIcon';
import STScoutIcon from './icons/STScoutIcon';
import Badge from '@material-ui/core/Badge';


const useStyles = makeStyles(
    theme => ({
        cub: {
            color: '#fff',
            fontWeight: "bold",
            backgroundColor: 'rgb(0,128,201)',
        },
        scout: {
            color: '#fff',
            fontWeight: "bold",
            backgroundColor: 'rgb(21,142,48)',
        },
        auxiliary: {
            color: '#fff',
            fontWeight: "bold",
            backgroundColor: 'rgb(21,142,48)',
        }, guardian: {
            color: '#fff',
            fontWeight: "bold",
            backgroundColor: 'rgb(21,142,48)',
        },
        adult: {
            color: '#fff',
            fontWeight: "bold",
            backgroundColor: 'rgb(91,41,25)',
        },
        chip: {margin: 4, cursor: 'inherit'},

    }),
    {name: 'RaChipField'}
);


interface RankFieldProps extends PublicFieldProps,
    InjectedFieldProps {
    source: string
}

// @ts-ignore
export const RankField = (props: RankFieldProps) => {
    var translate = useTranslate();
//debugger;
    // @ts-ignore
    const {
        // @ts-ignore
        className,
        // @ts-ignore
        classes: classesOverride,
        // @ts-ignore
        source,
        // @ts-ignore
        resource,
        // @ts-ignore
        record = {},
        // @ts-ignore
        emptyText,
        ...rest
    } = props;
    // @ts-ignore
    const classes = useStyles(props);
    const value = get(record, source);

    const newProps = {className: '', icon: <></>};
    switch (value) {
        case 'cub':
        case 'scout':
        case 'adult':
            newProps.icon = <Icon><img style={{height: 24}} src={Liliom}/></Icon>;
            break;
        case 'guardian':
            newProps.icon = <Badge anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }} badgeContent={<OVScoutIcon/>}>
                <Icon>
                    <img style={{height: 24}} src={Liliom}/>
                </Icon>
            </Badge>;
            break;
        case 'auxiliary':
            newProps.icon = <STScoutIcon/>;
            break;
    }
    switch (value) {
        case 'cub':
        case 'scout':
        case 'adult':
        case 'guardian':
        case 'auxiliary':
            // @ts-ignore
            newProps.className = classes[value];
            break;
    }


    return <Chip
        className={classnames(classes.chip, newProps.className)}
        label={translate(`rank.${value || 'none'}`)}
        {...sanitizeFieldRestProps({...rest, icon: newProps.icon})}
    />
}


// @ts-ignore
//export const RankField = translate(RankFieldView);

