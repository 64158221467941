import * as React from "react";
import {ChipField, Datagrid, List, ReferenceArrayField, SingleFieldList} from 'react-admin';
import {StatusField} from "../../components/StatusField";
import {RankField} from "../../components/RankField";
import FullNameField from "../../components/FullNameField";

export const ChildList = (props: any) => (
    <List {...props} bulkActionButtons={false} exporter={false} >
        <Datagrid rowClick="edit">
            <FullNameField source="avatar" size="35"/>
            <RankField source="rank" addLabel={true}/>
            <ReferenceArrayField source="department_ids" reference="departments" label='Csapat'>
                <SingleFieldList>
                    <ChipField source="title"/>
                </SingleFieldList>
            </ReferenceArrayField>
            <StatusField source="status" parser={(currentSourceRecord, resource)=>currentSourceRecord.departments.map((d)=>d.status).shift()}  sortable={false}/>

        </Datagrid>
    </List>
);