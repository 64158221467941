// @ts-ignore
import {EditButton, TopToolbar, useGetIdentity, useUpdate} from "react-admin";
import Button from "@material-ui/core/Button";
import React from "react";

export const PostShowActions = (props: any) => {
    const {identity} = useGetIdentity();
    const {data} = props;
    const [update] = useUpdate('members', 0, {
        action: 'accept:' + (identity?.commanderOf.join(',')),
    })
    if (identity && data) {
        return (
            <TopToolbar>
                {identity?.id === data.parent_id && <EditButton basePath={props.basePath} record={data}/>}
            </TopToolbar>
        )

    }
    return null;
};
