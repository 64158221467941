import React from "react";
import {Box, Paper, Typography} from "@material-ui/core";
import {MedicalInformationType} from "../../../types";
import {MedicalInformation as Info} from "./mediaclInformation/MedicalInformation";
import {Insurance} from "./mediaclInformation/Insurance";

export const MedicalInformation = (props: any) => {
    return (
        <Paper>
            <Typography variant="h6">Orvosi információk</Typography>
            <Box display="flex">
                <Box flex={2} className="ra-field ">
                    <Insurance {...props}/>
                    <Info {...props} type={MedicalInformationType.DISEASE}/>
                    <Info {...props} type={MedicalInformationType.DOCTOR}/>
                    <Info {...props} type={MedicalInformationType.MEDICINE}/>
                </Box>
                <Box flex={2} ml="1em" className="ra-field ">
                    <Info {...props} type={MedicalInformationType.VACCINATION}/>
                    <Info {...props} type={MedicalInformationType.ALLERGY}/>
                    <Info {...props} type={MedicalInformationType.EATING_HABITS}/>
                </Box>
            </Box>
        </Paper>
    )
};