import * as React from "react";
import {
    AutocompleteArrayInput,
    BooleanInput,
    Edit,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    DateInput,
    AutocompleteInput
} from 'react-admin';
import {useEditContext, useResourceDefinition, useTranslate} from "ra-core";
import {TopToolbar, ShowButton, EditActionsProps} from "ra-ui-materialui";
import {usePermission} from "../../providers/permissionProvider";
import ImpersonateButton from "../../components/ImpersonateButton";
import {Permissions} from "../../components/Permissions";


const EditTitle = (props: any) => {
    const {record, resource} = props;
    const t = useTranslate();
    return <>{t(`resources.${resource}.pageTitle`, {
        ...record
    })}</>;
};


const EditActions = ({className, ...rest}: EditActionsProps) => {
    const sanitizeRestProps = ({
                                   basePath = null,
                                   hasCreate = null,
                                   hasEdit = null,
                                   hasShow = null,
                                   hasList = null,
                                   ...rest
                               }) => rest;

    const {basePath, record} = useEditContext(rest);
    const {hasShow} = useResourceDefinition(rest);
    const {hasPermission} = usePermission();
    const canImpersonate = hasPermission('impersonate');

    return (
        <TopToolbar>
            {hasShow && <ShowButton basePath={basePath} record={record}/>}
            {canImpersonate && <ImpersonateButton basePath={basePath} record={record}/>}
        </TopToolbar>
    );
};

export const UserEdit = (props: any) => {
    const {loaded, permissions, hasPermission} = usePermission();
    //console.debug(hasPermission(Permissions.SUPERADMIN), Permissions.SUPERADMIN, permissions);
    if (loaded && !hasPermission(Permissions.SUPERADMIN)) {
        props.history.push("/forbidden");
        return null;
    }
    if(loaded){
        return (<UserEditForm {...props}/>);
    }
    return null;
};

export const UserEditForm = (props: any) => {
    const optionRenderer = choice => choice?`${choice.last_name} ${choice.first_name} (${choice.email})`:'';

    return (
        <Edit {...props} title={<EditTitle {...props}/>} actions={<EditActions {...props}/>}>
            <SimpleForm>
        <TextInput source="last_name"/>
        <TextInput source="first_name"/>
        <TextInput source="email"/>
        <TextInput source="mobile"/>
        <SelectInput source="status" choices={[
            {id: 'pending', name: 'Pending'},
            {id: 'accepted', name: 'Accepted'},
            {id: 'blocked', name: 'Blocked'},
            {id: 'deleted', name: 'Deleted'},
        ]}/>
        <BooleanInput source="email_verified"/>
        <BooleanInput source="mobile_verified"/>
        <DateInput source="date_of_birth"/>
        <ReferenceArrayInput source="department_ids" reference="departments" perPage={1000} allowEmpty>
            <AutocompleteArrayInput optionText="title"/>
        </ReferenceArrayInput>
        <ReferenceInput label="Szülő" allowEmpty={true}
    source="parent_id"
    reference="users"

>
    <AutocompleteInput optionText={optionRenderer} />
</ReferenceInput>



    </SimpleForm>
    </Edit>
);
};
