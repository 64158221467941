// in src/App.js
import * as React from "react";
import {Admin, Resource} from 'react-admin';
import hungarianMessages from './i18n/hu';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import authProvider from "./providers/authProvider";
import delayedDataProvider from "./providers/dataProvider";
import userResource from "./models/users";
import faqResource from "./models/faq";
import faqCategoryResource from "./models/faqCategory";
import campsResource from "./pages/camps";
import addressResource from "./models/addresses";
import departmentsResource from "./models/departments";
import countriesResource from "./models/countries";
import membersResource from "./models/members";
import applicationsResource from "./models/applications";
import childrenResource from "./models/children";
import englishMessages from 'ra-language-english';
import {reducer as tree} from 'ra-tree-ui-materialui';
import {createMuiTheme} from "@material-ui/core";
import Dashboard from "./components/Dashboard";
import scoutprimary from './components/colors/scoutprimary';
import scoutsecondary from "./components/colors/scoutsecondary";
import ScoutLayout from "./components/ScoutLayout";
import {LoginPage} from "./pages/login/LoginPage";
import {routes} from "./routes/routes";
import {createBrowserHistory as createHistory} from 'history';
import {MedicalInformationType as MIType} from "./types";
import MaintenancePage from "./pages/maintenence/Maintenance";

const history = createHistory();

const theme = createMuiTheme({
    palette: {
        secondary: scoutsecondary,
        // Style sheet name ⚛️
        primary: scoutprimary,
    },
});


const messages = {
    hu: hungarianMessages,
    en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'hu');

const App = () => (
    <Admin dataProvider={delayedDataProvider}
           authProvider={authProvider}
           dashboard={Dashboard}
           i18nProvider={i18nProvider}
           layout={ScoutLayout}
           customReducers={{tree}}
           theme={theme}
           loginPage={LoginPage}
           customRoutes={routes}
           history={history}
    >
        <Resource name="profile"/>
        <Resource name="users" {...userResource}/>
        <Resource name="camps" {...campsResource}/>
        <Resource name="departments" {...departmentsResource}/>
        <Resource name="addresses" {...addressResource}/>
        <Resource name="countries" {...countriesResource}/>
        <Resource name="groups"/>
        <Resource name="members" {...membersResource}/>
        <Resource name="users/medical_information"/>
        <Resource name="applications/camps" />
        <Resource name="applications" {...applicationsResource}/>
        <Resource name="family" {...childrenResource}/>
        <Resource name={MIType.VACCINATION.resource}/>
        <Resource name={MIType.OTHERS.resource}/>
        <Resource name={MIType.MEDICINE.resource}/>
        <Resource name={MIType.EATING_HABITS.resource}/>
        <Resource name={MIType.DOCTOR.resource}/>
        <Resource name={MIType.ALLERGY.resource}/>
        <Resource name={MIType.DISEASE.resource}/>
        <Resource name={MIType.INSURANCE.resource}/>
        {/*<Resource name="faqs/categories" {...faqCategoryResource}/>*/}
        {/*<Resource name="faqs" {...faqResource}/>*/}
        {/*<Resource name="faq" {...faqResource}/>*/}
    </Admin>


);
//const App = () => (<MaintenancePage/>);

export default App;
