import * as React from "react";
import useTranslate from "ra-core/esm/i18n/useTranslate";
import {RadioButtonGroupInput} from "react-admin";

export const RankInput = (props: any) => {
    var translate = useTranslate();
    return <RadioButtonGroupInput row={false} source="rank" {...props} choices={[
        {id: 'none', name: translate(`rank.none`)},
        {id: 'cub', name: translate(`rank.cub`)},
        {id: 'scout', name: translate(`rank.scout`)},
        {id: 'guardian', name: translate(`rank.guardian`)},
        {id: 'auxiliary', name: translate(`rank.auxiliary`)},
        {id: 'officer', name: translate(`rank.officer`)},
        {id: 'group_officer', name: translate(`rank.group_officer`)},
        {id: 'adult', name: translate(`rank.adult`)},
    ]}/>
}


// @ts-ignore
//export const RankField = translate(RankFieldView);

