import {Record} from "ra-core";
import {Typography} from "@material-ui/core";
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput
} from "react-admin";
import * as React from "react";

export function CampBaseEdit(props: { record?: Record, fullWidth?: boolean }) {
    return <>
        <Typography variant="h6">Alap informárciók</Typography>
        <TextInput source="name" validate={[required()]} {...props}/>
        <TextInput source="ref" validate={[required()]} {...props}/>
        <ReferenceArrayInput source="department_ids" reference="departments" isRequired={true} {...props}>
            <AutocompleteArrayInput optionText="title" {...props}/>
        </ReferenceArrayInput>
        <ReferenceInput source="parent.id" reference="camps" {...props}>
            <SelectInput optionText="name" {...props}/>
        </ReferenceInput>
        <ReferenceInput source="commander.id" reference="users" validate={[required()]} {...props}>
            <AutocompleteInput optionText={(record: any) => `${record?.last_name} ${record?.first_name}`} {...props}/>
        </ReferenceInput>
        <TextInput source="start_at" type="datetime" validate={[required()]} {...props}/>
        <TextInput source="finish_at" type="datetime" validate={[required()]} {...props}/>
    </>;
}
