import decodeJwt from 'jwt-decode';
import dataProvider, {httpClient} from "./dataProvider";

function setUserToStorage(access_token, token_type, refresh_token, expires_in, refreshProfile = undefined) {
    localStorage.setItem('permissions', JSON.stringify(decodeJwt(access_token).permissions));
    localStorage.setItem('token', `${token_type} ${access_token}`);
    localStorage.setItem('refresh', `${token_type} ${refresh_token}`);
    localStorage.setItem('expires', (new Date()).getTime() + expires_in);

    return dataProvider.getOne('users', {id: 'my-profile'}).then(({data}) => {
        let userProfile = {...data};
        if (!userProfile.fullName) {
            userProfile.fullName = `${userProfile.last_name} ${userProfile.first_name}`;
        }
        refreshProfile && refreshProfile();
        localStorage.setItem('userProfile', JSON.stringify(userProfile));
        return Promise.resolve();
    });
}

export default {
    login: ({username, password}) => {
        const request = new Request(process.env.REACT_APP_AUTH_URL, {
            method: 'POST', body: JSON.stringify({
                'grant_type': process.env.REACT_APP_AUTH_GRANT_TYPE,
                'client_id': process.env.REACT_APP_AUTH_CLIENT_ID,
                'client_secret': process.env.REACT_APP_AUTH_CLIENT_SECRET, //'scope': process.env.REACT_APP_AUTH_SCOPE,
                username,
                password
            }), headers: new Headers({'Content-Type': 'application/json'}),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({access_token, expires_in, refresh_token, token_type}) => {
                return setUserToStorage(access_token, token_type, refresh_token, expires_in);

            });
    }, impersonate: ({user_id, refreshProfile}) => {
        return httpClient((process.env.REACT_APP_IMPERSONATE_URL || '//api.scoutdata.dev/oauth/impersonate'), {
            method: 'POST', body: JSON.stringify({
                'grant_type': 'impersonate',
                'client_id': process.env.REACT_APP_AUTH_CLIENT_ID,
                'client_secret': process.env.REACT_APP_AUTH_CLIENT_SECRET, //'scope': process.env.REACT_APP_AUTH_SCOPE,
                user_id,
            }), headers: new Headers({'Content-Type': 'application/json'}),
        })
            .then(({
                       json: {
                           access_token, expires_in, refresh_token, token_type
                       }
                   }) => {

                // clone current user data
                localStorage.setItem('or', JSON.stringify({
                    username: localStorage.getItem('username'),
                    token: localStorage.getItem('token'),
                    permissions: localStorage.getItem('permissions'),
                    refresh: localStorage.getItem('refresh'),
                    userProfile: localStorage.getItem('userProfile'),
                    expires: localStorage.getItem('expires')
                }));
                return setUserToStorage(access_token, token_type, refresh_token, expires_in, refreshProfile);
            });
    }, logout: () => {
        const or = localStorage.getItem('or')
        if (or) {
            const {
                username, token, permissions, refresh, userProfile, expires
            } = JSON.parse(or);
            localStorage.setItem('username', username);
            localStorage.setItem('token', token);
            localStorage.setItem('permissions', permissions);
            localStorage.setItem('refresh', refresh);
            localStorage.setItem('userProfile', userProfile);
            localStorage.setItem('expires', expires);
            localStorage.removeItem('or');
            return Promise.resolve();

        }
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('refresh');
        localStorage.removeItem('userProfile');
        localStorage.removeItem('expires');

        return Promise.resolve();
    }, getIdentity: (refresh = false) => {
        const profile = localStorage.getItem("userProfile");

        if (profile) {
            const data = JSON.parse(profile);
            if (!data.commanderOf) {
                data.commanderOf = data.departments.filter((dep) => {
                    return !!dep.is_commander;
                }).map((dep) => dep.id);
                localStorage.setItem('userProfile', JSON.stringify(data));
            }
            data.isCommanderOf = (deps = []) => {
                return !!deps.filter((dep) => data.commanderOf.includes(dep.id || dep)).length;
            }


            return Promise.resolve(data);
        }

        return Promise.reject();
    }, checkError: () => Promise.resolve(), checkAuth: () => {
        return localStorage.getItem('token') && localStorage.getItem('expires') > (new Date()).getTime() ? Promise.resolve() : Promise.reject({redirectTo: '/login'});
    }, getPermissions: () => {
        const role = JSON.parse(localStorage.getItem('permissions'));
        return role ? Promise.resolve(role) : Promise.reject();
    },
    refresh: () => {
        return dataProvider.getOne('users', {id: 'my-profile'}).then(({data}) => {
            let userProfile = {...data};
            if (!userProfile.fullName) {
                userProfile.fullName = `${userProfile.last_name} ${userProfile.first_name}`;
            }
            //refreshProfile && refreshProfile();
            localStorage.setItem('userProfile', JSON.stringify(userProfile));
            return Promise.resolve();
        });

    }
};
