import * as React from "react";
import {
    ArrayInput,
    Create,
    RadioButtonGroupInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput
} from 'react-admin';

export const UserCreate = (props:any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="last_name"/>
            <TextInput source="first_name"/>
            <TextInput source="email"/>
            <TextInput source="mobile"/>
            <SelectInput source="status" choices={[
                {id: 'pending', name: 'Pending'},
                {id: 'accepted', name: 'Accepted'},
                {id: 'blocked', name: 'Blocked'},
                {id: 'deleted', name: 'Deleted'},
            ]}/>
            {/*<BooleanInput source="email_verified"/>*/}
            {/*<BooleanInput source="mobile_verified"/>*/}
            <RadioButtonGroupInput row={false} source="rank" choices={[
                {id: 'scout', name: 'Cserkész'},
                {id: 'guardian', name: 'Őrsvezető'},
                {id: 'auxiliary', name: 'Segédtiszt'},
                {id: 'officer', name: 'Cserkésztiszt'},
                {id: 'adult', name: 'Felnőtt cserkész'},
            ]}/>
            <ArrayInput source="avatars">
                <SimpleFormIterator>
                    <TextInput source="url"/>
                </SimpleFormIterator>
            </ArrayInput>
            <ReferenceArrayInput source="department_ids" reference="departments">
                <SelectArrayInput optionText="title" />
            </ReferenceArrayInput>
            <ReferenceInput source="address_id" reference="addresses">
                <SelectInput optionText="id"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
