import {createSvgIcon} from '@material-ui/core/utils'

export default createSvgIcon(
    <svg id="Réteg_1" data-name="Réteg 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.86 224.86">
        <defs>
            <style>{`.cls-1{fill:none;}.cls-2{clip - path:url(#clip-path);}.cls-3{fill:#262d60;}`}</style>
            <clipPath id="clip-path" transform="translate(-15.57 -15.57)">
                <circle className="cls-1" cx="128" cy="128" r="112"/>
            </clipPath>
        </defs>
        <g className="cls-2">
            <rect className="cls-3" x="32.71" y="136.71" width="233" height="25"
                  transform="translate(-77.38 133.64) rotate(-45)"/>
            <rect className="cls-3" x="-9.71" y="94.29" width="233" height="25"
                  transform="translate(-59.8 91.22) rotate(-45)"/>
        </g>
    </svg>, 'OVScoutIcon');