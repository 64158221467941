import React from 'react';
import {TextField} from 'react-admin';
import {usePermission} from "../../providers/permissionProvider";
import {StatusToolbar} from "../../components/StatusToolbar";
import {MemberShow as MemberShowBase} from '../../components/members/MemberShow'


function ApplicationShowView(props: any) {
    const {hasPermission} = usePermission();

    return <>
        <TextField source="camp.name" {...props}/>
        <StatusToolbar
            {...props}
            status={(props: any) => {
                return props.recordOrigin?.status
            }}
            source="status"
            showToolbar={hasPermission('manage.applications') || hasPermission('manage.camp.applicants')}/>
    </>
        ;
}

export const ApplicationShow = (props: any) => {
    return (
        <MemberShowBase {...props} prefix="user">
            <ApplicationShowView {...props} />
        </MemberShowBase>
    );
};
