import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  CheckboxGroupInput,
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  EmailField,
  ImageInput,
  Labeled,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SingleFieldList,
  TextField
} from "react-admin";
import React, {ReactElement, ReactNode} from "react";
import TextInput from "../../../components/formInputs/TextInput";
import Avatar from "@material-ui/core/Avatar";
import get from 'lodash/get';
import {RankInput} from "../../../components/RankInput";
import {RankField} from "../../../components/RankField";
import {useTranslate} from "ra-core";

export enum FieldEnum {
  TEXT, DATE, REF_SELECT, FILE, RANK, EMAIL, BOOLEAN, AUTOCOMPLETE_ARRAY, CHECKBOX, REFARRAY
}


const ReferenceInputSelect = (props: any) => (<ReferenceInput {...props}><SelectInput
  {...props}/></ReferenceInput>);

// @ts-ignore
const CheckboxField = (props: { record, source, resource }) => {
  const t = useTranslate()
  const {
    record,
    source,
    resource
  } = props;
  const data = get(record, source);
  return (<ul>
    {data && data.map && data.map((item: { name: string } | string) => (     // @ts-ignore
      <li key={item.toString() === item ? item : item?.name}>
        {    // @ts-ignore
          t(`resources.${resource}.${source}.${item.toString() === item ? item : item?.name}`)}
      </li>))}
  </ul>)
};

CheckboxField.defaultProps = {addLabel: true};

const FieldTypes = {
  [FieldEnum.EMAIL]: {
    edit: TextInput,
    view: EmailField
  },
  [FieldEnum.RANK]: {
    edit: RankInput,
    view: RankField
  },
  [FieldEnum.TEXT]: {
    edit: TextInput,
    view: TextField
  },
  [FieldEnum.REF_SELECT]: {
    edit: ReferenceInputSelect,
    view: TextField
  },
  [FieldEnum.DATE]: {
    edit: DateInput,
    view: DateField
  },
  [FieldEnum.BOOLEAN]: {
    edit: BooleanInput,
    view: BooleanField
  },
  [FieldEnum.CHECKBOX]: {
    edit: CheckboxGroupInput,
    view: (props: any) => (<CheckboxField {...props}/>)
  },
  [FieldEnum.AUTOCOMPLETE_ARRAY]: {
    edit: ({
             optionText,
             ...props
           }: { optionText: string, source: string, reference: string, record: any, allowEmpty: boolean }) => (<ReferenceArrayInput {...props}>
      <AutocompleteArrayInput optionText={optionText}/>
    </ReferenceArrayInput>),
    view: ({
             optionText,
             ...props
           }: { optionText: string, source: string, reference: string, record: any, allowEmpty: boolean }) => {
      return (
      <ReferenceArrayField {...props} perPage={1000}>
          <SingleFieldList>
            <ChipField {...props}/>
          </SingleFieldList>  
        </ReferenceArrayField>

      )
    }
  },
  [FieldEnum.REFARRAY]: {
    view: ({
             optionText,
             children,
             source,
             label,
             ...props
           }: { optionText: string, source: string, reference: string, label: any, record: any, allowEmpty: boolean, children: ReactNode }) => {
            const refProps = {source, label, reference: props.reference, record: props.record, allowEmpty: props.allowEmpty};

            return (<ReferenceArrayField {...refProps}>
        <Datagrid {...refProps}>
          <DatagridView children={children}/>
        </Datagrid>
      </ReferenceArrayField>);

    },
    edit: ({
             optionText,
             children,
             ...props
           }: { optionText: string, source: string, reference: string, record: any, allowEmpty: boolean, children: ReactNode }) => {
      return <ReferenceArrayInput {...props}>
        <AutocompleteArrayInput optionText="title"/>
      </ReferenceArrayInput>
    }
  },
  [FieldEnum.FILE]: {
    edit: ImageInput,
    view: ({
             record,
             source
           }: any) => {
      if (record) {
        const src = get(record, source) || record;
        return <Avatar alt={`${record.last_name} ${record.first_name}`} src={src}
        />
      }
      return null;
    }
  },
}

function DatagridView(props: any) {
  // console.debug(props);
  return <>
    {// @ts-ignore
            props.children.map((c: React.FunctionComponentElement<{ source: string; reference: string; record: any; allowEmpty: boolean; }>, idx:any) => React.cloneElement(c, {...props, key: `${props.source}-${idx}-${props.record?.id||0}`}))
    }
    </>;
//   return           {// @ts-ignore
//     children.map((c: React.FunctionComponentElement<{ source: string; reference: string; record: any; allowEmpty: boolean; }>) => React.cloneElement(c, props))}
// ;
}

interface EditableFieldProps {
  fieldType?: FieldEnum,
  isEdit?: boolean,
  source: string,
  label?: string,
  optionText?: string,
  reference?: string,
  sourceName?: string,
  format?: string,
  prefix?: string,
  setEdit?: Function,
  validate?: any,
  selectedUser?: any,
  view?: ReactElement,
  edit?: ReactElement,
}

export const EditableField = ({
                                fieldType,
                                isEdit = false,
                                setEdit,
                                validate,
                                prefix,
                                selectedUser,
                                view,
                                edit,
                                ...props
                              }: EditableFieldProps) => {
  // return <></>;
  const source = (prefix ? `${prefix}.` : "") + (props.sourceName || props.optionText || props.source);
  const InputFiled = fieldType !== undefined ? FieldTypes[fieldType].edit : edit;
  const ViewField = fieldType !== undefined ? FieldTypes[fieldType].view : view;
  const fieldProps = {
    ...props,
    source
  };
  if (isEdit) {
    if (FieldEnum.FILE == fieldType) {
      // @ts-ignore
      return (<InputFiled {...fieldProps} validate={validate} fullWidth={true}><ViewField {...fieldProps}
                                                                                          source={source}/></InputFiled>);
    }
    // @ts-ignore
    return (<InputFiled {...fieldProps} fullWidth={true} validate={validate} /*classes={classes}*//>);
  } else {
    //if (fieldType !== undefined) {
    // @ts-ignore
    return (<Labeled {...fieldProps} fullWidth={true}><ViewField {...fieldProps} source={source}/></Labeled>);
    // }
    // return (
    //     <Labeled {...fieldProps} fullWidth={true}>
    //         {React.cloneElement(ViewField, {...fieldProps, source, ...(ViewField.props)})}
    //     </Labeled>);
  }
};
