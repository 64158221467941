import hungarianMessages from 'ra-language-hungarian';
import {mergeTranslations} from 'react-admin';


export default mergeTranslations(hungarianMessages, {
    components: {
        BaseData: {
            title: 'Alapadatok'
        }
    }, 'Your profile has been updated': 'A profilod frissült', backButton: 'Vissza', auth: {
        registration: 'Regisztráció', forgotten_password: 'Elfelejtett jelszó', username: 'E-mail cím',
    }, pos: {
        dashboard: {
            welcome: {
                title: ""
            }, new_camp_application: 'Elérhető táborok'
        }
    }, ra: {
        message: {
            forbidden: "Nincs jogosultságod a kért oldal megtekintéséhez",
        },
        
        page:{
            forbidden: "Hozzáférés megtagadva",
        },
        action: {
            impersonate: "Bejelentkezés mint ..."
        }, auth: {
            registration: 'Regisztráció', forgotten_password: 'Elfelejtett jelszó', username: 'E-mail cím',
        }, navigation: {
            skip_nav: ""
        }, tree: {
            add_node_after: 'Add node after',
            add_node_before: 'Add node before',
            add_child_node: 'Add child node',
            close: 'Close',

        }, validation: {
            password_confirmation_not_matching: 'A jelszó és ismétlése nem egyezik', email: 'Helytelen e-mail cím',
        }, message: {
            decline_title: "Jelentkezés elutasítása",
            decline_content: "Biztosan el akarod utasítani ezt a jelentkezést?"
        }
    }, rank: {
        none: 'Nem cserkész',
        cub: 'Kiscserkész',
        scout: 'Cserkész',
        guardian: 'Őrsvezető',
        auxiliary: 'Segédtiszt',
        officer: 'Cserkésztiszt',
        adult: 'Felnőtt cserkész',
        group_officer: 'Cserkészcsapat tiszt',
        parent: 'Szülő',
    }, status: {
        isLTC: {
            pending: "Függőben",
            accepted: "Elfogadva",
            acceptedByCommander: "Csapatparancsnok által elfogadva",
            acceptedByCampCommander: "I. Kerület VK tábor parancsnok által elfogadva",
            acceptedBySubCampCommander: "Altábor/VK parancsnok által elfogadva",
            declined: "Elutasítva",
        },
        action: {
            applicationAccept: "Jelentkezés megerősítése", applicationDecline: "Jelentkezés elutasítása",
        },
        pending: "Függőben",
        accepted: "Elfogadva",
        acceptedByCommander: "Parancsnok által elfogadva",
        acceptedByCampCommander: "Táborparancsnok által elfogadva",
        acceptedBySubCampCommander: "Altábor parancsnok által elfogadva",
        declined: "Elutasítva",
    }, archived: "Archivált", resources: {
        pages: {
            registration: {
                title: "KMCSSZ 1. Kerület - Európa"
            }
        },
        "camps/apply": {
            fields: {
                user: {
                    date_of_birth: 'Születési dátum',
                    place_of_birth: 'Születési hely',
                    last_name: 'Vezetéknév',
                    swimming: 'Tudok úszni',
                    first_name: 'Keresztnév',
                    department_ids: 'Szervezeti egység',
                    mobile: 'Mobil szám',
                    email: 'E-mail cím',
                    status: 'Státusz',
                    email_verified: 'E-mail cím ellenőrizve',
                    mobile_verified: 'Mobil szám ellenőrizve',
                    avatars: 'Avatárok',
                    address: 'Cím',
                    group: 'Csapat',
                    password: 'Jelszó',
                    password_confirmation: 'Jelszó - ismét',
                    isScout: 'Cserkész vagyok',
                    isParent: 'Szülő vagyok',
                    isDiaspora: 'Szórvány cserkész',
                    rank: 'Cserkész rang',
                    parent: 'Szülő',
                }
            }
        },
        media: {
            invitation: {
                title: '[%{campRef}] %{campName} - Meghívó (%{type})'
            }, general_information: {
                title: '[%{campRef}] %{campName} - Általános tudnivalók (%{type})'
            }, camp_regulations: {
                title: '[%{campRef}] %{campName} - Tábori szabályzat (%{type})',
                defaultValue: ''
            }, moral_statement: {
                title: '[%{campRef}] %{campName} - Adatkezelési és erkölcsi nyilatkozat (%{type})',
                defaultValue: 'Ezennel megerősítem, hogy egy államban sem követtem el bűncselekményt a szexuális önrendelkezés ellen, a gyermekpornográfiától való védelem ellen, az élet, vagy a testi épség védelme ellen, valamint nem követtem el bűncselekményt a gondoskodási és nevelési kötelezettségek megsértésével kapcsolatban, kiskorúak szexuális cselekedeteinek elősegítésével, prostituáltak kizsákmányolásával, prostituáltak futtatásával, exhibicionista cselekedetekkel, nyilvános felháborodást okozó tettekkel, pornografikus írások terjesztésével, erőszak- és állatpornográfia tartalmú írások terjesztésével, gyermek- és és ifjúsági pornográfiai írások terjesztésével, megszerzésével és birtoklásával, pornografikus tartalmak rádión, média- és teleszolgálatokon keresztüli terjesztésével, a tiltott prostitúció gyakorlásával, kiskorúakat veszélyeztető prostitúcióval, védelem alatt állókkal való visszaéléssel, a munkaerő kizsákmányolására irányuló emberkereskedelemmel, emberrablással, kiskorúak megszöktetésével, gyermekkereskedelemmel és hasonló tettekkel kapcsolatban és, ilyen, valamint hasonló bűncselekmények miatt egy államban sem hoztak ellenem jogerős ítéletet és nem tiltottak el idevonatkozó hivatali tevékenységtől.'
            }, equipment_list: {
                title: '[%{campRef}] %{campName} - Felszerelési lista (%{type})'
            }, others: {
                title: '[%{campRef}] %{campName} - %{title} (%{type})'
            },
        },
        medical_information: {
            type: {
                VACCINATION: 'Védőoltások',
                DISEASE: 'Betegségek',
                ALLERGY: 'Allergia',
                DOCTOR: 'Orvos',
                EATING_HABITS: 'Étkezés',
                MEDICINE: 'Állandó gyógyszerek',
                INSURANCE: "Biztosítás",
            },
            VACCINATION: {
                info: '<strong>Kérjük</strong> add meg lehetőségszerint az összes védőoltást amivel rendelkezel és egy tábor kapcsán fontos lehet!<br/>Pl.: Hepatitis A, Hepatitis B, TBE, Tetanus, FSME, stb.',
                fields: {
                    hasNot: "Nem rendelkezem oltással", name: "Oltás neve", valid_until: "Oltás időpontja",
                }
            },
            DISEASE: {
                fields: {
                    hasNot: "Nincs állandó betegségem", name: "Betegség neve", description: "Utasítás/Leírás",
                }
            },
            ALLERGY: {
                fields: {
                    hasNot: "Nincs allergiám", name: "Allergia neve", description: "Utasítás/Leírás",
                }
            },
            DOCTOR: {
                fields: {
                    name: "Kezelőorvos neve", hasNot: "Nincs kiemelt kezelőorvosom", description: "Elérhetőségei",
                }

            },
            EATING_HABITS: {
                fields: {
                    hasNot: "Nincs különleges étkezési szükségletem",
                    name: "Megnevezés",
                    description: "Utasítás/Leírás",
                }
            },
            MEDICINE: {
                fields: {
                    hasNot: "Nem kell rendszeresen gyógyszert szednem",
                    name: "Megnevezés",
                    description: "Utasítás/Leírás",
                }

            },
            INSURANCE: {
                fields: {
                    name: "Biztosító neve", description: "Kártya szám",
                }
            },
        },
        camps: {
            pageTitle: 'Tábor - %{campName}',
            pageTitleArchived: 'Archivált tábor - %{campName}',
            name: 'Táborok',
            commander: 'Tábor parancsnok',
            subCamps: 'Altáborok',
            apply: 'Jelentkezés',
            default_moral_statement_description: "Ezennel megerősítem, hogy egy államban sem követtem el bűncselekményt a\n" + "                szexuális önrendelkezés ellen, a gyermekpornográfiától való védelem ellen, az élet, vagy a testi\n" + "                épség védelme ellen, valamint nem követtem el bűncselekményt a gondoskodási és nevelési\n" + "                kötelezettségek megsértésével kapcsolatban, kiskorúak szexuális cselekedeteinek elősegítésével,\n" + "                prostituáltak kizsákmányolásával, prostituáltak futtatásával, exhibicionista cselekedetekkel,\n" + "                nyilvános felháborodást okozó tettekkel, pornografikus írások terjesztésével, erőszak- és\n" + "                állatpornográfia tartalmú írások terjesztésével, gyermek- és és ifjúsági pornográfiai írások\n" + "                terjesztésével, megszerzésével és birtoklásával, pornografikus tartalmak rádión, média- és\n" + "                teleszolgálatokon keresztüli terjesztésével, a tiltott prostitúció gyakorlásával, kiskorúakat\n" + "                veszélyeztető prostitúcióval, védelem alatt állókkal való visszaéléssel, a munkaerő kizsákmányolására\n" + "                irányuló emberkereskedelemmel, emberrablással, kiskorúak megszöktetésével, gyermekkereskedelemmel és\n" + "                hasonló tettekkel kapcsolatban és, ilyen, valamint hasonló bűncselekmények miatt egy államban sem\n" + "                hoztak ellenem jogerős ítéletet és nem tiltottak el idevonatkozó hivatali\n" + "                tevékenységtől.",
            default_camp_regulations_description: "Ezennel megerősítem, hogy a tábori szabályzatot elolvastam, vele egyet értek, valamint magamra és/vagy az általam jelntkeztetett családtagomra kötelező érvényűnek tekintem.",
            fields: {
                name: 'Tábor/Altábor neve',
                commander_id: 'Tábor parancsnok',
                'commander.id': 'Tábor parancsnok',
                'department.id': 'Szervezeti egység',
                'parent.id': 'Főtábor',
                start_at: 'Nyitás időpontja',
                finish_at: 'Zárás időpontja',
                moral_statement: 'Adatkezelési és erkölcsi nyilatkozat',
                camp_regulations: 'Tábori szabályzat',
                address: {
                    street: 'Utca',
                    street_extra: 'Utca - kiegészítő sor',
                    city: 'Város',
                    state: 'Állam/Megye/Provincia',
                    post_code: 'Irányítószám',
                    country_id: 'Ország',
                    note: 'Megjegyzés',
                }
            }
        },
        departments: {
            pageTitle: 'Csapat - %{title}', name: "Csapatok", groupName: 'Csapat név'
        },
        addresses: {
            name: 'Címek', fields: {
                'street': 'utca'
            }
        },
        countries: {
            name: 'Országok'
        },
        faqs: {
            name: 'FAQ elemek', fields: {
                slug: 'Felhasználóbarát URL',
                faqs: 'FAQ-k',
                question: 'Kérdés',
                answer: 'Válasz',
                total_read: 'Olvasva',
                helpful_yes: 'Segített',
                helpful_no: 'Nem segített',
                list_order: 'Sorrend',
                category: 'Kategória neve',
                category_id: 'Kategória neve'

            }
        },
        "faqs/categories": {
            name: 'FAQ kategóriák', fields: {
                category: 'Kategória neve',
                name: 'Kategória neve',
                slug: 'Felhasználóbarát URL',
                faqs: 'Kérdések - Válaszok',
                question: 'Kérdés',
                total_read: 'Olvasva',
                helpful_yes: 'Segített',
                helpful_no: 'Nem segített',
                list_order: 'Sorrend',

            }
        },
        applications: {
            name: "Jelentkezések",
            pageTitle: "Jelentkezés: %{last_name} %{first_name} - %{campName}",
            pageTitleArchived: "Arvhivált jelentkezés: %{last_name} %{first_name} - %{campName}",
            status: {
                undefined: "Függőben",
                pending: "Függőben",
                accepted: "Elfogadva",
                acceptedByCommander: "Parancsnok által elfogadva",
                acceptedByCampCommander: "Táborparancsnok által elfogadva",
                acceptedBySubCampCommander: "Altábor parancsnok által elfogadva",
                declined: "Elutasítva",
            },
            permissions: {
                'manage.camps': 'Tábor kezelése', 'manage.camp.applicants': 'Jelentkezők kezelése'
            },
            options: {
                join: {
                    atStart: "A tábor kezdetén csatlakozik", at: "Az alábbi napon csatlakozik a táborhoz"
                }
            },
            rank: {
                none: 'Nem cserkész',
                cub: 'Kiscserkész',
                scout: 'Cserkész',
                guardian: 'Őrsvezető',
                auxiliary: 'Segédtiszt',
                officer: 'Cserkésztiszt',
                adult: 'Felnőtt cserkész',
                parent: 'Szülő',
            },
            fields: {
                id: 'Azonosító',
                fullName: 'Név',
                date_of_birth: 'Születési dátum',
                place_of_birth: 'Születési hely',
                last_name: 'Vezetéknév',
                swimming: 'Tudok úszni',
                first_name: 'Keresztnév',
                department_ids: 'Szervezeti egység',
                mobile: 'Mobil szám',
                email: 'E-mail cím',
                status: 'Státusz',
                email_verified: 'E-mail cím ellenőrizve',
                mobile_verified: 'Mobil szám ellenőrizve',
                avatars: 'Avatárok',
                address: 'Cím',
                group: 'Csapat',
                password: 'Jelszó',
                password_confirmation: 'Jelszó - ismét',
                isScout: 'Cserkész vagyok',
                isParent: 'Szülő vagyok',
                isDiaspora: 'Szórvány cserkész',
                rank: 'Cserkész rang',
                rankNone: 'Nem cserkész',
                rankCub: 'Kiscserkész',
                rankScout: 'Cserkész',
                rankGuardian: 'Őrsvezető',
                rankAuxiliary: 'Segédtiszt',
                rankOfficer: 'Cserkésztiszt',
                rankAdult: 'Felnőtt cserkész',
                rankParent: 'Szülő',

                title: 'Megnevezés',
                is_commander: 'Vezető',
                departments: 'Szervezeti egység',
                camp_id: 'Tábor',
                camp: {
                    name: "Tábor neve", start_at: 'Nyitás időpontja', finish_at: 'Zárás időpontja'
                },
                user: {
                    last_name: 'Vezetéknév', first_name: 'Keresztnév',

                },
                allergyInformation: 'Allergiák',
                eatingHabitsInformation: 'Étkezés',
                vaccinationInformation: 'Védőoltások',
                vactinationInformation: 'Védőoltások',
                diseaseInformation: 'Betegségek',
                doctorInformation: 'Orvos',
                medicineInformation: 'Állandó gyógyszerek',
                insuranceInformation: 'Biztosítás',
                medicalInformation: 'Egészségügyi információk',

                regos_workgroups: 'Milyen munkacsoportokban szeretnél részt venni?',
                regos_instruments:"Milyen hangszeren játszol?",
                regos_full_outfit:"Van viseleted?",
                regos_own_intrument:"Van saját hangszered?",
                transfers_ask:"Segítséget kérek az tárohelyre szállításban",
                transfers_departure:"Állomásról",
                transfers_arrival:"Állomásra",
                
            

            }
        }, users: {
            name: "Felhasználók",
            pageTitle: "Felhasználó - %{last_name} %{first_name}",
            campApplicationPage: 'Jelentkezési lap',
            fields: {
                date_of_birth: 'Születési dátum',
                place_of_birth: 'Születési hely',
                last_name: 'Vezetéknév',
                swimming: 'Tudok úszni',
                first_name: 'Keresztnév',
                department_ids: 'Szervezeti egység',
                mobile: 'Mobil szám',
                email: 'E-mail cím',
                status: 'Státusz',
                email_verified: 'E-mail cím ellenőrizve',
                mobile_verified: 'Mobil szám ellenőrizve',
                avatars: 'Avatárok',
                address: 'Cím',
                group: 'Csapat',
                password: 'Jelszó',
                password_confirmation: 'Jelszó - ismét',
                isScout: 'Cserkész vagyok',
                isParent: 'Szülő vagyok',
                isDiaspora: 'Szórvány cserkész',
                rank: 'Cserkész rang',
                parent: 'Szülő',

            },
            contact: {
                info: 'A gyermekednél <strong>csak akkor</strong> adj meg email címet vagy telefonszámot\n' + '                        ha az a <strong>sajátja</strong>, amennyiben üresen hagyon, <strong>te</strong> kapsz minden\n' + '                        üzenetet!'
            }
        }, family: {
            name: "Családtagjaim",
            pageTitle: "Családtag - %{last_name} %{first_name}",
            campApplicationPage: 'Jelentkezési lap',
            empty: "Még nem regisztráltál egyetlen családtagodat sem!",
            invite: "Szeretnél regisztrálni egyet?",
            delete_content: 'Biztosan törölni akarod?',
            delete_title: 'Törölni készülsz a(z) %{first_name} nevű családtagod!',
            fields: {
                date_of_birth: 'Születési dátum',
                place_of_birth: 'Születési hely',
                last_name: 'Vezetéknév',
                swimming: 'Tudok úszni',
                first_name: 'Keresztnév',
                department_ids: 'Szervezeti egység',
                mobile: 'Mobil szám',
                email: 'E-mail cím',
                status: 'Státusz',
                title: 'Csapat név',
                is_commander: 'Parancsnok',
                email_verified: 'E-mail cím ellenőrizve',
                mobile_verified: 'Mobil szám ellenőrizve',
                avatars: 'Avatárok',
                address: 'Cím',
                group: 'Csapat',
                password: 'Jelszó',
                password_confirmation: 'Jelszó - ismét',
                isScout: 'Cserkész vagyok',
                isParent: 'Szülő vagyok',
                isDiaspora: 'Szórvány cserkész',
                rank: 'Cserkész rang',


            },
            rank: {
                none: 'Nem cserkész',
                cub: 'Kiscserkész',
                scout: 'Cserkész',
                guardian: 'Őrsvezető',
                auxiliary: 'Segédtiszt',
                officer: 'Cserkésztiszt',
                group_officer: 'Cserkészcsapat tiszt',
                adult: 'Felnőtt cserkész',
                parent: 'Szülő',
            },
            status: {
                undefined: "Függőben",
                pending: "Függőben",
                accepted: "Elfogadva",
                acceptedByCommander: "Parancsnok által elfogadva",
                acceptedByCampCommander: "Táborparancsnok által elfogadva",
                acceptedBySubCampCommander: "Altábor parancsnok által elfogadva",
                declined: "Elutasítva",
            },
        }, members: {
            name: "Csapattagok",
            pageTitle: "Csapattag: %{last_name} %{first_name}",
            campApplicationPage: 'Jelentkezési lap',
            fields: {
                swimming: 'Tudok úszni',
                fullName: 'Név',
                date_of_birth: 'Születési dátum',
                place_of_birth: 'Születési hely',
                last_name: 'Vezetéknév',
                first_name: 'Keresztnév',
                department_ids: 'Szervezeti egység',
                mobile: 'Mobil szám',
                email: 'E-mail cím',
                status: 'Státusz',
                email_verified: 'E-mail cím ellenőrizve',
                mobile_verified: 'Mobil szám ellenőrizve',
                avatars: 'Avatárok',
                address: 'Cím',
                group: 'Csapat',
                password: 'Jelszó',
                password_confirmation: 'Jelszó - ismét',
                isScout: 'Cserkész vagyok',
                isParent: 'Szülő vagyok',
                isDiaspora: 'Szórvány cserkész',
                rank: 'Cserkész rang',
                rankNone: 'Nem cserkész',
                rankCub: 'Kiscserkész',
                rankScout: 'Cserkész',
                rankGuardian: 'Őrsvezető',
                rankAuxiliary: 'Segédtiszt',
                rankOfficer: 'Cserkésztiszt',
                rankAdult: 'Felnőtt cserkész',
                rankParent: 'Szülő',
                title: 'Megnevezés',
                is_commander: 'Vezető',
                departments: 'Szervezeti egység',
            },
            rank: {
                none: 'Nem cserkész',
                cub: 'Kiscserkész',
                scout: 'Cserkész',
                guardian: 'Őrsvezető',
                auxiliary: 'Segédtiszt',
                officer: 'Cserkésztiszt',
                adult: 'Felnőtt cserkész',
                parent: 'Szülő'
            },
            status: {
                accepted: 'Elfogadva', pending: 'Függőben',
            }
        }, faq: {
            send: 'Küld',
            sentSuccessfully: 'Kérdésedet mentettük, hamarosan válaszolunk rá! (5-7 nap)',
            sendNew: 'Új kérdést szeretnék feltenni',
            fields: {
                name: 'Neved', email: 'E-mail címed', question: 'Kérdésed (több soros is lehet)',
            }
        }
        
    },
    regos: {
        workgroups: 'Milyen munkacsoportokban szeretnél részt venni?',
        instruments:"Milyen hangszeren játszol?",
        full_outfit:"Van viseleted?",
        own_intrument:"Van saját hangszered?",
    },
    transfers:{
        ask:"Segítséget kérek az tárohelyre szállításban",
    	departure:"Állomásról",
        arrival:"Állomásra",
    }

});
