import * as React from "react";
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Edit,
    ImageField,
    ImageInput,
    ReferenceArrayInput,
    ReferenceInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import {useTranslate} from "ra-core";
import { usePermission } from "../../providers/permissionProvider";
import { Permissions } from "../../components/Permissions";

const EditTitle = (props: any) => {
    const {
        record,
        resource
    } = props;
    const t = useTranslate();
    return <>{t(`resources.${resource}.pageTitle`, {
        ...record
    })}</>;
};
export const DepartmentEdit = (props: any) => {
    const {id} = props;
    const {loaded, hasPermission} =  usePermission();
    if(!loaded){
        return null;
    }

    if (!hasPermission('manage.departments')) {
        // redirect to show page
        props.history.push("/departments/" + id + '/show');
        return null;
    }
    return (<Edit {...props} title={<EditTitle {...props}/>}>
            <SimpleForm>
                <TextInput source="title"/>

                <ReferenceInput source="parent_id" reference="departments" allowEmpty={true}
                                filterToQuery={searchText => ({
                                    q: searchText,
                                    exclude: [id]
                                })}>
                    <AutocompleteInput optionText="title"/>
                </ReferenceInput>

                <ReferenceArrayInput source="commander_ids" reference="users" perPage={1000} allowEmpty>
                    <AutocompleteArrayInput
                        optionText={(row: any) => row ? `${row?.last_name} ${row?.first_name} ` : ''}/>
                </ReferenceArrayInput>
                <ImageInput source="media[header]" label="Fejléc" accept="image/*">
                    <ImageField source="url" title="Fejléc"/>
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
};
