import {Record} from "ra-core";
import {get as getByPath} from "lodash";
import {Box, Typography} from "@material-ui/core";
import {BooleanInput, FormDataConsumer, RadioButtonGroupInput} from "react-admin";
import React from "react";

interface CustomCampProps {
    record: Record,
    camp: Record,
}

// @ts-ignore
export function StartLater({
                               record,
                               camp
                           }: CustomCampProps) {
    // @ts-ignore
        const startOptions = getByPath(camp, 'options.starts', []).filter(Boolean).map(({start_at}) => {
        return {
            id: start_at,
            name: start_at
        };
    })

    if (startOptions.length > 1) {
        return <Box p="1em">
            <Typography variant="h6">Tábori részvétel:</Typography>
            <BooleanInput source="options.start_available" label="Teljes időtartam"/>
            <FormDataConsumer>
                {({
                      formData,
                      ...rest
                  }) => !formData.options?.start_available &&
                    <RadioButtonGroupInput source="options.start" choices={startOptions} fullWidth={true}
                                           label={""}/>}
            </FormDataConsumer>

        </Box>
    }

    return null;
}
