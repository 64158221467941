import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChildrenIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M 7 2 A 2.5 2.5 0 0 0 4.5 4.5 A 2.5 2.5 0 0 0 7 7 A 2.5 2.5 0 0 0 9.5 4.5 A 2.5 2.5 0 0 0 7 2 z M 17 2 A 2.5 2.5 0 0 0 14.5 4.5 A 2.5 2.5 0 0 0 17 7 A 2.5 2.5 0 0 0 19.5 4.5 A 2.5 2.5 0 0 0 17 2 z M 5 8 C 3.9 8 3 8.89 3 10 L 3 17 L 4 17 L 4 20 C 4 20.552 4.448 21 5 21 C 5.552 21 6 20.552 6 20 L 6 17 L 8 17 L 8 20 C 8 20.552 8.448 21 9 21 C 9.552 21 10 20.552 10 20 L 10 17 L 11 17 L 11 10 C 11 8.89 10.1 8 9 8 L 5 8 z M 15.75 8 C 14.87 8 14.080078 8.5992188 13.830078 9.4492188 L 11.669922 17 L 14 17 L 14 20 C 14 20.552 14.448 21 15 21 C 15.552 21 16 20.552 16 20 L 16 17 L 18 17 L 18 20 C 18 20.552 18.448 21 19 21 C 19.552 21 20 20.552 20 20 L 20 17 L 22.330078 17 L 20.169922 9.4492188 C 19.919922 8.5992188 19.13 8 18.25 8 L 15.75 8 z"></path>
        </SvgIcon>
    );
}

