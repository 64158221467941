import {Layout} from 'react-admin';
import Menu from './Menu';
import {MyAppBar} from "./UserMenu";
import {ProfileProvider} from "../providers/profileProvider";
import {withStore} from 'react-context-hook';

const ScoutLayout = (props: any) => (
    <ProfileProvider>
        <Layout {...props} menu={Menu} appBar={MyAppBar}/>
    </ProfileProvider>
);

export default withStore(ScoutLayout);