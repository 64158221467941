export const isEmpty = (value: any) =>
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0);


export const sanitizeProps = (props: any) => {
    const {isEdit, setEdit, record, resource, selectedUser, prefix} = props;
    return {isEdit, setEdit, record, resource, selectedUser, prefix};
}