// @ts-ignore
import {get as getByPath} from "lodash";
import {Box, Typography} from "@material-ui/core";
import {BooleanInput, FormDataConsumer, RadioButtonGroupInput} from "react-admin";
import React from "react";
import {Record} from "ra-core";

interface CustomCampProps {
    record: Record,
    camp: Record,
}

export const TransferBox = ({
                                record,
                                camp
                            }: CustomCampProps) => {
    function buildOptions() {
        const transfers = getByPath(camp, 'options.transfers', []);

        const transferOptions = transfers.filter(Boolean).map((transfer: { station: any; }) => {
            return {
                id: transfer.station,
                name: transfer.station
            };
        })
        if (transferOptions.length) {
            transferOptions.unshift({
                id: 0,
                name: 'Nem kell'
            });

        }
        return transferOptions;
    }

    const transferOptions = buildOptions();

    if (transferOptions.length) {
        return <Box p="1em">
            <Typography variant="h6">Utazás (fuvar az állomásról / állomásra):</Typography>
            <BooleanInput source="options.transfers.ask" label="Segítséget kérek" initialValue={true}/>
            <FormDataConsumer>
                {({formData}) => formData.options?.transfers?.ask && <>
                    <RadioButtonGroupInput source="options.transfers.arrival"
                                           choices={transferOptions}
                                           fullWidth={true}
                                           label={"Kérem, az alábbi állomásról vegyenek fel"}
                                           record={record}/>
                    <RadioButtonGroupInput source="options.transfers.departure" choices={transferOptions}
                                           fullWidth={true}
                                           label={"Kérem, az alábbi állomásral vigyenek ki"} record={record}/>
                </>}
            </FormDataConsumer>
        </Box>
    }

    return null;
}
