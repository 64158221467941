import React from 'react';
import {ReferenceField, Show, SimpleShowLayout, TextField,} from 'react-admin';
import { usePermission } from '../../providers/permissionProvider';
import { Permissions } from '../../components/Permissions';

export const DepartmentShow = (props: any) => {
    const {loaded, hasPermission} =  usePermission();
    if(!loaded){
        return null;
    
    }
    else if (!hasPermission('manage.departments')) {
        // redirect to show page
        props.history.push("/forbidden");
        return null;
    }

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="title"/>
                <ReferenceField source="parent_id" reference="departments"><TextField source="title"/></ReferenceField>
            </SimpleShowLayout>
        </Show>
    )
};
