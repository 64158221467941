import {makeStyles} from "@material-ui/core/styles";
import ContentSave from "@material-ui/icons/Save";
import {useTranslate} from "ra-core";
import {Button} from "react-admin";
import classnames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as React from "react";
import {cloneElement} from "react";
import {sanitizeButtonRestProps} from "./grid/RowForm";

const useStyles = makeStyles(
    theme => ({
        button: {
            position: 'relative',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        icon: {
            fontSize: 18,
        },
    }),
    {name: 'RaSaveButton'}
);
const defaultIcon = <ContentSave/>;

const SubmitIconButton = (props: any) => {
    const {
        className,
        classes: classesOverride,
        invalid,
        label = 'ra.action.save',
        disabled,
        redirect,
        saving,
        submitOnEnter,
        variant = 'contained',
        icon = defaultIcon,
        onClick,
        handleSubmitWithRedirect,
        onSave,
        onSuccess,
        onFailure,
        transform,
        ...rest
    } = props;

    const classes = useStyles(props);
    const translate = useTranslate();
    const type = submitOnEnter ? 'submit' : 'button';
    const displayedLabel = label && translate(label, {_: label});

    return (
        <Button
            className={classnames(classes.button, className)}
            variant={variant}
            type={type}
            onClick={onClick}
            color={saving ? 'default' : 'primary'}
            aria-label={displayedLabel}
            disabled={disabled}
            {...sanitizeButtonRestProps(rest)}
        >
            {saving ? (
                <CircularProgress
                    size={18}
                    thickness={2}
                    className={classes.leftIcon}
                />
            ) : (
                cloneElement(icon, {
                    className: classnames(classes.leftIcon, classes.icon),
                })
            )}
        </Button>)

}
export default SubmitIconButton;