import * as React from 'react';
import {AnchorHTMLAttributes, FC, memo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AvatarField from './AvatarField';
import {fieldPropTypes, InjectedFieldProps, PublicFieldProps} from "ra-ui-materialui/lib/field/types";
import {useRecordContext} from "ra-core";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
        marginTop: -theme.spacing(0.5),
        marginBottom: -theme.spacing(0.5),
    },
}));

export interface FullNameFieldProps
    extends PublicFieldProps,
        InjectedFieldProps,
        AnchorHTMLAttributes<HTMLAnchorElement> {
    size?: string;
    withAge?: boolean;
}

// @ts-ignore
const FullNameField: FC<FullNameFieldProps> = (props) => {
    const {className, source, emptyText, size, withAge, ...rest} = props;
    const record = useRecordContext(props);
    const classes = useStyles();
    const rowdata = (Object.keys(record).includes('last_name')) ? record : record.user;
    const fullName = `${rowdata.last_name} ${rowdata.first_name}`;
    // calculate age from rowdata.date_of_birth

    if(withAge){
        const age = (dateString: string) => {   
            const today = new Date();
            const birthDate = new Date(dateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            if(age < 0 || age > 100 || isNaN(age)){
                return '';
            }
            return age;
        };
            return record ? (
            <div className={classes.root}>
                <AvatarField
                    className={classes.avatar}
                    record={rowdata}
                    size={size}
                />
                {fullName}
                <span style={{marginLeft: '10px'}}>{age(rowdata.date_of_birth)}</span>
            </div>
        ) : null;
    
    }
    if (fullName)
        return record ? (
            <div className={classes.root}>
                <AvatarField
                    className={classes.avatar}
                    record={rowdata}
                    size={size}
                />
                {fullName}
            </div>
        ) : null;
};

FullNameField.propTypes = fieldPropTypes;

FullNameField.defaultProps = {
    source: 'last_name',
    label: 'resources.customers.fields.name',
    addLabel: true,
};

export default memo<FullNameFieldProps>(FullNameField);