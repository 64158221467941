// in src/Menu.js
import * as React from 'react';
import {FC} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Theme, useMediaQuery} from '@material-ui/core';
import {DashboardMenuItem, getResources, MenuItemLink, MenuProps} from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import {usePermission} from "../providers/permissionProvider";
import {ReduxState, Translate, useTranslate} from "ra-core";
import {makeStyles} from "@material-ui/core/styles";
import lodashGet from 'lodash/get';
import classnames from 'classnames';
import inflection from 'inflection';
import {useProfile} from "../providers/profileProvider";
import get from "lodash/get";
import GoogleDriveVideoField, { GoogleDriveVideoMenuItem } from './GoogleDriveVideo';

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
    theme => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: '0.5em',
            [theme.breakpoints.only('xs')]: {
                marginTop: 0,
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '1.5em',
            },
        },
        open: {
            width: lodashGet(theme, 'menu.width', MENU_WIDTH),
        },
        closed: {
            width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
        },
    }),
    {name: 'RaMenu'}
);
const translatedResourceName = (resource: any, translate: Translate) =>
    translate(`resources.${resource.name}.name`, {
        smart_count: 2,
        _:
            resource.options && resource.options.label
                ? translate(resource.options.label, {
                    smart_count: 2,
                    _: resource.options.label,
                })
                : inflection.humanize(inflection.pluralize(resource.name)),
    });

const Menu: FC<MenuProps> = props => {
    const {
        classes: classesOverride,
        className,
        dense,
        hasDashboard,
        onMenuClick,
        logout,
        ...rest
    } = props;
    const translate = useTranslate();
    const classes = useStyles(props);
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources, shallowEqual) as Array<any>;
    // Used to force redraw on navigation
    useSelector((state: ReduxState) => state.router.location.pathname);
    const {
        hasPermission,
        loaded
    } = usePermission();
    // @ts-ignore
    const {profileVersion} = useProfile();
    return (
        <div
            className={classnames(
                classes.main,
                {
                    [classes.open]: open,
                    [classes.closed]: !open,
                },
                className
            )}
            {...rest}
            key={`profileVersion-${profileVersion}`}
        >
            {hasDashboard && (
                <DashboardMenuItem
                    onClick={onMenuClick}
                    dense={dense}
                    sidebarIsOpen={open}
                />
            )}
            {resources
                .filter(r => r.hasList)
                .filter(r => {
                    const hasListPermission = get(r, 'options.list.permissions');
                    if (hasListPermission) {
                        return hasListPermission(r, hasPermission)
                    }
                    return hasPermission(`manage.${r.name.replace(/\/.*?$/, '')}`);
                }/*||hasPermission(`manage.${r.name.replace(/\//, '.')}`)||hasPermission(r.options?.permissions)*/)


                .map(resource => {
                    return (<MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={translatedResourceName(
                            resource,
                            translate
                        )}
                        leftIcon={
                            resource.icon ? <resource.icon/> : <DefaultIcon/>
                        }
                        onClick={onMenuClick}
                        dense={dense}
                        sidebarIsOpen={open}
                    />) || null
                })}
            <GoogleDriveVideoMenuItem id="1NxXENWTdQyCgK2wGhKcDZx9FjrUwMw_H" label="Videó segéd"/>

            {/*<MenuItemLink*/}
            {/*    key="FAQ"*/}
            {/*    to={`/faq`}*/}
            {/*    primaryText="GY.I.K. / FAQ"*/}

            {/*    leftIcon={<QuestionAnswerIcon/>}*/}
            {/*    onClick={onMenuClick}*/}
            {/*    dense={dense}*/}
            {/*    sidebarIsOpen={open}*/}
            {/*/>*/}
            {isXSmall && logout}
        </div>
    );
};


/*
MenuItemLink to="/custom-route" primaryText=
{
    <Badge badgeContent={4} color="primary">
        Notifications
    </Badge>
}
onClick=
{
    onMenuClick
}
/>
*/

export default Menu;
