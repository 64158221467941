class Node<T> {
    from: string;
    callback: (record: T, hasPermission?: Function, identity?: any) => any;

    constructor(from: string, to: string, callback: (record: T, hasPermission?: Function, identity?: any) => any) {
        this.from = from;
        this._to = to;
        this.callback = callback;
    }

    private _to: string;

    get to(): string {
        return this._to;
    }
}
export default Node;