import * as React from 'react';
import {
    ArrayField,
    BooleanField,
    DateField, EditButton,
    EmailField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    useListContext,
    useGetIdentity,
    usePermissions,
    useRecordContext,
    useShowContext,
    useResourceDefinition,
    useTranslate,
    useEditContext,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Datagrid
} from 'react-admin';
import {Avatar} from '@material-ui/core';
import {StatusField} from "../../components/StatusField";
import {Permissions} from "../../components/Permissions";
import {usePermission} from "../../providers/permissionProvider";
import {EditActionsProps, ShowButton, TopToolbar} from "ra-ui-materialui";
import ImpersonateButton from "../../components/ImpersonateButton";
import { RankField } from '../../components/RankField';
import AvatarField from '../../components/AvatarField';


const cardStyle = {
    width: 300,
    minHeight: 300,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
};
const AvatarGrid = () => {
    const {ids, data, basePath} = useListContext();
    return (
        <div style={{margin: '1em'}}>
            {ids.map(id => (
                    <div key={`avatar-{$id}`}><Avatar src={data[id].url}/></div>
                )
            )}
        </div>
    );
};
const MediaGrid = () => {
    const {ids, data, basePath} = useListContext();

    return (
        <div style={{margin: '1em'}}>
            {ids.map(id => (
                    <div key={`media-{$data[id].id}`}><Avatar src={data[id].url}/></div>
                )
            )}
        </div>
    );
};
const Title = (props: any) => {
    const t = useTranslate();
    const {resource} = props;

    return <>{t(`resources.${resource}.pageTitle`, {
        first_name: props.record.first_name,
        last_name: props.record.last_name,
    })}</>;

}

const ShowActions = ({className, ...rest}: EditActionsProps) => {
    const sanitizeRestProps = ({
                                   basePath = null,
                                   hasCreate = null,
                                   hasEdit = null,
                                   hasShow = null,
                                   hasList = null,
                                   ...rest
                               }) => rest;

    const {basePath, record} = useEditContext(rest);
    const {hasEdit} = useResourceDefinition(rest);
    const {hasPermission} = usePermission();
    const canImpersonate = hasPermission('impersonate');
    const canManageUsers = hasPermission(Permissions.MANAGE_USERS)
    return (
        <TopToolbar>
            {hasEdit && canManageUsers && <EditButton basePath={basePath} record={record}/>}
            {canImpersonate && <ImpersonateButton basePath={basePath} record={record}/>}
        </TopToolbar>
    );
};


export const UserShow = (props: any) => {
    const record = useRecordContext(props);
    const {identity} = useGetIdentity();
    const {hasPermission} = usePermission();

    const actions = (hasPermission(Permissions.MANAGE_USERS) || hasPermission(Permissions.SUPERADMIN)) ? undefined : false;

    const isCommnderOf = identity?.commanderOf;
    console.debug({isCommnderOf})


    return (<Show {...props} title={<Title {...props}/>} actions={<ShowActions {...props}/>}>
        <UserShowContent {...props}/>
    </Show>)
};

const UserShowContent = (props) => {
    const {identity} = useGetIdentity();

    const {record} = useShowContext(props);
    const {hasPermission} = usePermission();

    
    const isCommnderOf = identity?.commanderOf?.some((id) => record?.department_ids?.includes(id));

    // commander or superadmin can see more fields
    if (isCommnderOf||hasPermission(Permissions.SUPERADMIN)) {
        return (<SimpleShowLayout {...props}>
            <AvatarField source="avatar"  addLabel={true}/>
            <TextField source="first_name"/>
            <TextField source="last_name"/>
            <TextField source="email"/>
            <TextField source="mobile"/>
            <DateField source="date_of_birth"/>
            <TextField source="place_of_birth"/>
            <ReferenceArrayField source="department_ids" reference="departments">
                <Datagrid >
                    <ChipField source="title"/>
                    <StatusField source="status" parser={(r)=>{
                        return (record?.departments||[]).find((d)=>d.id===r.id)?.status
                        }}/>
                </Datagrid>
            </ReferenceArrayField>
            <RankField source="rank" addLabel={true}/>
            <DateField source="created_at"/>
            <DateField source="updated_at"/>
        </SimpleShowLayout>)
    // regular user
    }else{
        return (
        <SimpleShowLayout {...props}>
            <AvatarField source="avatar"  addLabel={true}/>
            <TextField source="first_name"/>
            <TextField source="last_name"/>
            <RankField source="rank" addLabel={true}/>
        </SimpleShowLayout>)

}
return null;
}

