import * as React from "react";
import {Form} from "react-final-form";
import {TableCell, TableRow} from '@material-ui/core';
import SubmitIconButton from "../SubmitIconButton";
import {Button} from "react-admin";

export const sanitizeButtonRestProps = ({
                                            // The next props are injected by Toolbar
                                            basePath,
                                            handleSubmit,
                                            handleSubmitWithRedirect,
                                            invalid,
                                            onSave,
                                            pristine,
                                            record,
                                            redirect,
                                            resource,
                                            saving,
                                            submitOnEnter,
                                            undoable,
                                            ...rest
                                        }: any) => rest;

function EditableTableRow({
                              className,
                              children,
                              element,
                              onClick,
                              onClick1,
                              withNewForm = false
                          }: {
    className: any,
    children: any,
    element: (child: React.ReactNode) => JSX.Element,
    onClick?: (event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>) => (Promise<any | undefined> | undefined),
    onClick1?: any,
    withNewForm?: boolean
}) {

    return <TableRow className={className}>
        {children.map(element
        )}
        {withNewForm && <TableCell>
            <SubmitIconButton label={""} onClick={onClick}/>
            <Button variant="contained" color="primary" onClick={onClick1} label="cancel"></Button>
        </TableCell>}

    </TableRow>;
}

export const RowForm = (props: any) => {
    const {
        children,
        record,
        id,
        className,
        quitEditMode,
        selectable,
        basePath,
        resource,
        save,
        saving,
        selected,
        undoable,
        form,
        recordTemplate,
        ...rest
    } = props;

    return (<EditableTableRow key={id} className={className} children={children}
                              withNewForm={false}
                              element={(child: React.ReactNode) => (
                                  <TableCell>
                                      {child}
                                  </TableCell>)} onClick1={quitEditMode}/>
    );
    return (
        <Form
            initialValues={record}
            onSubmit={save}
            {...rest}
            render={({handleSubmit, invalid, dirty}) => {
                return (
                    <EditableTableRow key={id} className={className} children={children}
                                      withNewForm={true}
                                      element={(child: React.ReactNode) => (
                                          <TableCell>
                                              {child}
                                          </TableCell>)} onClick={handleSubmit} onClick1={quitEditMode}/>
                )
            }}
        />
    );
}

export default RowForm;