import {useTranslate} from "ra-core";
import {MedicalInformationType as MIType} from "../../types";
import {SimpleShowLayout, TextField} from "react-admin";
import {Typography} from "@material-ui/core";
import {MedicalInformationShow} from "./MedicalInformationShow";
import React from "react";

export const MedicalInformation = (props: any) => {
    const t = useTranslate();
    return (

        <SimpleShowLayout {...props}>
            <Typography
                variant="h6"
                component="span"
                align="center"
            >
                Egészségügyi információk
            </Typography>

            <TextField source="medical_information.INSURANCE.name"
                       label={t(`resources.medical_information.INSURANCE.fields.name`)}/>
            <TextField source="medical_information.INSURANCE.description"
                       label={t(`resources.medical_information.INSURANCE.fields.description`)}/>

            <MedicalInformationShow {...props} type={MIType.VACCINATION}/>
            <MedicalInformationShow {...props} type={MIType.DISEASE}/>
            <MedicalInformationShow {...props} type={MIType.ALLERGY}/>
            <MedicalInformationShow {...props} type={MIType.DOCTOR}/>
            <MedicalInformationShow {...props} type={MIType.EATING_HABITS}/>
            <MedicalInformationShow {...props} type={MIType.MEDICINE}/>
        </SimpleShowLayout>);
}
