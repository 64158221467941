import {EditableComponent} from "../EditableComponent";
import Avatar from "@material-ui/core/Avatar";
import {EditableField, FieldEnum} from "../../models/users/components/EditableField";
import React from "react";
import {Parent} from "./Parent";

export const PersonalInformation = (props: any) => <EditableComponent {...props} label='Személyes információk'>
    <Avatar src={props.record.avatar}/>
    <EditableField fieldType={FieldEnum.TEXT} source="last_name"/>
    <EditableField fieldType={FieldEnum.TEXT} source="first_name"/>
    <EditableField fieldType={FieldEnum.DATE} source="date_of_birth"/>
    <EditableField fieldType={FieldEnum.TEXT} source="place_of_birth"/>
    <EditableField fieldType={FieldEnum.EMAIL} source="email"/>
    <EditableField fieldType={FieldEnum.TEXT} source="mobile"/>
    <Parent {...props}/>
</EditableComponent>;