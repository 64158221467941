import {ApplicationRecord, CampRecord, UserRecord} from "../../models/RecordTypes";
import {useTranslate} from "ra-core";
import {RankField} from "../RankField";
import React from "react";

export const Title = (props: { resource: string, record: ApplicationRecord, user: UserRecord, camp: CampRecord }) => {
    const t = useTranslate();
    const {
        user,
        record,
        camp,
        resource
    } = props;
    if (camp && user) {
        return <>{t(`resources.${resource}.pageTitle`, {
            first_name: user.first_name,
            last_name: user.last_name,
            campName: camp.parent ? `${camp.parent.name} - ${camp.name}` : camp.name,
        })}</>;
    }
    return <>{t(`resources.${resource}.pageTitle`, props.record)}<RankField source="rank" {...props} /></>;
}