import * as React from "react";
import {Datagrid, List, ReferenceField, TextField} from 'react-admin';
import { usePermission } from "../../providers/permissionProvider";
import { Permissions } from "../../components/Permissions";

export const DepartmentList = (props:any) => {
    const {loaded, permissions, hasPermission} =  usePermission();
    if(!loaded){
        return null;
    }

    if(!hasPermission("manage.departments")){
        debugger;
        props.history.push("/forbidden");
    }
    return (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="title" />
            <ReferenceField source="parent_id" reference="departments"><TextField source="title" /></ReferenceField>
        </Datagrid>
    </List>
)};
