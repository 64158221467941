import ApplicationStates from "./ApplicationStates";
import {ApplicationRecord} from "./ApplicationRecord";
import Node from "../../components/statemachine/Node";

export default class ApplicationStateMachine {

    static nodes: Node<ApplicationRecord>[] = [
        new Node<ApplicationRecord>(ApplicationStates.PENDING, ApplicationStates.ACCEPTED_BY_COMMANDER, (record) => {
            return record.currentIsGroupCommander
        }),
        new Node<ApplicationRecord>(ApplicationStates.PENDING, ApplicationStates.ACCEPTED_BY_SUB_CAMP_COMMANDER, (record) => {
            return record.currentIsSubCampCommander && record.user?.department_ids?.length === 0;
        }),
        new Node<ApplicationRecord>(ApplicationStates.ACCEPTED_BY_COMMANDER, ApplicationStates.ACCEPTED_BY_SUB_CAMP_COMMANDER, (record) => {
            return record.currentIsSubCampCommander
        }),
        new Node<ApplicationRecord>(ApplicationStates.ACCEPTED_BY_COMMANDER, ApplicationStates.ACCEPTED_BY_CAMP_COMMANDER, (record) => {
            return record.currentIsCampCommander
        }),
        new Node<ApplicationRecord>(ApplicationStates.ACCEPTED_BY_SUB_CAMP_COMMANDER, ApplicationStates.ACCEPTED_BY_CAMP_COMMANDER, (record) => {
            return record.currentIsCampCommander
        })
    ];
    static next = (status: string, record: ApplicationRecord): string | undefined => {
        const next = ApplicationStateMachine.nodes.filter((node) => {
            return node.from === status && node.callback(record);
        }).shift();
        return (next && next.to) || undefined;
    }
}
