import {Identifier} from "ra-core";
import {useState} from "react";
import {useStore, useStoreState} from 'react-context-hook';
import {UserRecord} from "../models/RecordTypes";

export interface PropsWithPermissions {
    permissions: string[]
}


export interface UserMedicalInformationType {
    id: Identifier

    [key: string]: any;
}


export interface SelectedUserState {
    id: Identifier;
    userId?: number;
    medical_information: {
        ALLERGY: UserMedicalInformationType[]
        DISEASE: UserMedicalInformationType[]
        DOCTOR: UserMedicalInformationType[]
        EATING_HABITS: UserMedicalInformationType[]
        INSURANCE?: UserMedicalInformationType
    }
    //[key: string]: any;
}

export function useSelectedUser(props?: { record: any }): { setSelectedUser: (data:any) => void; selectedUser: {} } {
    //const hasUserRecord = props && props?.record.first_name;
    const [selectedUser, setSelectedUser] = useState({});
    return {
        selectedUser,
        // @ts-ignore
        setSelectedUser: (data) => {
            //console.debug({setSelectedUser: data});
            return setSelectedUser(data)
        }
    };
}


// Hook
function useLocalStorage<T>(key: string, initialValue: T) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state

            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue] as const;
}