import * as React from 'react';
import {createElement, FC} from 'react';
import {Box, Card, Divider, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';

import cartouche from './cartouche.png';
import cartoucheDark from './cartoucheDark.png';

interface Props {
    icon: FC<any>;
    to?: string;
    title?: string;
    subtitle?: string | number;
}

const useStyles = makeStyles(theme => ({
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    main: () => ({
        overflow: 'inherit',
        padding: 16,
        background: `url(${
            theme.palette.type === 'dark' ? cartoucheDark : cartouche
        }) no-repeat`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440',
        },
    }),
    title: {},
}));

class CardWithIconContent extends React.Component<{ classes: { main:any, title:any }, type: React.FunctionComponent<any>, title: string | undefined, subtitle: string | number | undefined }> {
    render() {
        return <div className={this.props.classes.main}>
            <Box width="3em" className="icon">
                {createElement(this.props.type, {fontSize: "large"})}
            </Box>
            <Box textAlign="right">
                <Typography
                    className={this.props.classes.title}
                    color="textSecondary"
                >
                    {this.props.title}
                </Typography>
                <Typography variant="h5" component="h2">
                    {this.props.subtitle || " "}
                </Typography>
            </Box>
        </div>;
    }
}

const CardWithIcon: FC<Props> = props => {
    // @ts-ignore
    const {icon, title, subtitle, to, children} = props;
    const classes = useStyles(props);
    return (
        <Card className={classes.card}>
            {to ? <Link to={to}>
                <CardWithIconContent classes={classes} type={icon} title={title} subtitle={subtitle}/>
            </Link> : <CardWithIconContent classes={classes} type={icon} title={title} subtitle={subtitle}/>}
            {children && <Divider/>}
            {children}
        </Card>
    );
};

export default CardWithIcon;
