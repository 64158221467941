import {Identifier, Record, useUpdate} from "ra-core";
import {Button, Confirm, Toolbar, useDeleteWithConfirmController, useGetIdentity, useRedirect, useRefresh} from "react-admin";
import classnames from "classnames";
import ActionDelete from "@material-ui/icons/Delete";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {fade} from "@material-ui/core/styles/colorManipulator";
import get from "lodash/get";
import {usePermission} from "../providers/permissionProvider";
import {AcceptButton} from "./AcceptButton";
import ApplicationStateMachine from "../models/applications/ApplicationStateMachine";
import { StatusField } from "./StatusField";

const useStyles = makeStyles(
    theme => ({
        defaultToolbar: {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
        },
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    }),
    {name: 'RaDeleteWithUndoButton'}
);


export interface StatusToolbarProps {
    id?: Identifier,
    resource: string,
    source: string,
    status?: string | Function,
    record?: Record,
    classes?: any,
    showToolbar?: boolean,
    extraParams?: object,
    deleteAction?: boolean,
    recordOrigin?: any,
    stateMachine?: any,
    basePath?: string
}


function Decline({
                     resource,
                     status,
                     classes,
                     onClick
                 }: { status: string, resource: string, onClick: (query?: Partial<any>, options?: Partial<any>) => (void | Promise<any>), classes: any }) {
    const {
        open,
        loading,
        handleDialogOpen,
        handleDialogClose,
        handleDelete,
    } = useDeleteWithConfirmController({
        resource,
        //record,
        //redirect,
        //basePath,
        onClick,
    });
    return <><Button
        onClick={handleDialogOpen}
        label="ra.message.decline_title"
        className={classnames(
            "ra-delete-button",
            classes.deleteButton
        )} variant='contained' type='button'>
        <ActionDelete/>
    </Button>
        <Confirm
            isOpen={open}
            loading={loading}
            title="ra.message.decline_title"
            content="ra.message.decline_content"
            onConfirm={(e) => {
                onClick();
                handleDialogClose(e)
            }}
            onClose={handleDialogClose}
        />
    </>;
}

export const StatusToolbar = (props: StatusToolbarProps) => {
    const {hasPermission} = usePermission();
    const {
        identity,
        loading: identityLoading
    } = useGetIdentity();
    const classes = useStyles();
    const {
        record,
        id,
        showToolbar,
        resource,
        source,
        basePath,
        extraParams = {},
        deleteAction = true,
        status: currentStatus,
        stateMachine = ApplicationStateMachine
    }
        = props;
        const refresh = useRefresh();
        const redirect = useRedirect();

    const extraParamValues = {};
    Object.keys(extraParams).forEach((paramKey) => {
        // @ts-ignore
        extraParamValues[paramKey] = get(record, extraParams[paramKey]);
    });

    // @ts-ignore
    const [decline, {
        loading: declineLoading,
        error: declineError

    }// @ts-ignore
    ] = useUpdate(resource, id || record.id, {
        ...extraParamValues,
        status: 'declined'
    });
    const [accept, {
        loading: acceptLoading,
        error: acceptError
        // @ts-ignore
    }// @ts-ignore
    ] = useUpdate(resource, id || record.id, {
        ...extraParamValues,
        status: 'accepted'
    });
    let status;// @ts-ignore
    let stateRecord;// @ts-ignore
    if (typeof extraParams.getStatus === 'function') {
        try{
        // @ts-ignore
        status = (extraParams.getStatus(record)).shift();
        // @ts-ignore
        let sourceKey = Object.hasOwnProperty.call(extraParams, 'source')?extraParams.source:null;
        if(sourceKey){
            stateRecord=[].concat((record||{})[sourceKey]);
        }
        
        status = stateRecord.shift()[source];

        } catch(Error){
            status = 'pending';
        }
    
    }else{
        // @ts-ignore
        status = currentStatus ? (currentStatus.toString() === currentStatus ? currentStatus : currentStatus(props)) : get(record, source);
    }

    // @ts-ignore

    if (!showToolbar) {
        return null;
    }

    //@ts-ignore
    record.status = status;
    return <>
        <div>
        <label>Jelentkezés státusza:</label><br/><StatusField source="status" addLabel={true} record={record}/>
        </div>
        <Toolbar>
            <div className={classes.defaultToolbar}>
                <AcceptButton record={props.recordOrigin} resource={resource} status={status}
                              stateMachine={stateMachine}/>
                {deleteAction && <Decline onClick={(e) => {
                    // @ts-ignore
                    decline(undefined, {returnPromise: true}).then(() => {
                        refresh();
                    })

                }} resource={resource} classes={classes} status={status}/>}
            </div>
        </Toolbar>
    </>;
}


