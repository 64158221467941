import * as React from "react";
import {Route} from "react-router-dom";
import {EmailVerify} from "../components/EmailVerify";
import {Login} from "react-admin";

export const EmailVerifyLink = <Route
    key="EmailVerifyLink"
    path="/api/auth/verify"
    //  noLayout={true}
    render={() => <Login><EmailVerify/></Login>}
/>