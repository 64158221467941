import * as React from "react";
import {Edit, SimpleForm} from 'react-admin';
import {PersonalData} from "../users/component/application/PersonalData";
import {UserRecord} from "../RecordTypes";
import {RankField} from "../../components/RankField";
import {useTranslate} from "ra-core";
import Toolbar from "../../components/Toolbar";


const Title = (props: any) => {
    const t = useTranslate();
    const {resource} = props;
    if (props.record.camp && props.record.user) {
        return <>{t(`resources.${resource}.pageTitle`, {
            first_name: props.record.user.first_name,
            last_name: props.record.user.last_name,
            campName: props.record.camp.parent ? `${props.record.camp.parent.name} - ${props.record.camp.name}` : props.record.camp.name,
        })}</>;
    }
    return <>{t(`resources.${resource}.pageTitle`, props.record)}<RankField source="rank" {...props} /></>;
}


export const ChildEdit = (props: { record: UserRecord } | any) => {
    return (
        <Edit {...props} title={<Title {...props}/>} resource='family' undoable={false} mutationMode="pessimistic">
            <SimpleForm toolbar={<Toolbar/>}>
                <PersonalData {...props} withoutForm/>
            </SimpleForm>
        </Edit>)
};