import * as React from "react";
import {createElement, useMemo, useRef, useState} from "react";
import {Route} from "react-router-dom";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {Button, Card, Theme} from "@material-ui/core";

import LockOpenIcon from '@material-ui/icons/LockOpen';
import UndoIcon from '@material-ui/icons/Undo';
import {ThemeProvider} from "@material-ui/styles";
import classnames from "classnames";
import CardMedia from '@material-ui/core/CardMedia';
import logo from '../images/kmcssz-europa-logo.png'
import {
    CreateContextProvider,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useCreateController,
    useDataProvider,
    useNotify,
    useRedirect
} from "react-admin";
import {useTranslate} from "ra-core";
import {FORM_ERROR} from "final-form";

const useStyles = makeStyles(
    (theme: Theme) => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: 'auto',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage:
                'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
        },
        card: {
            minWidth: 300,
            maxWidth: 450,
            marginTop: '6em',
            width: '40%',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            // @ts-ignore
            backgroundColor: theme.palette.secondary[500],
        },
        media: {
            height: 71,
            width: '100%'
        },
        backButton: {
            position: 'absolute',
            right: '20px'
        }
    }),
    {name: 'RaLogin'}
);
// @ts-ignore
export const ForgotPasswordLink = <Route key="forgot_password" path="/forgot_password"  noLayout={true} render={() => <ForgotPassword noLayout={true}/>}/>

const ForgotPassword = (props: any) => {
    const containerRef = useRef<HTMLDivElement>();
    const resource = 'auth/forgot-password';
    const {
        theme,
        children,
        className,
        notification,
        ...rest
    } = props;

    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [, setSaving] = useState<boolean>();
    const [, setPasswordRequested] = useState<string>();
    const t = useTranslate();
    const classes = useStyles();
    const redirect = useRedirect();

    const handleSave = async (values: any) => {
        setSaving(true);
        try {
            await dataProvider.create(resource,
                {data: values},
                {
                    onSuccess: ({data: {status}}: any) => {
                        setSaving(false);
                        notify("Your profile has been updated", "info", {
                            _: "Your profile has been updated"
                        });
                        setPasswordRequested(status);
                        redirect('/login')
                    },
                    onFailure: ({body: {message}}: any) => {
                        setSaving(false);
                        notify(
                            "A technical error occured while updating your profile. Please try later.",
                            "warning",
                            {
                                _:
                                message
                            }
                        );
                    }
                }
            );
        } catch (errorData) {
            // @ts-ignore
            const {body: {errors}, status} = errorData;
            if (status === 422) {
                if (errors) {
                    const result = {
                        [FORM_ERROR]: t('Please check your data'),
                        ...errors
                    }
                    if (errors.exists) {
                        result[FORM_ERROR] = errors.exists;
                    }
                    if (errors.error) {
                        result[FORM_ERROR] = errors.error;
                    }
                    Object.keys(errors).forEach(key => {
                        const error = errors[key];
                        if (error.constructor === String) {
                            result[key] = t(error.toString());
                        } else if (error.constructor === Array) {
                            result[key].forEach((value: string, idx: number) => {
                                result[key][idx] = t(value.replace(/\.$/, ''));
                            });
                        }
                    });

                    return result;
                }
                return {[FORM_ERROR]: t('Unknown error')};
            }
        }
    };

    const controllerProps = useCreateController({
        ...props,
        basePath: '/forgot_password',
        resource,
        save: handleSave
    });

    return <ThemeProvider theme={muiTheme}>
        <div
            className={classnames(classes.main, className)}
            {...rest}
            ref={containerRef}
        >
            <Card className={classes.card}>
                <div className={classes.avatar}>
                    <CardMedia
                        className={classes.media}
                        image={logo}
                        title="KMCSSZ 1. Kerület - Európa"
                    />
                </div>
                <CreateContextProvider value={controllerProps}>
                    <SimpleForm save={handleSave} toolbar={<PostCreateToolbar/>}>
                        <TextInput label="Email Address" source="email" type="email"/>
                    </SimpleForm>
                </CreateContextProvider>

            </Card>
            {notification ? createElement(notification) : null}
        </div>
    </ThemeProvider>
}

const PostCreateToolbar = (props: any) => {
    const classes = useStyles();
    const t = useTranslate();
    const redirect = useRedirect();
    return (
        <Toolbar {...props} >
            <SaveButton
                label="Új jelszó"
                redirect="show"
                submitOnEnter={true}
                icon={<LockOpenIcon/>}
            />
            <Button
                variant="text"
                type="button"
                color="primary"
                className={classes.backButton}
                onClick={() => {
                    redirect('/login')
                }}
            >
                <UndoIcon/>
                {t('backButton')}
            </Button>

        </Toolbar>
    )
};
