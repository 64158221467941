import React, {FC} from "react";
import {CreateButton, EditButton, Link, useTranslate} from "react-admin";
import {Card, CardContent, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {CampType} from "./CampType";
import ListButtonWithQuery from "../../components/ListButtonWithQuery";
import WorkIcon from '@material-ui/icons/Work';
import { usePermission } from "../../providers/permissionProvider";

export interface CampSubListProps {
    recentCamps?: Array<CampType>
}

// @ts-ignore
export const CampSubList: FC<CampSubListProps> = ({recentCamps = []}) => {
    const t = useTranslate();
    const {
        hasPermission,
        loaded
    } = usePermission();

    if (!recentCamps?.length) {
        return null;
    }

    return (<Card>
        <CardContent>
            <Typography variant="h4">{t('resources.camps.name')}</Typography>
            {hasPermission('manage.camps') && <CreateButton basePath="/camps" />}
            <List>
                {recentCamps.map((record: CampType) => {
                    return (<ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/camps/${record.id}/show`}
                        alignItems="flex-start"
                    >
                        <ListItemText primary={record.name} style={{
                            paddingRight: 0,
                            width: '40%'
                        }}/>
                        <ListItemText primary={record.start_at.toString()} style={{
                            paddingRight: 0,
                            width: '20%'
                        }}/>
                        <ListItemText primary={record.finish_at.toString()} style={{
                            paddingRight: 0,
                            width: '20%'
                        }}/>
                        <ListItemText style={{
                            paddingRight: 0,
                            width: '20%'
                        }}>
                            {(record.permissions.includes('camp.applicants') &&
                                <ListButtonWithQuery icon={<WorkIcon/>} resource="applications" query={{filter: {camp_id: record.id}}} label="" title="Jelentkezések"/>) || ''}
                            {(record.permissions.includes('camp.update') && <EditButton record={record} basePath="/camps" label="" title={t("ra.action.edit")}/>) || ''}
                        </ListItemText>
                    </ListItem>)
                })}
            </List>
        </CardContent>
    </Card>);
};
